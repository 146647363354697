import Vue from 'vue'
import notifications from 'vue-notification'

import { NotificationError, NotificationSuccess, NotificationWarning } from 'src/pojos/NotificationTypes.js'
import application from 'src/main'

Vue.use(notifications)

const sendNotification = (notification) => {
	application.$notify(notification)
}

const sendSuccessNotification = (message, group) => {
	sendNotification(new NotificationSuccess({ message, group }))
}

const sendErrorNotification = (message, group) => {
	sendNotification(new NotificationError({ message, group }))
}

const sendWarningNotification = (message, group) => {
	sendNotification(new NotificationWarning({ message, group }))
}

export {
	sendSuccessNotification,
	sendErrorNotification,
	sendWarningNotification
}
