import Enum from './Domain'

class ModalSize extends Enum { }

ModalSize.initEnum([
	'SMALL',
	'MEDIUM',
	'LARGE'
])

export default ModalSize
