import {
	ROUTE_BULK_ACTIONS
	, ROUTE_SELLER_BULK_ACTIONS
	, ROUTE_BULK_ACTIONS_LOGS
	, ROUTE_BULK_ACTIONS_LOGS_DETAILS
} from 'constants/RouterNames'

const bulkActions = () => import('components/pages/secured/product/bulkActions/bulkActions.vue')
const productBulkActions = () => import('components/pages/secured/product/bulkActions/productBulkActions/productBulkActions.vue')
const bulkActionsLogs = () => import('components/pages/secured/product/bulkActions/bulkActionsLogs/bulkActionsLogs.vue')
const bulkActionsLogsDetails = () => import('components/pages/secured/product/bulkActions/bulkActionsLogs/bulkActionsLogsDetails/bulkActionsLogsDetails.vue')

const productColomboRoute = {
	path: '/produtos-colombo',
	redirect: '/acoes-em-massa',
	component: bulkActions,
	name: ROUTE_BULK_ACTIONS.name,
	meta: { requiresAuthorities: ROUTE_BULK_ACTIONS.routerAuth, breadcrumb: { name: 'Ações em Massa' } },
	children: [
		{ path: '/acoes-em-massa', component: productBulkActions, name: ROUTE_SELLER_BULK_ACTIONS.name, requiresAuthorities: ROUTE_SELLER_BULK_ACTIONS.routerAuth },
		{ path: '/acoes-em-massa-logs', props: (route) => ({ task: route.query.task }), component: bulkActionsLogs, name: ROUTE_BULK_ACTIONS_LOGS.name, meta: { breadcrumb: { name: 'Logs' } }, requiresAuthorities: ROUTE_BULK_ACTIONS_LOGS.routerAuth },
		{ path: '/acoes-em-massa-logs/:id', props: true, component: bulkActionsLogsDetails, name: ROUTE_BULK_ACTIONS_LOGS_DETAILS.name, meta: { breadcrumb: { name: 'Logs' } }, requiresAuthorities: ROUTE_BULK_ACTIONS_LOGS_DETAILS.routerAuth }
	]
}

export default productColomboRoute
