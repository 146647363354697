
import {
	ROUTE_CONFIGURATION_FEE
	, ROUTE_CONFIGURATION_FEE_LIST
	, ROUTE_CONFIGURATION_FEE_REGISTER
	, ROUTE_CONFIGURATION_FEE_DETAIL
} from 'constants/RouterNames'

const fee = () => import('src/components/pages/secured/configuration/fee/fee')
const feeList = () => import('src/components/pages/secured/configuration/fee/feeList/feeList')
const feeRegister = () => import('src/components/pages/secured/configuration/fee/feeRegister/feeRegister')
const feeDetail = () => import('src/components/pages/secured/configuration/fee/feeDetail/feeDetail')

const feeRoute = {
	path: '/fee',
	redirect: '/listagem-fee',
	component: fee,
	name: ROUTE_CONFIGURATION_FEE.name,
	meta: { breadcrumb: { name: 'Fee' }, requiresAuthorities: ROUTE_CONFIGURATION_FEE.routerAuth },
	children: [
		{ path: '/listagem-fee', component: feeList, name: ROUTE_CONFIGURATION_FEE_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_FEE_LIST.routerAuth } },
		{ path: '/cadastro-fee', component: feeRegister, name: ROUTE_CONFIGURATION_FEE_REGISTER.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_CONFIGURATION_FEE_REGISTER.routerAuth } },
		{ path: '/detalhe-fee/:feeId', props: true, component: feeDetail, name: ROUTE_CONFIGURATION_FEE_DETAIL.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_CONFIGURATION_FEE_DETAIL.routerAuth } }
	]
}

export default feeRoute
