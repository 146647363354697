import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const PasswordRenewal = new AuthorityBuilder({
	accessGroup: 'PASSWORD_RENEWAL',
	accessType: ['ACCESS']
})

export default [
	PasswordRenewal
]
