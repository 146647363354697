import PanelColombo from './panelColombo/'
import PanelSeller from './panelSeller/'
import Marketplace from './marketplace/'

const Authorities = [
	...PanelSeller,
	...PanelColombo,
	...Marketplace
]

export const RouteAuthorities = {
	...Authorities.flatMap(authBuilder => authBuilder.build())
		.reduce((acc, cur) => {
			acc[cur] = cur.toLowerCase()
			return acc
		}, {})
}
