import {
	ROUTE_REBATE_ACCESS_DETAIL_SELLER,
	ROUTE_REBATE_DETAIL_PRODUCT,
	ROUTE_REBATE_DETAIL_CATEGORY
} from 'constants/RouterNames'

const rebateDetail = () => import('components/pages/secured/promotion/rebate/rebateSeller/rebateDetail/rebateDetail.vue')
const rebateDetailProduct = () => import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseProduct/rebateBaseProduct.vue')
const rebateDetailCategory = () => import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseCategory/rebateBaseCategory.vue')

const rebateDetailRoute = {
	path: '/detalhar-rebate/:rebateId',
	component: rebateDetail,
	name: ROUTE_REBATE_ACCESS_DETAIL_SELLER.name,
	meta: { breadcrumb: { name: 'Detalhamento', active: false } },
	children: [
		{ path: '/detalhar-rebate/:rebateId/produto', component: rebateDetailProduct, name: ROUTE_REBATE_DETAIL_PRODUCT.name, meta: { breadcrumb: { name: 'Rebate por produto' }, requiresAuthorities: ROUTE_REBATE_DETAIL_PRODUCT.routerAuth } },
		{ path: '/detalhar-rebate/:rebateId/categoria', component: rebateDetailCategory, name: ROUTE_REBATE_DETAIL_CATEGORY.name, meta: { breadcrumb: { name: 'Rebate por categoria' }, requiresAuthorities: ROUTE_REBATE_DETAIL_CATEGORY.routerAuth } }
	]
}

export default rebateDetailRoute
