import {
	ROUTE_PROMOTION_COLOMBO,
	ROUTE_PROMOTION_MODERATION_LISTING_COLOMBO,
	ROUTE_PROMOTION_LISTING_COLOMBO
} from 'constants/RouterNames'

import promotionModeration from './promotionModeration/'
import promotionDetail from './promotionDetail/'
const promotionColombo = () => import('components/pages/secured/promotion/promotionColombo/promotionColombo.vue')
const promotionListingColombo = () => import('components/pages/secured/promotion/promotionColombo/promotionList/promotionList.vue')
const promotionModerationListingColombo = () => import('components/pages/secured/promotion/promotionColombo/promotionModerationList/promotionModerationList.vue')

const promotionRoute = {
	path: '/promotion-colombo',
	redirect: '/listar-todas-moderacao',
	component: promotionColombo,
	name: ROUTE_PROMOTION_COLOMBO.name,
	children: [
		{ path: '/listar-todas-promocoes', component: promotionListingColombo, name: ROUTE_PROMOTION_LISTING_COLOMBO.name, meta: { requiresAuthorities: ROUTE_PROMOTION_LISTING_COLOMBO.routerAuth } },
		{ path: '/listar-promocoes-moderacao', component: promotionModerationListingColombo, name: ROUTE_PROMOTION_MODERATION_LISTING_COLOMBO.name, meta: { requiresAuthorities: ROUTE_PROMOTION_MODERATION_LISTING_COLOMBO.routerAuth } },
		promotionModeration,
		promotionDetail
	]
}

export default promotionRoute
