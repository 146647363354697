import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Dashboard = new AuthorityBuilder({
	accessGroup: 'DASHBOARD',
	accessType: ['ACCESS', 'READ', 'WRITE']
})

export default [
	Dashboard
]
