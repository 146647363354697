import { sendErrorNotification } from 'src/services/notificationService'

import {
	ADD_REBATE_CATEGORY,
	ADD_REBATE_CATEGORY_EXCLUDED_PRODUCT,
	SET_REBATE_CATEGORY_FIELD,
	REMOVE_REBATE_CATEGORY,
	REMOVE_REBATE_CATEGORY_EXCLUDED_PRODUCT
} from 'constants/MutationTypes'

import RebateProductItemSellerDto from 'dtos/promotionDtos/RebateProductItemSellerDto.js'
import RebateCategoryDto from 'dtos/promotionDtos/RebateCategoryDto.js'

import { isNullOrUndefined } from 'services/objectService.js'

// getters
const getters = {
	getRebateCategoryExcludedProducts: state => state.rebate.rebateProductItemSellers || [],
	getRebateCategories: state => state.rebate.rebateCategories || []
}

// actions
const actions = {
	pushRebateCategory({ commit }, category) {
		commit(ADD_REBATE_CATEGORY, category)
	},
	pushRebateCategoryExcludedProduct({ commit }, product) {
		commit(ADD_REBATE_CATEGORY_EXCLUDED_PRODUCT, product)
	},
	setRebateCategoryField({ commit }, { index, fieldName, fieldValue }) {
		commit(SET_REBATE_CATEGORY_FIELD, { index, fieldName, fieldValue })
	},
	removeRebateCategory({ commit }, index) {
		commit(REMOVE_REBATE_CATEGORY, index)
	},
	removeRebateCategoryExcludedProduct({ commit }, index) {
		commit(REMOVE_REBATE_CATEGORY_EXCLUDED_PRODUCT, index)
	}
}

// mutations
const mutations = {
	[ADD_REBATE_CATEGORY](state, data) {
		const rebateCategory = state.rebate.rebateCategories.find(currentCategory => currentCategory.categoryId === data.categoryId)

		if (!rebateCategory) {
			state.rebate.rebateCategories.push(new RebateCategoryDto(data))
		} else {
			sendErrorNotification(`A categoria de id ${rebateCategory.categoryId} já foi adicionada!`)
		}
	},
	[ADD_REBATE_CATEGORY_EXCLUDED_PRODUCT](state, data) {
		const productItemSeller = state.rebate.rebateProductItemSellers.find(pis => pis.productItemSellerId === data.productItemSellerId)

		if (!productItemSeller) {
			state.rebate.rebateProductItemSellers.push(new RebateProductItemSellerDto(data))
		} else {
			sendErrorNotification(`O produto ${productItemSeller.productName} já foi adicionado!`)
		}
	},
	[SET_REBATE_CATEGORY_FIELD](state, { index, fieldName, fieldValue }) {
		if (isNullOrUndefined(index)) {
			state.rebate.rebateCategories.forEach(cat => {
				cat[fieldName] = fieldValue
			})
		} else {
			state.rebate.rebateCategories[index][fieldName] = fieldValue
		}
	},
	[REMOVE_REBATE_CATEGORY](state, index) {
		state.rebate.rebateCategories.splice(index, 1)
	},
	[REMOVE_REBATE_CATEGORY_EXCLUDED_PRODUCT](state, index) {
		state.rebate.rebateProductItemSellers.splice(index, 1)
	}
}

export default {
	getters,
	actions,
	mutations
}
