import moment from 'moment'
import store from 'src/store/store'

import { redirect } from 'services/routesService'
import { eventEmit, EVENT_UPDATE_SESSION, EVENT_WARNING_SESSION } from 'services/eventBusService'
import { sendErrorNotification } from 'services/notificationService'
import { logout } from 'httpServices/sessionHttpService'

import { SET_SESSION_TIMER, SET_CURRENT_TIMEOUT } from 'constants/MutationTypes'
import { ROUTE_LOGIN } from 'constants/RouterNames'
import { SESSION_EXPIRE } from 'constants/LocalStorageKeys.js'
import { getSessionInactiveInterval } from 'httpServices/sessionHttpService.js'

let SESSION_TIMEOUT_WARNING
let SESSION_TIMEOUT_TIME
let MAX_INACTIVE_INTERVAL
let onGoingRefresh = false

const refreshSession = () => {
	// if the user doesnt have a session or is currently refreshing his current one: NOOP
	const noSessionCurrently = !store.getters.getLoggedUser
	if (noSessionCurrently || onGoingRefresh) {
		return
	}
	onGoingRefresh = true

	if (!MAX_INACTIVE_INTERVAL) { // if the interval has no been defined yet, retrieve it from the backend
		getSessionInactiveInterval().then(({ data }) => {
			MAX_INACTIVE_INTERVAL = data

			// 20 mins before the session expire, send a warning
			SESSION_TIMEOUT_WARNING = moment(MAX_INACTIVE_INTERVAL).subtract(20, 'minutes').valueOf()

			// 5 mins before the session ends, forcibly close it
			SESSION_TIMEOUT_TIME = moment(MAX_INACTIVE_INTERVAL).subtract(5, 'minutes').valueOf()
			startTimer()
		}).catch(() => {
			onGoingRefresh = false
		})
	} else {
		startTimer()
	}
}

const startTimer = () => {
	onGoingRefresh = false

	store.commit(SET_SESSION_TIMER, getCurrentTime())

	// Calculate the time difference between current time and the warning message
	const gracePeriod = calculateGracePeriod(SESSION_TIMEOUT_WARNING)
	eventEmit(EVENT_UPDATE_SESSION)

	// If that difference is bigger than 0 then wait that amount before showing the warning
	if (gracePeriod > 0) {
		sessionTimeout(gracePeriod, startWarningTimeout)
	} else { // Otherwise show the warning and start the timer to terminate the session
		startWarningTimeout()
	}
}

const startWarningTimeout = () => {
	// Calculate the time difference between current time and the end of session
	const gracePeriod = calculateGracePeriod(SESSION_TIMEOUT_TIME)

	// Update on store that the current session is in warning with the time left until its over
	eventEmit(EVENT_WARNING_SESSION, gracePeriod)

	// If that difference is bigger than 0 then wait that amount before terminating the session
	if (gracePeriod > 0) {
		sessionTimeout(gracePeriod, expireUserSession)
	} else { // Otherwise expire the session
		expireUserSession()
	}
}

const sessionTimeout = (callIn, onTimeout) => {
	// Get the timeout from store to avoid creating duplicates
	const currentTimeOut = store.getters.getCurrentTimeout
	if (currentTimeOut) {
		clearTimeout(currentTimeOut)
	}

	// Start new timeout with the grace period
	const newCurrentTimeOut = setTimeout(() => {
		const currentTime = getTimeSessionExpire()
		const lastTimeUpdate = getStoreTime()

		// Check if the currentTime is consistent with the timer set on localStorage to sync different tabs
		if (checkTimerConsistency(currentTime, lastTimeUpdate)) {
			onTimeout()
		} else { // Otherwise reset the timer using that different timer to calculate the grace period
			refreshTimeoutFail(currentTime)
		}
	}, callIn)

	store.commit(SET_CURRENT_TIMEOUT, newCurrentTimeOut)
}

const refreshTimeoutFail = (currentTime) => {
	// Calculate the new time based on the last time it was updated on the local storage
	const nextCallIn = addTime(currentTime, SESSION_TIMEOUT_WARNING) - getCurrentTime()
	if (isNaN(nextCallIn)) return

	eventEmit(EVENT_UPDATE_SESSION)
	store.commit(SET_SESSION_TIMER, currentTime)
	sessionTimeout(nextCallIn, startWarningTimeout)
}

const checkTimerConsistency = (currentTime = getTimeSessionExpire(), lastTimeUpdate = getStoreTime()) => {
	return currentTime === lastTimeUpdate
}

const expireUserSession = () => {
	sendErrorNotification('Sua sessão expirou')
	logout().then(() => {
		redirect(ROUTE_LOGIN)
	})
}

const getTimeSessionExpire = () => {
	return parseFloat(localStorage.getItem(SESSION_EXPIRE))
}

const getStoreTime = () => {
	return parseFloat(store.getters.getSessionTimer)
}

const getCurrentTime = () => {
	return moment().valueOf()
}

const calculateGracePeriod = (timeToAdd) => {
	const lastTimeUpdate = getTimeSessionExpire()
	const nextValidationMoment = addTime(lastTimeUpdate, timeToAdd)
	return nextValidationMoment - getCurrentTime()
}

const addTime = (date, time) => {
	return moment(date).add(time).valueOf()
}

export {
	refreshSession,
	calculateGracePeriod,
	getTimeSessionExpire,
	checkTimerConsistency
}
