<template>
	<div class="mkt-pagination" v-if="totalPages && totalPages > 1">
		<div :class="{ 'disabled': currentPage <= 0 }">
			<a @click.prevent="previousPage" href="#">
				<mkt-icon :bl-hover="true" tx-font-size="10px" :tx-icon="'icon-chevron-left'"></mkt-icon>
			</a>
		</div>

		<div v-for="(page, index) in paginate" :key="index" :class="{ 'active': page === currentPage }">
			<a @click.prevent="() => setCurrentPage(page)" href="#">{{page + 1}}</a>
		</div>

		<div :class="{ 'disabled': currentPage === totalPages -1 }">
			<a @click.prevent="nextPage" href="#">
				<mkt-icon :bl-hover="true" tx-font-size="10px" :tx-icon="'icon-chevron-right'"></mkt-icon>
			</a>
		</div>
	</div>
</template>

<script>
import { Get } from 'src/httpServices/baseHttpService'
import { serializeObject } from 'src/services/queryStringService'
import mktPaginationMixin from '../mktPaginationMixin'

export default {
	name: 'mkt-paginate-data',
	mixins: [mktPaginationMixin],
	props: {
		txApiUrl: { type: String, required: false },
		obAppendParams: { type: Object, required: false, default: () => {} }
	},
	data() {
		return {
			params: this.obAppendParams,
			lastCall: '',
			events: {
				onLoadData: (page) => this.$emit('mkt-pagination:on-load-data', page)
			}
		}
	},
	methods: {
		loadPaginateData() {
			const panelBackUrl = `${this.txApiUrl}?${this.searchQueryString}`
			this.lastCall !== panelBackUrl && Get(panelBackUrl).then(({ data: page }) => {
				this.lastCall = panelBackUrl
				this.totalPages = page.totalPages
				this.events.onLoadData(page)
			})
		},
		loadData(params = {}) {
			this.params = params
			this.loadPaginateData()
		}
	},
	computed: {
		searchQueryString() {
			const paginationInfo = {
				page: this.currentPage,
				size: this.pageSize
			}

			const mergedOptions = Object.assign({}, this.params, paginationInfo)
			return serializeObject(mergedOptions)
		}
	}
}
</script>
<style src="../mktPagination.scss" lang="scss">
</style>
