import Enum from './Domain'

class StatusType extends Enum { }

StatusType.initEnum({
	APPROVED: {
		message: 'Aprovado',
		icon: 'icon-check_circle',
		color: '#54C484'
	},
	DISABLED: {
		message: 'Desabilitado',
		icon: 'icon-cancel',
		color: '#ABA9A9'
	},
	REPROVED: {
		message: 'Reprovado',
		icon: 'icon-error',
		color: '#EB5757'
	},
	AWAITING_COLOMBO: {
		message: 'Aguardando Moderação',
		icon: 'icon-watch_later',
		color: '#6E6E6E'
	},
	IN_MODERATION: {
		message: 'Em Moderação',
		icon: 'icon-lock_outline',
		color: '#6E6E6E'
	},
	IN_ANALYSIS: {
		message: 'Em Análise',
		icon: 'icon-settings',
		color: '#6E6E6E'
	},
	AWAITING_SELLER: {
		message: 'Aprovação Seller',
		icon: 'icon-monetization_on',
		color: '#ABA9A9'
	},
	DISABLED_COLOMBO: {
		message: 'Desabilitado Colombo',
		icon: 'icon-block',
		color: '#787878'
	}
})

export default StatusType
