// Adicionar novos Módulos aqui
import configurations from './configurations'
import globals from './globals'
import product from './product'
import promotion from './promotion'
import rebate from './promotion/rebate'

export default {
	...globals,
	...product,
	...configurations,
	...promotion,
	...rebate
}
