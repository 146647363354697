<template>
	<draggable
		class="mkt-draggable-file"
		v-model="draggableFiles"
		:move="() => blHasDrag"
		@start="onDragStart"
		@end="onDragEnd"
		@change="onChange"
	>
		<div v-for="(file, $index) in draggableFiles" :key="'file-key-' + $index" class="mkt-draggable-file__container" :class="{ 'has-drag': blHasDrag }">
			<img :src="acceptedFileTypes.thumbnailImg || file[txImageSource]" :alt="file.fileName" />
			<div class="mkt-draggable-file__container__file-actions" v-if="blHasHover && !isDragging">
				<span
					v-if="blHasPreview || blReadOnly"
					class="file-action action-color-black has-opacity"
					@click="previewFile(file)"
					title="Preview"
					name="Preview"
				>
					<i aria-hidden="true" name="Preview" class="icon-open_in_new"></i>
				</span>

				<slot :file="file" :index="$index" name="extra-action-slot">
				</slot>

				<span title="Deletar" name="Deletar" class="file-action action-color-red" @click="removeUploadedFile($index)" v-if="allowDelete(file)">
					<i aria-hidden="true" name="Deletar" class="icon-delete"></i>
					<slot name="file-remove-action-subtitle"></slot>
				</span>

			</div>
			<div class="mkt-draggable-file__container__file-name" v-if="blShowFilename">
				{{ file.fileName }}
			</div>
		</div>
	</draggable>
</template>
<script>
import draggable from 'vuedraggable'
import PropConfig from 'pojos/PropConfig'
import MimeType from 'domains/MimeType'

import { loadFileFromUrl, openFile } from 'services/fileService'
import { openLightBox } from 'services/lightboxService.js'

export default {
	name: 'mkt-draggable-file',
	components: {
		draggable
	},
	props: {
		value: new PropConfig({ type: Array, required: true }),
		txIdFileUpload: new PropConfig({ type: String, required: true }),
		txImageSource: new PropConfig({ type: String, required: true }),
		blFileSwap: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blShowFilename: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blHasHover: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blHasDelete: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blHasDrag: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blSingleUpload: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blHasPreview: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blDeleteFile: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blAllowDeleteInitialFiles: new PropConfig({ type: Boolean, valueDefault: true }),
		acceptedFileTypes: new PropConfig({ type: MimeType, required: false, valueDefault: () => MimeType.IMAGE })
	},
	mounted() {
		if (this.value && this.value.length) {
			this.value.sort(this.sortByOrder)
			this.emitValueEvent(this.value.map(file => {
				file.initialValue = true
				return file
			}))
		}
	},
	data() {
		return {
			isDragging: false
		}
	},
	methods: {
		onDragStart() {
			this.isDragging = true
		},
		onDragEnd() {
			this.isDragging = false
		},
		onChange() {
			this.emitValueEvent(this.draggableFiles)
		},
		allowDelete(file) {
			const allowDeleteThisFile = this.blAllowDeleteInitialFiles ? true : !file.initialValue
			return this.blDeleteFile && allowDeleteThisFile
		},
		sortByOrder(fileBefore, fileAfter) {
			const orderBefore = fileBefore.order || Infinity
			const orderAfter = fileAfter.order || Infinity
			return orderBefore - orderAfter
		},
		mapOrder(file, index) {
			file.order = index + 1
			return file
		},
		emitValueEvent(value) {
			this.$emit('input', value.map(this.mapOrder))
		},
		previewFile(file) {
			if (MimeType.PDF === this.acceptedFileTypes) {
				loadFileFromUrl(file[this.txImageSource]).then(response => {
					openFile(response.data, 'application/pdf')
				})
			} else {
				openLightBox(file[this.txImageSource])
			}
		},
		removeUploadedFile(index) {
			const removedFiled = this.draggableFiles.splice(index, 1)
			this.$emit('mkt-draggable-file:remove', removedFiled)
		}
	},
	computed: {
		draggableFiles: {
			get() {
				return this.value
			},
			set(files) {
				this.emitValueEvent(files)
			}
		}
	}
}
</script>
<style src="./mktDraggableFile.scss" lang="scss">
</style>
