<template>
	<div class="mkt-label" v-if="txLabel || txHighligthedLabel || blHasSlot">
		<label class="mkt-label__label" :class="{ 'mkt-label__label--required': blRequired }" :id="txId">
			{{txLabel}}
			<b v-if="txHighligthedLabel"> {{txHighligthedLabel}} </b>
			<mkt-icon
				v-if="!obLabelIcon.hidden"
				class="mkt-label__icon"
				tx-font-size="12px"
				:tx-icon="obLabelIcon.icon"
				:tx-icon-color="obLabelIcon.color"
				v-tooltip="{ text: obLabelIcon.message, show: true, style: { maxWidth: '225px' }, options: { appendTo: 'document' } }"
			></mkt-icon>
			<slot></slot>
			<mkt-icon
         		class="mkt-label__observation"
				tx-icon="icon-info-circle"
				tx-font-size="15px"
				v-if="!!txObservation"
				tx-icon-color="#F58220"
				v-tooltip="{ text: txObservation, show: !!txObservation }">
        	</mkt-icon>
		</label>	
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'
	export default {
		props: {
			txLabel: new PropConfig({
				type: String,
				required: false
			}),
			txHighligthedLabel: new PropConfig({
				type: String,
				required: false
			}),
			txId: new PropConfig({
				type: String,
				required: false
			}),
			obLabelIcon: new PropConfig({
				type: Object,
				required: false,
				valueDefault: () => ({ hidden: true })
			}),
			blRequired: new PropConfig({
				type: Boolean,
				valueDefault: false
			}),
			blHasSlot: new PropConfig({
				type: Boolean,
				valueDefault: false
			}),
			txObservation: new PropConfig({
				required: false
			})
		}
	}
</script>

<style src="./mktLabel.scss" lang="scss">

</style>
