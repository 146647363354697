'use strict'

class Notification {
	constructor({ icon, group }) {
		this.duration = 10000
		this.speed = 1000
		this.group = group || 'global'
		this.data = { icon }
	}
}

export class NotificationError extends Notification {
	constructor({ message, group }) {
		super({ icon: 'icon-error', group })
		this.type = 'error'
		this.text = message
	}
}

export class NotificationSuccess extends Notification {
	constructor({ message, group }) {
		super({ icon: 'icon-check-full', group })
		this.type = 'success'
		this.text = message
	}
}

export class NotificationWarning extends Notification {
	constructor({ message, group }) {
		super({ icon: 'icon-info', group })
		this.type = 'warning'
		this.text = message
	}
}
