'use strict'
import { CATEGORY_LINE, CATEGORY_FAMILY, CATEGORY_GROUP } from 'constants/CategoryLevel'
import { sortFunctions } from 'services/listService.js'
import { orderCharacteristicsByActive } from 'services/characteristicsService.js'
export default class ProductColomboDto {
	constructor({ productId, categoryEanOptional, productItemSellerContentId, categories, name, description, status, brandId, productCategoryType,
		brandName, model, guide, attributes, productVariationSet, youtubeLink, reprovedReasonList, integrationStatus, nonIntegrationMessage }) {
		this.productId = productId || null
		this.line = this.getProductCategoryLine(categories)
		this.family = this.getProductCategoryFamily(categories)
		this.group = this.getProductCategoryGroup(categories)
		this.categoryEanOptional = categoryEanOptional || false
		this.productItemSellerContentId = productItemSellerContentId || null
		this.status = status
		this.title = name || null
		this.description = description || null
		this.brand = { id: brandId, name: brandName } || {}
		this.model = model || null
		this.manual = guide || []
		this.youtubeLink = youtubeLink || null
		this.productCharacteristics = attributes || []
		this.productCategoryType = productCategoryType || null

		if (productId) {
			this.productCharacteristics = this.productCharacteristics.sort(orderCharacteristicsByActive)
		} else {
			this.productCharacteristics = sortFunctions.alphabeticSortList(this.productCharacteristics, 'description')
		}
		this.variations = productVariationSet || null
		this.status = status || null
		this.categories = categories || {}
		this.reprovedReasonList = reprovedReasonList || []
		this.integrationStatus = integrationStatus
		this.nonIntegrationMessage = nonIntegrationMessage
	}
	getProductCategoryLine(categories) {
		return (categories && categories.find((category) => category.level === CATEGORY_LINE).id) || null
	}
	getProductCategoryFamily(categories) {
		return (categories && categories.find((category) => category.level === CATEGORY_FAMILY).id) || null
	}
	getProductCategoryGroup(categories) {
		return (categories && categories.find((category) => category.level === CATEGORY_GROUP).id) || null
	}
}
