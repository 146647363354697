import { ROUTE_REBATE_LISTING_COLOMBO } from 'constants/RouterNames'
import rebateRegisterRoute from './rebateRegister'
import rebateListRoute from './rebateList'
import rebateDetail from './rebateDetail/'

const rebate = () => import('components/pages/secured/promotion/rebate/rebate.vue')

const rebateRoute = {
	path: '/rebate-colombo',
	redirect: '/listar-rebates',
	component: rebate,
	name: ROUTE_REBATE_LISTING_COLOMBO.name,
	meta: { breadcrumb: { name: 'Rebate automático' }, requiresAuthorities: ROUTE_REBATE_LISTING_COLOMBO.routerAuth },
	children: [
		rebateRegisterRoute,
		rebateListRoute,
		rebateDetail
	]
}

export default rebateRoute
