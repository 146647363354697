<template>
	<div class="mkt-switch">
		<mkt-label :txLabel="txLabel" v-if="txLabel"></mkt-label>
		<md-switch
			:disabled="disabled"
			v-model="switchModel"
			class="md-primary"
			@change="updateParentModel"
			>{{ txText }}</md-switch
		>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
export default {
	name: 'mkt-switch',
	props: {
		txLabel: new PropConfig({ type: String, required: false }),
		txText: new PropConfig({ type: String, required: true }),
		value: new PropConfig({ required: false, valueDefault: null }),
		disabled: new PropConfig({ required: false, valueDefault: false })
	},
	data() {
		return {
			switchModel: this.value
		}
	},
	methods: {
		updateParentModel() {
			this.$emit('input', this.switchModel)
		}
	}
}
</script>
