'use strict'

import { isNullOrUndefined } from 'services/objectService.js'

export default class PromotionProductItemSellerDto {
	constructor({ productId, price, sku, productName, promotionProductItemSellerId, productItemSellerId, discountPercentage, saleLimitQuantity }) {
		this.productId = productId || null
		this.price = price || null
		this.sku = sku || null
		this.productName = productName || null
		this.promotionProductItemSellerId = promotionProductItemSellerId || null
		this.productItemSellerId = productItemSellerId || null
		this.saleLimitQuantity = saleLimitQuantity || null

		if (isNullOrUndefined(discountPercentage)) {
			this.discountPercentage = null
			this.discountValue = null
			this.promotionalPrice = null
		} else {
			this.calculateDiscountByPercentage(discountPercentage)
		}
	}

	/**
	 * Quando é setado o valor de desconto num campo, é necessário
	 * calcular o valor de desconto nos outros campos
	 */
	setDiscountByField(fieldName, fieldValue) {
		switch (fieldName) {
		case 'discountPercentage':
			this.calculateDiscountByPercentage(fieldValue)
			break
		case 'discountValue':
			this.calculateDiscountByValue(fieldValue)
			break
		case 'promotionalPrice':
			this.calculateDiscountByPromotionalPrice(fieldValue)
			break
		}

		this.isNegativeValue = (this.promotionalPrice < 0 || this.discountValue < 0)
	}

	calculateDiscountByPercentage(discountPercentage) {
		this.discountPercentage = parseFloat(discountPercentage).toFixed(2)
		this.discountValue = (this.price * (discountPercentage / 100)).toFixed(2)
		this.promotionalPrice = (this.price - this.discountValue).toFixed(2)
	}

	calculateDiscountByValue(discountValue) {
		this.discountValue = discountValue
		this.discountPercentage = ((100 / this.price) * this.discountValue).toFixed(2)
		this.promotionalPrice = (this.price - this.discountValue).toFixed(2)
	}

	calculateDiscountByPromotionalPrice(promotionalPrice) {
		this.promotionalPrice = promotionalPrice
		this.discountValue = (this.price - this.promotionalPrice).toFixed(2)
		this.discountPercentage = ((100 / this.price) * this.discountValue).toFixed(2)
	}
}
