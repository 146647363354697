import {
	ROUTE_CONFIGURATION_FREIGHT_COLOMBO
	, ROUTE_CONFIGURATION_FREIGHT_COLOMBO_LIST
} from 'constants/RouterNames'

const freightColombo = () => import('components/pages/secured/configuration/freight/freightColombo/freightColombo')
const freightList = () => import('components/pages/secured/configuration/freight/freightColombo/freightList/freightList')

const configurationFreightColombo = {
	path: '/frete-colombo',
	redirect: '/listagem-fretes-colombo',
	component: freightColombo,
	name: ROUTE_CONFIGURATION_FREIGHT_COLOMBO.name,
	meta: { requiresAuthorities: ROUTE_CONFIGURATION_FREIGHT_COLOMBO.routerAuth },
	children: [
		{ path: '/listagem-fretes-colombo', component: freightList, name: ROUTE_CONFIGURATION_FREIGHT_COLOMBO_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_FREIGHT_COLOMBO_LIST.routerAuth } }
	]
}

export default configurationFreightColombo
