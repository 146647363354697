import { sendErrorNotification } from 'src/services/notificationService'

import {
	ADD_PROMOTION_PRODUCT_ITEM_SELLER,
	SET_PROMOTION_PRODUCT_SET_DISCOUNT_FIELDS,
	SET_PROMOTION_PRODUCT_SET_SALE_LIMIT,
	REMOVE_PROMOTION_PRODUCT
} from 'constants/MutationTypes'

import PromotionProductItemSellerDto from 'dtos/promotionDtos/PromotionProductItemSellerDto.js'
import { isNullOrUndefined } from 'services/objectService.js'

// getters
const getters = {
	getPromotionProductItemSellerList: state => state.promotion.promotionProductItemSellers || []
}

// actions
const actions = {
	addPromotionProductItemSeller: ({ commit }, promotionProductItemSeller) => {
		commit(ADD_PROMOTION_PRODUCT_ITEM_SELLER, promotionProductItemSeller)
	},
	setPromotionProductDiscountFields: ({ commit }, { index, fieldName, fieldValue }) => {
		commit(SET_PROMOTION_PRODUCT_SET_DISCOUNT_FIELDS, { index, fieldName, fieldValue })
	},
	setPromotionProductSaleLimit: ({ commit }, { index, saleLimitQuantity }) => {
		commit(SET_PROMOTION_PRODUCT_SET_SALE_LIMIT, { index, saleLimitQuantity })
	},
	removePromotionProduction: ({ commit }, index) => {
		commit(REMOVE_PROMOTION_PRODUCT, index)
	}
}

// mutations
const mutations = {
	[ADD_PROMOTION_PRODUCT_ITEM_SELLER](state, promotionProductItemSeller) {
		const productItemSeller = state.promotion.promotionProductItemSellers.find(pis => pis.productItemSellerId === promotionProductItemSeller.productItemSellerId)

		if (!productItemSeller) {
			state.promotion.promotionProductItemSellers.push(new PromotionProductItemSellerDto(promotionProductItemSeller))
		} else {
			sendErrorNotification(`O produto ${productItemSeller.productName} já foi adicionado!`)
		}
	},
	[SET_PROMOTION_PRODUCT_SET_DISCOUNT_FIELDS](state, { index, fieldName, fieldValue }) {
		if (isNullOrUndefined(index)) {
			state.promotion.promotionProductItemSellers.forEach(productItemSeller => {
				productItemSeller.setDiscountByField(fieldName, fieldValue)
			})
		} else {
			const productItemSeller = state.promotion.promotionProductItemSellers[index]
			if (productItemSeller) {
				productItemSeller.setDiscountByField(fieldName, fieldValue)
			}
		}
	},
	[SET_PROMOTION_PRODUCT_SET_SALE_LIMIT](state, { index, saleLimitQuantity }) {
		if (isNullOrUndefined(index)) {
			state.promotion.promotionProductItemSellers.forEach(productItemSeller => {
				productItemSeller.saleLimitQuantity = saleLimitQuantity
			})
		} else {
			const productItemSeller = state.promotion.promotionProductItemSellers[index]
			if (productItemSeller) {
				productItemSeller.saleLimitQuantity = saleLimitQuantity
			}
		}
	},
	[REMOVE_PROMOTION_PRODUCT](state, index) {
		if (!isNullOrUndefined(index)) {
			state.promotion.promotionProductItemSellers.splice(index, 1)
		}
	}
}

export default {
	getters,
	actions,
	mutations
}
