<template>
	<div class="mkt-textarea">
		<md-textarea
			:placeholder="txPlaceholder"
			:maxlength="nrMaxlength"
			:required="blRequired"
			:disabled="blDisabled || blReadOnly"
			v-model="inputModel"
			:id="txId"
			ref="textArea"
			@blur="$emit('blur')"
			:md-autogrow="true">
		</md-textarea>
	</div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
export default {
	props: {
		txPlaceholder: new PropConfig({ type: String }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		blDisabled: new PropConfig({ type: Boolean, valueDefault: false }),
		nrMaxlength: new PropConfig({ type: Number, valueDefault: 200 }),
		value: new PropConfig({ required: false }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		txId: new PropConfig({ type: String, required: false })
	},
	methods: {
		refreshStyle() {
			this.$nextTick(() => {
				this.$refs.textArea && this.$refs.textArea.applyStyles()
			})
		}
	},
	computed: {
		inputModel: {
			get() {
				this.refreshStyle()
				return this.value
			},
			set(value) {
				this.$emit('input', value)
				this.refreshStyle()
			}
		}
	}
}
</script>
<style src="./mktTextarea.scss" lang="scss">
</style>
