<template>
	<div class="mkt-icon">
		 <i @click="events.iconClicked" aria-hidden="true" :style="{ fontSize: txFontSize, color: txIconColor }"
		 	class="mkt-icon__icon" :class="txIcon"
		></i>
		<span v-if="txText" class="mkt-icon__text"> {{ txText }} </span>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'

	export default {
		data() {
			return {
				events: {
					iconClicked: () => {
						this.$emit('mkt-icon:icon-clicked')
					}
				}
			}
		},
		props: {
			txIcon: new PropConfig({ type: String }),
			// TODO janderson.rosa ON 09/05/2018 - alterar componente para nao receber dessa forma font size
			txFontSize: new PropConfig({ type: String, valueDefault: '24px' }),
			txIconColor: new PropConfig({ type: String }),
			txText: new PropConfig({ type: String })
		}
	}
</script>

<style src="./mktIcon.scss" lang="scss">

</style>
