import {
	ROUTE_FINANCIAL_ANTICIPATION,
	ROUTE_FINANCIAL_ANTICIPATION_LIST
} from 'constants/RouterNames'

const financialAnticipation = () => import('components/pages/secured/financial/financialAnticipation/financialAnticipation')
const financialAnticipationList = () => import('components/pages/secured/financial/financialAnticipation/financialAnticipationList/financialAnticipationList')

const financialAnticipationSeller = {
	path: '/antecipacao',
	redirect: '/listagem-antecipacoes',
	meta: { requiresAuthorities: ROUTE_FINANCIAL_ANTICIPATION.routerAuth },
	component: financialAnticipation,
	name: ROUTE_FINANCIAL_ANTICIPATION.name,
	children: [
		{ path: '/listagem-antecipacoes', component: financialAnticipationList, name: ROUTE_FINANCIAL_ANTICIPATION_LIST.name, meta: { breadcrumb: { name: 'Antecipações' }, requiresAuthorities: ROUTE_FINANCIAL_ANTICIPATION_LIST.routerAuth } }
	]
}

export default financialAnticipationSeller
