export default (
	[{
		description: 'Sim',
		value: true
	},
	{
		description: 'Não',
		value: false
	}]
)
