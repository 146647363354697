import {
	ROUTE_CHARACTERISTIC
	, ROUTE_CHARACTERISTIC_LIST
	, ROUTE_CHARACTERISTIC_DETAIL
} from 'constants/RouterNames'

const characteristic = () => import('src/components/pages/secured/configuration/characteristic/characteristic')
const characteristicList = () => import('src/components/pages/secured/configuration/characteristic/characteristicList/characteristicList')
const characteristicDetail = () => import('src/components/pages/secured/configuration/characteristic/characteristicDetail/characteristicDetail')

const characteristicRoute = {
	path: '/caracteristicas',
	redirect: '/listagem-caracteristicas',
	component: characteristic,
	name: ROUTE_CHARACTERISTIC.name,
	meta: { breadcrumb: { name: 'Características' }, requiresAuthorities: ROUTE_CHARACTERISTIC.routerAuth },
	children: [
		{ path: '/listagem-caracteristicas', component: characteristicList, name: ROUTE_CHARACTERISTIC_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CHARACTERISTIC_LIST.routerAuth } },
		{ path: '/edicao-caracteristica/:id', props: true, component: characteristicDetail, name: ROUTE_CHARACTERISTIC_DETAIL.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_CHARACTERISTIC_DETAIL.routerAuth } }
	]
}

export default characteristicRoute
