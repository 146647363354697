import TooltipTemplate from './tooltip.html'
import { calculateOffset, calculatePosition } from 'services/tooltipService.js'

import DOMPurify from 'dompurify'

const defaultOptions = {
	position: 'top',
	spacing: 10,
	appendTo: 'element'
}

const defaultStyles = {
	maxHeight: 'auto',
	maxWidth: '500px',
	backgroundColor: '#757575',
	color: '#FFF'
}

const defaultClasses = [
	'tooltip'
]

export default class Tooltip {
	constructor(el, bind = {}) {
		this.element = el

		this.tooltipNode = document.createElement('Div')
		this.tooltipNode.innerHTML = TooltipTemplate
		this.tooltipNode.className += ` tooltip`

		this.setInitialValues(bind)
	}

	setInitialValues(bind) {
		const options = bind.options || {}
		const style = bind.style || {}
		const classes = bind.classes || []

		this.options = { ...defaultOptions, ...options }
		this.style = { ...defaultStyles, ...style }
		this.content = bind.text || bind
		this.classes = [ ...defaultClasses, ...classes ]

		this.setEvents(this.element)
		this.setStyles(this.style)
		this.setContent(this.content)
		this.setTooltipClasses(this.classes)
	}

	setEvents(el) {
		el.onmouseover = function(e) {
			if (this.element.contains(this.tooltipNode)) return

			e.stopPropagation()

			this.getTooltipParent().appendChild(this.tooltipNode)
			this.isRendered = true
			// Timeout necessário para renderizar a tooltip, o calculo de posição utiliza o tamanho da própria tooltip para centralizar seu conteúdo
			setTimeout(function() {
				this.calculateTooltipPosition()
			}.bind(this))
		}.bind(this)

		el.onmouseleave = function(e) {
			e.stopPropagation()
			this.getTooltipParent().removeChild(this.tooltipNode)
			this.isRendered = false
		}.bind(this)
		/*
		 * TODO Rafael Henrique da Silva 06/08: Implementar lógica para desmontar tooltip em outros casos além de mouse leave
		 * e para poder montar a tooltip onclick
		*/
	}

	getTooltipParent() {
		if (this.options.appendTo === 'element') {
			return this.element
		} else if (this.options.appendTo === 'document') {
			return document.body
		}
	}

	setTooltipClasses(classes) {
		this.tooltipNode.className = `${classes.join(' ')} ${this.options.position}`
	}

	calculateTooltipPosition() {
		if (this.options.appendTo === 'element') {
			const rect = this.tooltipNode.querySelector('.tooltip-popup').getBoundingClientRect()

			const offset = calculateOffset(this.options.position, rect, this.options.spacing)
			this.setStyles(calculatePosition(this.options.position, offset))
		} else if (this.options.appendTo === 'document') {
			const tooltipRect = this.tooltipNode.querySelector('.tooltip-popup').getBoundingClientRect()
			const eleRect = this.element.getBoundingClientRect()
			const offSet = calculateOffset(this.options.position, tooltipRect, this.options.spacing)

			this.setStyles(calculatePosition(this.options.position, offSet, { tooltipRect, eleRect }))
		}
	}

	setContent(content) {
		const contentNode = this.tooltipNode.querySelectorAll('[data-tooltip-content]')[0]
		contentNode.innerHTML = DOMPurify.sanitize(content)
		// TODO Rafael Henrique da Silva 06/08: Implementar template builder apartir do bind de um object
	}

	setStyles(styles) {
		Object.keys(styles).forEach(key => {
			this.tooltipNode.style[key] = styles[key]
		})
	}

	eject() {
		if (this.element.contains(this.tooltipNode)) {
			this.element.removeChild(this.tooltipNode)
		} else if (document.body.contains(this.tooltipNode)) {
			document.body.removeChild(this.tooltipNode)
		}

		this.element.onmouseover = undefined
		this.element.onmouseleave = undefined
	}
}
