<template>
	<div class="mkt-tag-list">
		<div class="mkt-tag-list__wrapper" v-for="(tag, $index) in ltTags" :key="fnOptionText(tag)">
			<mkt-tag
				:tx-option-text="txOptionText"
				:fn-option-text="fnOptionText"
				:ob-tag="tag"
				@mkt-tag:remove="(event, obTag) => removeTagFromList($index, event, obTag)"
			></mkt-tag>
		</div>
	</div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import MktTag from './mktTag.vue'

export default {
	name: 'mkt-tag-list',
	components: {
		MktTag
	},
	props: {
		ltTags: new PropConfig({ type: Array, required: true }),
		txOptionText: new PropConfig({ type: String, required: false }),
		fnOptionText: new PropConfig({ type: Function, required: false })
	},
	methods: {
		removeTagFromList(index, event, obTag) {
			this.$emit('mkt-tag-list:remove', index, event, obTag)
		}
	},
	computed: {
		tagList() {
			return this.ltTags
		}
	}
}
</script>

<style src="./mktTagList.scss" lang="scss">
</style>
