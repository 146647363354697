<template>
	<div class="typeahead__wrapper" v-click-outside="clickOutsideTypeahead">
		<div class="typeahead__input__wrapper">
			<md-field
				:class="{'md-invalid' : hasErrors, 'md-read-only': blReadOnly }"
				class="typeahead__input"
			>
				<mkt-label
					v-if="txLabel || txHighligthedLabel"
					:tx-label="txLabel"
					:tx-highligthed-label="txHighligthedLabel"
					class="mkt-field__label"
				/>

				<span v-if="!blReadOnly" class="typeahead__input--icon">
					<mkt-icon tx-icon="icon-search2" :bl-hover="false" tx-font-size="16px"></mkt-icon>
				</span>

				<md-input
					v-model="viewValue"
					:placeholder="txPlaceholder"
					:required="blRequired"
					:disabled="blDisabled || blReadOnly"
					:id="txId"
					@focus="isClickOutsideActive = true"
					@keydown="search"
					@keydown.down="down"
					@keydown.up="up"
					v-on:keydown.enter="prevent"
					@keyup.enter="enter"
					@keyup.esc="clearViewValue"
					class="typeahead typeahead__input--field"
					autocomplete="off"
					/>
					<span v-if="!blDisabled && !blReadOnly" class="typeahead__input--deleteicon" @click="clearViewValue">
						<mkt-icon txIcon="icon-cancel" :bl-hover="true" tx-font-size="16px"></mkt-icon>
					</span>
					<span class="md-error" v-if="hasErrors">{{formValidationError || 'Campo obrigatório'}}</span>
			</md-field>
		</div>

		<md-content class="typeahead__resultlist md-scrollbar mkt-raised" v-if="showResultList && listResult.length">
				<ul class="typeahead__resultlist--list">
					<li
						v-for="(result, $index) in listResult"
						:key="$index"
						class="typeahead__resultlist--row"
						:class="activeClass($index)"
						@mousemove="setActive($index)"
						@click="selectValue(result)">
						<span class="typeahead__resultlist--item" :id="$index" v-html="$sanitize(buildResultTemplate(result, $index, listResult))"></span>
					</li>
				</ul>
		</md-content>

		<div class="typeahead__noresults mkt-raised" v-if="showNoResults" id="typeahead-no-result">
			<slot name="no-results-template">
				Nenhum resultado foi encontrado
			</slot>
		</div>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'
	import _ from 'lodash-core'
	import { incrementListIndex, decrementListIndex } from 'services/selectService'
	import { verticalScroll } from 'services/scrollService.js'
	import DOMPurify from 'dompurify'

export default {
		name: 'mkt-typeahead',
		props: {
			fnSearch: new PropConfig({ type: Function, required: true }),
			fnClear: new PropConfig({ type: Function, required: false }),
			fnSelect: new PropConfig({ type: Function, required: false }),
			txLabel: new PropConfig({ type: String, required: false }),
			txHighligthedLabel: new PropConfig({ type: String, required: false }),
			txPlaceholder: new PropConfig({ type: String, required: false }),
			optionText: new PropConfig({ type: [String, Function], required: false, valueDefault: 'value' }),
			fnCustomListTemplate: new PropConfig({ type: Function, required: false }),
			nrDigitsStartSearch: new PropConfig({ type: Number, required: false, valueDefault: 3 }),
			blRequired: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blDisabled: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blOnEnterAction: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blScrollToNoResults: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			txId: new PropConfig({ type: String }),
			value: new PropConfig({ required: false, valueDefault: null })
		},
		data() {
			return {
				viewValue: this.getOptionText(this.value),
				highlightedOption: '',
				formValidationError: '',
				current: undefined,
				listResult: [],
				showNoResults: false,
				debounceLock: false,
				showResultList: false,
				selectedValue: false,
				hasErrors: false,
				isClickOutsideActive: false,
				debounceOnSearch: _.debounce(this.debouncedSearch, 500),
				events: {
					clickOutside: () => this.$emit('mkt-typeahead:click-outside'),
					updateModel: (value) => this.$emit('input', value),
					onValuelessEnter: () => this.$emit('mkt-typeahead:on-valueless-enter', this.viewValue)
				}
			}
		},
		methods: {
			search(el) {
				// Não realizar busca caso não for inserido ou removido um character do campo
				if (!el || el.keyCode === 13 || el.keyCode === 16 || el.keyCode === 17 ||
					el.keyCode === 18 || el.keyCode === 225 || el.keyCode === 45 ||
					el.keyCode === 36 || el.keyCode === 33 || el.keyCode === 35 ||
					el.keyCode === 34 || el.keyCode === 20 || el.keyCode === 144 ||
					el.keyCode === 27 || el.keyCode === 40 || el.keyCode === 9 ||
					el.keyCode === 39 || el.keyCode === 38 || el.keyCode === 37) {
					return
				}

				if (this.selectedValue) {
					this.selectedValue = false
					return
				}

				const debounceOnSearch = this.debounceOnSearch
				this.current = undefined
				this.highlightedOption = this.viewValue

				if (!this.viewValue) {
					this.events.updateModel(null)
				}

				if (this.viewValue && this.viewValue.length >= this.nrDigitsStartSearch && !this.debounceLock) {
					debounceOnSearch()
				} else {
					debounceOnSearch.cancel()
					this.closeNoResultList()
					this.listResult = []
				}
			},
			debouncedSearch(el) {
				this.highlightedOption = this.viewValue
				if (!this.viewValue || this.viewValue.length < this.nrDigitsStartSearch) {
					this.closeResultList()
					return
				}

				Promise.resolve(this.fnSearch(this.viewValue)).then(data => {
					this.listResult = data
					const listResultLength = this.listResult.length
					this.showNoResults = !listResultLength
					this.showResultList = !this.showNoResults

					if (this.showNoResults && this.blScrollToNoResults) {
						this.$nextTick(() => {
							verticalScroll('typeahead-no-result')
						})
					}
				})
			},
			setActive(index) {
				this.current = index
			},
			activeClass(index) {
				return {
					active: this.current === index
				}
			},
			selectValue(value) {
				if (this.fnSelect) this.fnSelect(value)

				this.selectedValue = true
				this.hasErrors = false
				this.viewValue = this.getOptionText(value)
				this.closeResultList()
				this.events.updateModel(value)
			},
			up() {
				if (this.selectedValue || !this.showResultList) return

				this.current = decrementListIndex(this.current)

				if (this.current !== undefined) {
					this.viewValue = this.getOptionText(this.listResult[this.current])
				}
			},
			down() {
				if (this.selectedValue || !this.listResult.length || !this.showResultList) return

				const maxValue = this.listResult.length - 1
				this.current = incrementListIndex(this.current, maxValue)

				this.viewValue = this.getOptionText(this.listResult[this.current])
			},
			prevent(e) {
				e.preventDefault()
			},
			enter() {
				if (this.showResultList && this.current !== undefined && this.listResult[this.current]) {
					this.selectValue(this.listResult[this.current])
				} else {
					this.current = undefined
					this.closeResultList()
					this.closeNoResultList()
					if (this.blOnEnterAction) {
						this.events.onValuelessEnter()
						this.debounceOnSearch.cancel()
						this.debounceLock = true
						setTimeout(() => {
							this.debounceLock = false
						}, 250)
					}
				}
			},
			clearViewValue() {
				if (this.fnClear) this.fnClear()

				this.viewValue = ''
				this.debounceOnSearch.cancel()
				this.closeResultList()
				this.closeNoResultList()
				this.events.updateModel(null)
			},
			highlightModel(template) {
				const viewValueRegex = new RegExp(this.highlightedOption, 'gi')
				return template.replace(viewValueRegex, '<strong>$&</strong>')
			},
			buildResultTemplate(result, $index, listResult) {
				const templateToHighlight = this.fnCustomListTemplate ? this.fnCustomListTemplate(result, $index, listResult) : this.getOptionText(result)
				return DOMPurify.sanitize(this.highlightModel(templateToHighlight))
			},
			closeResultList() {
				this.showResultList = false
				this.current = undefined
			},
			closeNoResultList() {
				this.showNoResults = false
			},
			clickOutsideTypeahead() {
				if (!this.isClickOutsideActive) return

				this.viewValue = this.getOptionText(this.value)
				this.closeResultList()
				this.closeNoResultList()
				this.events.clickOutside()
				this.isClickOutsideActive = false

				if (this.blRequired) {
					this.hasErrors = !this.value
				}
			},
			getOptionText(result) {
				if (!result) return null

				return this.optionText instanceof Function ? this.optionText(result) : (result && result[this.optionText])
			}
		},
		watch: {
			value(value) {
				this.viewValue = this.getOptionText(value)
			}
		}
	}
</script>

<style src="./mktTypeahead.scss" lang="scss">

</style>
