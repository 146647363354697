import { SET_SELLER_ORDER_REASON } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerOrderReasons = new SellerRegistrationBaseModule('sellerOrderReasons', SET_SELLER_ORDER_REASON, [])

const state = sellerOrderReasons.getState()
const initialState = sellerOrderReasons.getInitialState()
const getters = sellerOrderReasons.getGetters()
const actions = sellerOrderReasons.getSetters()
const mutations = {
	[SET_SELLER_ORDER_REASON](state, orderReasons) {
		state.sellerRegistration.sellerOrderReasons = orderReasons
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
