<template>
  <div class="mkt-input-decimal">
		<md-field :md-counter="false" :class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }">
			<mkt-label class="mkt-label__input" :txLabel="txLabel"></mkt-label>
			<md-input
				v-model="fieldModel"
				v-on:keypress="isNumber"
				@paste="onPaste"
				@blur="onBlur"
				@focus="$emit('focus')"
				:placeholder="!blReadOnly ? txPlaceholder : '-'"
				:required="blDisabled || blRequired"
				:maxlength="nrMaxlength"
				:disabled="blDisabled || blReadOnly"
				:id="txId"
				type="text"
				ref="mktInputDecimal"
			></md-input>
			<span class="md-icon-error" v-if="hasErrors && isIconError">
				<mkt-icon
				tx-icon="icon-error"
				tx-icon-color="#EB5757"
				tx-font-size="20px"
						v-tooltip="{ text: (txErrorMessage || 'Campo obrigatório'), show: true, style: { maxWidth: '200px' }, options: { appendTo: 'document' } }"
				></mkt-icon>
			</span>
			<span class="md-error" v-else-if="hasErrors">{{formValidationError || 'Campo obrigatório'}}</span>
		</md-field>
  </div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import { transformValue } from 'services/transformDecimalService'
import { REGEX_NOT_PURE_NUMBER } from 'constants/Regex'
import { isNullOrUndefined } from 'services/objectService.js'
import { getStringNumbers } from 'services/stringBuilderService.js'
import formFieldMixin from 'components/globals/mktField/formFieldMixin.js'

export default {
	name: 'mkt-input-decimal',
	mixins: [formFieldMixin],
	data() {
		return {
			isInputActive: false
		}
	},
	props: {
		txSuffix: new PropConfig({ type: String, required: false, valueDefault: '' }),
		nrDecimal: new PropConfig({ type: Number, required: false, valueDefault: 2 })
	},
	mounted() {
		if (!isNullOrUndefined(this.value)) {
			const val = parseFloat(this.value)
			this.$emit('input', val.toFixed(this.nrDecimal))
		}
	},
	computed: {
		fieldModel:	{
			get() {
				return this.value ? transformValue(this.value.toString(), this.nrDecimal, this.txSuffix) : ''
			},
			set(newValue) {
				if (!newValue) {
					newValue = null
				} else {
					const setValue = transformValue(newValue, this.nrDecimal, this.txSuffix).replace(',', '.')
					if (this.txSuffix) {
						newValue = isNaN(setValue.replace(this.txSuffix, '')) ? null : setValue
					} else {
						newValue = isNaN(setValue) ? null : setValue
					}
				}
				this.$emit('input', newValue)
				if (this.blValidateOnSet && this.hasErrors) {
					this.validateField()
				}
			}
		}
	},
	methods: {
		isNumber(e) {
			const isNotNumber = e.key.match(REGEX_NOT_PURE_NUMBER)

			if (isNotNumber) {
				e.preventDefault()
			}
		},
		onPaste(e) {
			e.preventDefault()
			const clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData
			const text = clipboardData.getData('text')
			const val = getStringNumbers(text)
			if (val && !isNaN(val)) {
				this.$nextTick(() => {
					this.$refs.mktInputDecimal.model = transformValue(val, this.nrDecimal)
				})
			}
		},
		validateField() {
			if (this.blRequired) {
				this.hasErrors = !this.value
			} else {
				this.hasErrors = false
			}
		}
	}
}
</script>
