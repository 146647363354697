import Configuration from './configurations/'
import Dashboard from './dashboard/'
import Orders from './orders/'
import Products from './products/'
import Financial from './financial/'
import Sac from './sac/'
import Reports from './reports/'
import Promotion from './promotion/'

const baseAuthority = 'PANEL_COLOMBO'

const Authorities = [
	...Configuration,
	...Dashboard,
	...Orders,
	...Products,
	...Financial,
	...Sac,
	...Reports,
	...Promotion
]

Authorities.forEach(auth => auth.setBaseAuthority(baseAuthority))

export default Authorities
