import {
	ROUTE_PROMOTION_MODERATION_COLOMBO,
	ROUTE_PROMOTION_PRODUCT_MODERATION_COLOMBO,
	ROUTE_PROMOTION_CATEGORY_MODERATION_COLOMBO
} from 'constants/RouterNames'

const promotionModeration = () => import('components/pages/secured/promotion/promotionColombo/promotionModeration/promotionModeration.vue')
const promotionModerationProduct = () => import('components/pages/secured/promotion/promotionBase/promotionBaseProduct/promotionBaseProduct.vue')
const promotionModerationCategory = () => import('components/pages/secured/promotion/promotionBase/promotionBaseCategory/promotionBaseCategory.vue')

const promotionModerationRoute = {
	path: '/promocao-moderacao/:promotionId',
	component: promotionModeration,
	name: ROUTE_PROMOTION_MODERATION_COLOMBO.name,
	meta: { requiresAuthorities: ROUTE_PROMOTION_MODERATION_COLOMBO.routerAuth, breadcrumb: { name: 'Moderação', active: false } },
	children: [
		{ path: '/promocao-moderacao/:promotionId/produto', props: { isModeration: true }, component: promotionModerationProduct, name: ROUTE_PROMOTION_PRODUCT_MODERATION_COLOMBO.name, meta: { requiresAuthorities: ROUTE_PROMOTION_PRODUCT_MODERATION_COLOMBO.routerAuth } },
		{ path: '/promocao-moderacao/:promotionId/categoria', props: { isModeration: true }, component: promotionModerationCategory, name: ROUTE_PROMOTION_CATEGORY_MODERATION_COLOMBO.name, meta: { requiresAuthorities: ROUTE_PROMOTION_CATEGORY_MODERATION_COLOMBO.routerAuth } }
	]
}

export default promotionModerationRoute
