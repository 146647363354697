import store from 'src/store/store'
import { isNullOrUndefined } from 'services/objectService.js'

const hasAuthority = (authorities) => {
	if (isNullOrUndefined(authorities)) {
		return true
	}

	const authorityArr = mapToAuthoritiesArray(authorities).map(mapToLowerCase)

	return hasNoRequiredAuthority(authorityArr) || hasSomeMatchingProfile(authorityArr)
}

const hasNoRequiredAuthority = (anArray) => {
	return anArray && anArray.length === 0
}

const hasSomeMatchingProfile = (authorities) => {
	const profiles = getLoggedUserProfiles()

	return profiles && authorities.some(authority => profiles.includes(mapToLowerCase(authority))
	)
}

const mapToAuthoritiesArray = (authorities) => {
	if (authorities instanceof Array) {
		return authorities
	} else if (authorities instanceof Object) {
		return Object.values(authorities)
	} else {
		return [authorities]
	}
}

const mapToLowerCase = (aString) => isNullOrUndefined(aString) ? aString : aString.toLowerCase()

const getLoggedUserProfiles = () => {
	const loggedUser = store.getters.getLoggedUser

	return !loggedUser ? null : loggedUser.profiles
}

const getLoggedSellerId = () => {
	return store.getters.getLoggedSellerId
}

export { hasAuthority, getLoggedSellerId }
