import { Post, Get } from './baseHttpService'

const postFile = (url, file, params) => {
	const formData = buildFormData(file, params)
	return Post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

const buildFormData = (file, params) => {
	const formData = new FormData()
	formData.append('file', file)
	params && Object.keys(params).forEach(key => {
		formData.append(key, params[key])
	})
	return formData
}

const baseUploadUrl = '/upload'

const getPresignedUrl = () => {
	return Get(`${baseUploadUrl}/presigned-url`)
}

const postValidateFile = (urlSource, fileType) => {
	return Post(`${baseUploadUrl}/validate`, { urlSource, fileType })
}

export {
	postFile,
	getPresignedUrl,
	postValidateFile
}
