import {
	SET_CLEAN_SELLER_REGISTRATION,
	SET_SELLER_REGISTRATION
} from 'constants/MutationTypes'

import sellerDetails from './sellerDetails'
import sellerAddress from './sellerAddress'
import sellerAboutCompany from './sellerAboutCompany'
import sellerSeo from './sellerSeo'
import sellerBankDetails from './sellerBankDetails'
import sellerConfiguration from './sellerConfiguration'
import sellerIntegration from './sellerIntegration'
import sellerCommission from './sellerCommission'
import sellerSettings from './sellerSettings'
import sellerOrderReasons from './sellerOrderReason'
import sellerPaymentTicketSettings from './sellerPaymentTicketSettings'
import sellerPaymentSettings from './sellerPaymentSettings'
import DayOptions from 'constants/DayOptions'

// initial state
const state = {
	blSellerEdit: null,
	blColomboEdit: null,
	readAllowedSections: null,
	writeAllowedSections: null,
	sellerRegistration: {
		sellerId: null,
		sellerAdmin: null,
		...sellerDetails.state,
		...sellerAddress.state,
		...sellerAboutCompany.state,
		...sellerSeo.state,
		...sellerBankDetails.state,
		...sellerConfiguration.state,
		...sellerIntegration.state,
		...sellerCommission.state,
		...sellerSettings.state,
		...sellerOrderReasons.state,
		...sellerPaymentTicketSettings.state,
		...sellerPaymentSettings.state
	}
}

// getters
const getters = {
	...sellerDetails.getters,
	...sellerAddress.getters,
	...sellerAboutCompany.getters,
	...sellerSeo.getters,
	...sellerBankDetails.getters,
	...sellerConfiguration.getters,
	...sellerIntegration.getters,
	...sellerCommission.getters,
	...sellerSettings.getters,
	...sellerOrderReasons.getters,
	...sellerPaymentTicketSettings.getters,
	...sellerPaymentSettings.getters,
	getSellerRegistration: state => state.sellerRegistration,
	getSellerAdmin: state => state.sellerRegistration.sellerAdmin
}

// actions
const actions = {
	...sellerDetails.actions,
	...sellerAddress.actions,
	...sellerAboutCompany.actions,
	...sellerSeo.actions,
	...sellerBankDetails.actions,
	...sellerConfiguration.actions,
	...sellerIntegration.actions,
	...sellerCommission.actions,
	...sellerSettings.actions,
	...sellerOrderReasons.actions,
	...sellerPaymentTicketSettings.actions,
	...sellerPaymentSettings.actions,
	cleanSellerRegistration: ({ commit }) => {
		commit(SET_CLEAN_SELLER_REGISTRATION)
	},
	setSellerRegistration: ({ commit }, sellerRegistration) => {
		commit(SET_SELLER_REGISTRATION, sellerRegistration)
	}
}

// mutations
const mutations = {
	...sellerDetails.mutations,
	...sellerAddress.mutations,
	...sellerAboutCompany.mutations,
	...sellerSeo.mutations,
	...sellerBankDetails.mutations,
	...sellerConfiguration.mutations,
	...sellerIntegration.mutations,
	...sellerCommission.mutations,
	...sellerSettings.mutations,
	...sellerOrderReasons.mutations,
	...sellerPaymentTicketSettings.mutations,
	...sellerPaymentSettings.mutations,
	[SET_CLEAN_SELLER_REGISTRATION](state) {
		state.sellerRegistration = {
			...sellerDetails.initialState,
			...sellerAddress.initialState,
			...sellerAboutCompany.initialState,
			...sellerSeo.initialState,
			...sellerBankDetails.initialState,
			...sellerConfiguration.initialState,
			...sellerIntegration.initialState,
			...sellerCommission.initialState,
			...sellerSettings.initialState,
			...sellerOrderReasons.initialState,
			...sellerPaymentTicketSettings.initialState,
			...sellerPaymentSettings.initialState
		}
		state.blSellerEdit = state.blColomboEdit = null
	},
	[SET_SELLER_REGISTRATION](state, sellerRegistration) {
		sellerRegistration.sellerCommission = sellerRegistration.sellerCommission && { categoryTree: [...sellerRegistration.sellerCommission] }
		if (sellerRegistration.sellerSettings && sellerRegistration.sellerSettings.anticipationTax) {
			sellerRegistration.sellerSettings.anticipationTax = sellerRegistration.sellerSettings.anticipationTax.toFixed(2)
		}
		state.sellerRegistration = { ...state[sellerRegistration], ...sellerRegistration }

		const sellerPaymentTicketSettings = state.sellerRegistration.sellerPaymentTicketSettings
		if (sellerPaymentTicketSettings) {
			sellerPaymentTicketSettings.forEach(ticketSettings => {
				const schedule = ticketSettings.ticketScheduling
				ticketSettings.ticketScheduling = DayOptions.find(day => day.value === schedule)
			})
		}
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
