import {
	ROUTE_ORDER_SELLER
	,	ROUTE_ORDER_SELLER_LIST
	, ROUTE_ORDER_SELLER_DETAIL
} from 'constants/RouterNames'

const orderSeller = () => import('components/pages/secured/order/orderSeller/orderSeller')
const orderSellerList = () => import('components/pages/secured/order/orderSeller/orderSellerList/orderSellerList')
const orderSellerDetail = () => import('components/pages/secured/order/orderSeller/orderSellerDetail/orderSellerDetail')

const orderSellerRoute = {
	path: '/pedidos-seller',
	redirect: '/listagem-pedidos-seller',
	component: orderSeller,
	name: ROUTE_ORDER_SELLER.name,
	meta: { breadcrumb: { name: 'Meus Pedidos' }, requiresAuthorities: ROUTE_ORDER_SELLER.routerAuth },
	children: [
		{ path: '/listagem-pedidos-seller', component: orderSellerList, name: ROUTE_ORDER_SELLER_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_ORDER_SELLER_LIST.routerAuth } },
		{ path: '/detalhamento-pedidos-seller/:idOrderSeller', props: true, component: orderSellerDetail, name: ROUTE_ORDER_SELLER_DETAIL.name, meta: { breadcrumb: { name: 'Pedido' }, requiresAuthorities: ROUTE_ORDER_SELLER_DETAIL.routerAuth } }
	]
}

export default orderSellerRoute
