const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index
}

const filterFunctions = {
	onlyUnique
}

const alphabeticSort = (beforeIndex, actualIndex) => {
	return beforeIndex.localeCompare(actualIndex)
}

const alphabeticSortList = (arr, field) => {
	return arr.sort((a, b) => a[field].toLowerCase().localeCompare(b[field].toLowerCase()))
}

const sortFunctions = {
	alphabeticSort,
	alphabeticSortList
}

const stringToArray = (string, separator) => {
	if (typeof (string) === 'string' || string instanceof String) {
		var listStrings = string.split(separator)
		return listStrings.map(s => s.trim())
	} else if (Array.isArray(string)) {
		return string
	} else {
		return []
	}
}

const convertFunctions = {
	stringToArray
}

export {
	filterFunctions,
	sortFunctions,
	convertFunctions
}
