import store from 'src/store/store'
import { sendErrorNotification } from 'services/notificationService'
import { SET_LOADER_STATE_MUTATION } from 'constants/MutationTypes'
import { refreshSession } from 'services/userSessionService'
import { setErrorsToElements } from 'services/formErrorsService.js'

const setLoaderState = (state) => {
	store.commit(SET_LOADER_STATE_MUTATION, state)
}

const interceptorRequest = (config) => {
	setLoaderState(true)
	return config
}

const interceptorRequestError = (error) => {
	setLoaderState(false)
	return Promise.reject(error)
}

const interceptorResponseSuccess = (response) => {
	refreshSession()
	setLoaderState(false)
	return response
}

const interceptorResponseError = (error) => {
	refreshSession()
	setLoaderState(false)

	/* TODO Rafael Henrique da Silva 13/03/2018 Verificar como modificar o retorno de error do axios
		para retornar direto a response do server e não o erro de javascript
	*/

	const { response, message } = error
	if (response) {
		const { data } = response
		data.message && sendErrorNotification(data.message)
		data.details && setErrorsToElements(data.details)
	} else if (message !== 'Request overridden') {
		sendErrorNotification('Ocorreu um erro! Tente novamente mais tarde')
	}

	return Promise.reject(error)
}

export {
	interceptorRequest,
	interceptorRequestError,
	interceptorResponseSuccess,
	interceptorResponseError
}
