import {
	SET_REBATE_INITIAL_STATE,
	SET_REBATE_BASE_FIELDS,
	SET_REBATE_STATUS,
	SET_REBATE_TYPE,
	SET_REBATE_EDITION_TYPE_STATE,
	CLEAR_SPECIFIC_REBATE_DATA
} from 'constants/MutationTypes'

import EditionType from 'domains/EditionType.js'

import rebateProduct from './rebateProduct'
import rebateCategory from './rebateCategory'

import RebateDto from 'dtos/promotionDtos/RebateDto.js'

// initial state
const state = {
	rebate: new RebateDto({}),
	markedFields: [],
	editionType: EditionType.ALL,
	disableInRouteRedirects: false
}

// getters
const getters = {
	getRebate: state => state.rebate,
	getRebateType: state => state.rebate && state.rebate.rebateType,
	getRebateStatus: state => state.rebate && state.rebate.rebateStatus,
	isInRebateRouteRedirectsDisabled: state => state.disableInRouteRedirects,
	isRebateEditionTypeAll: state => state.editionType === EditionType.ALL,
	isRebateEditionTypeNone: state => state.editionType === EditionType.NONE,
	...rebateProduct.getters,
	...rebateCategory.getters
}

// actions
const actions = {
	setRebateBaseFields: ({ commit }, { fieldName, fieldValue }) => {
		commit(SET_REBATE_BASE_FIELDS, { fieldName, fieldValue })
	},
	setRebateType: ({ commit }, rebateType) => {
		commit(SET_REBATE_TYPE, rebateType)
	},
	setRebateStatus: ({ commit }, rebateStatus) => {
		commit(SET_REBATE_STATUS, rebateStatus)
	},
	setRebateInitialState: ({ commit }, rebate) => {
		commit(SET_REBATE_INITIAL_STATE, rebate)
	},
	setRebateEditionType: ({ commit }, editionType) => {
		commit(SET_REBATE_EDITION_TYPE_STATE, editionType)
	},
	clearSpecificData: ({ commit }) => {
		commit(CLEAR_SPECIFIC_REBATE_DATA)
	},
	...rebateProduct.actions,
	...rebateCategory.actions
}

// mutations
const mutations = {
	[SET_REBATE_INITIAL_STATE](state, rebate) {
		if (rebate) {
			state.disableInRouteRedirects = false
			state.rebate = new RebateDto(rebate)
		} else {
			state.disableInRouteRedirects = true
			state.markedFields = []
			state.editionType = EditionType.ALL
			state.rebate = new RebateDto({})
		}
	},
	[CLEAR_SPECIFIC_REBATE_DATA](state) {
		state.rebate.clearSpecificData()
	},
	[SET_REBATE_BASE_FIELDS](state, { fieldName, fieldValue }) {
		state.rebate[fieldName] = fieldValue
	},
	[SET_REBATE_STATUS](state, rebateStatus) {
		state.promotion.promotionStatus = rebateStatus
	},
	[SET_REBATE_EDITION_TYPE_STATE](state, editionType) {
		state.editionType = editionType
	},
	[SET_REBATE_TYPE](state, rebateType) {
		state.rebate.rebateType = rebateType
		mutations[SET_REBATE_INITIAL_STATE](state, state.rebate)
	},
	...rebateProduct.mutations,
	...rebateCategory.mutations
}

export default {
	state,
	getters,
	actions,
	mutations
}
