<template>
  <div class="mkt-input-cpf-cnpj">
		<md-field
			:class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }"
		>
			<mkt-label v-if="txLabel" class="mkt-field__label" :txLabel="txLabel" :bl-required="blRequired"></mkt-label>
			<md-input
				v-if="isFieldTypeCpf"
				:placeholder="txPlaceholder"
				:disabled="blDisabled || blReadOnly"
				:type="tpInputType.type"
				v-model="componentModel"
				v-mask="'###.###.###-##'"
				:id="txId"
				:required="blRequired"
				@blur="validateField"
			></md-input>
			<md-input
				v-if="isFieldTypeCnpj"
				:placeholder="txPlaceholder"
				:disabled="blDisabled || blReadOnly"
				:type="tpInputType.type"
				v-model="componentModel"
				v-mask="'##.###.###/####-##'"
				:id="txId"
				:required="blRequired"
				@blur="validateField"
			></md-input>
			<span class="md-error" v-if="hasErrors">{{formValidationError || 'Campo obrigatório'}}</span>
		</md-field>
  </div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import InputType from 'domains/InputType'
import CpfCnpj from 'domains/CpfCnpj.js'

import mktInput from 'src/components/globals/mktInput/mktInput'
import { mask } from 'vue-the-mask'

export default {
	name: 'mkt-input-cpf-cnpj',
	components: {
		mktInput
	},
	directives: {
		mask
	},
	props: {
		txLabel: new PropConfig({ type: String }),
		txPlaceholder: new PropConfig({ type: String }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		blDisabled: new PropConfig({ type: Boolean }),
		tpField: new PropConfig({ type: CpfCnpj, valueDefault: () => CpfCnpj.CPF }),
		nrMaxlength: new PropConfig({ type: Number, valueDefault: 10 }),
		txId: new PropConfig({ type: String, required: false }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		value: new PropConfig({ required: false })
	},
	data() {
		return {
			tpInputType: InputType.NUMBER,
			hasErrors: false,
			formValidationError: ''
		}
	},
	mounted() {
		const val = this.value
		this.$nextTick(() => {
			this.componentModel = val
		})
	},
	methods: {
		validateField() {
			this.$emit('blur')
			if (this.blRequired) {
				this.$nextTick(() => {
					this.hasErrors = !this.value
				})
			} else {
				this.hasErrors = false
			}
		}
	},
	computed: {
		isFieldTypeCpf() {
			return this.tpField === CpfCnpj.CPF
		},
		isFieldTypeCnpj() {
			return this.tpField === CpfCnpj.CNPJ
		},
		componentModel: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	}
}
</script>
<style src="./mktInputCpfCnpj.scss" lang="scss">

</style>
