<template>
	<div class="mkt-input">
		<md-input
			:placeholder="txPlaceholder"
			:maxlength="nrMaxlength"
			v-model="inputModel"
			:disabled="blDisabled || blReadOnly"
			v-on:keypress="validateInputType"
			v-on:keydown.enter="checkPrevent"
			@paste="onPaste"
			ref="mktInput"
			:type="tpInputType.type"
			:id="txId"
			@blur="$emit('blur')"
			@focus="$emit('focus')"
			:required="blRequired">
		</md-input>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'
	import InputType from 'domains/InputType'
	import { REGEX_NOT_PURE_NUMBER } from 'constants/Regex'

	export default {
		props: {
			txPlaceholder: new PropConfig({ type: String }),
			tpInputType: new PropConfig({ type: InputType, valueDefault: () => InputType.TEXT }),
			blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
			blPreventDefault: new PropConfig({ type: Boolean, valueDefault: false }),
			blDisabled: new PropConfig({ type: Boolean }),
			nrMaxlength: new PropConfig({ type: Number, valueDefault: 10 }),
			value: new PropConfig({ required: false }),
			blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			txId: new PropConfig({ type: String, required: false })
		},
		methods: {
			validateInputType(e) {
				const isNotValid = this.fieldTypeRegex ? e.key.match(this.fieldTypeRegex) : false

				if (isNotValid) {
					e.preventDefault()
				}
			},
			checkPrevent(e) {
				if (this.blPreventDefault) {
					e.preventDefault()
				}
			},
			onPaste(e) {
				const clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData
				const text = clipboardData.getData('text')
				if (this.fieldTypeRegex) {
					e.preventDefault()
					this.$nextTick(() => {
						this.$refs.mktInput.model = text.replace(this.fieldTypeRegex, '')
					})
				}
				if (text !== null && this.nrMaxlength !== null && text.length > this.nrMaxlength) {
					e.preventDefault()
					this.$nextTick(() => {
						this.$refs.mktInput.model = text.trim().substr(0, this.nrMaxlength)
					})
				}
			}
		},
		computed: {
			inputModel: {
				get() {
					return this.value
				},
				set(newVal) {
					this.$emit('input', newVal)
				}
			},
			fieldTypeRegex() {
				switch (this.tpInputType) {
				case InputType.NUMBER:
					return REGEX_NOT_PURE_NUMBER
				default:
					return null
				}
			}
		}
	}
</script>
<style src="./mktInput.scss" lang="scss">
</style>
