import Vue from 'vue'
import mktLightBox from 'components/generics/mktLightBox/mktLightBox.vue'
import { eventListen, EVENT_LIGHT_BOX_CLOSE } from 'services/eventBusService.js'

const lightBoxContainer = document.createElement('Div')
lightBoxContainer.id = 'lightbox-container'

const openLightBox = (url) => {
	let Lightbox = new Vue({
		render(createElement)	{
			return createElement(mktLightBox, {
				props: {
					url
				}
			})
		}
	})

	document.body.appendChild(lightBoxContainer)
	Vue.nextTick(() => {
		Lightbox.$mount('#lightbox-container')

		eventListen(EVENT_LIGHT_BOX_CLOSE, () => {
			if (Lightbox) {
				Lightbox.$destroy()
				Lightbox.$el.remove()
				Lightbox = undefined
			}
		})
	})
}

export {
	openLightBox
}
