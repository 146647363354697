import { SET_SELLER_COMMISSION } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerCommission = new SellerRegistrationBaseModule('sellerCommission', SET_SELLER_COMMISSION)

const state = sellerCommission.getState()
const initialState = sellerCommission.getInitialState()
const getters = sellerCommission.getGetters()
const actions = sellerCommission.getSetters()
const mutations = sellerCommission.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
