<template>
	<div class="mkt-file-upload">
		<div class="mkt-file-upload__label">
			<mkt-label :bl-required="blRequired" v-if="txLabel" :tx-label="txLabel"></mkt-label>
		</div>
		<mkt-file-dropzone-container
				v-model="uploadedFiles"
				ref="dropzoneContainer"
				:lt-processing-files="onProgressFiles"
				:ob-params="obParams"
				:bl-single-upload="blSingleUpload"
				:tx-id-file-upload="txDropzoneId"
				:tx-image-source="txImagePath"
				:tx-image-hash="txImageHash"
				:tx-url-upload="txUrlUpload"
				:tx-description="txDescription"
				:tx-additional-description="txAdditionalDescription"
				:nr-max-files="nrMaxFiles"
				:bl-signed-key="blSignedKey"
				:nr-max-filesize="nrMaxFilesize"
				:accepted-file-types="acceptedMimeType"
				:file-type="fileType"
				@mkt-file-upload-container:loading="setProgressFiles"
				@mkt-file-upload-container:load-end="events.onLoadend"
				@mkt-file-upload:promise-file="emitPromiseFile"
		></mkt-file-dropzone-container>
		<div class="mkt-file-upload__thumbnails">
			<mkt-draggable-file
				v-if="draggeableFiles && draggeableFiles.length"
				:value="draggeableFiles"
				@input="(files) => uploadedFiles = files"
				:tx-image-source="txImagePath"
				:tx-id-file-upload="txDropzoneId"
				:bl-has-drag="blHasDrag"
				:bl-delete-file="!blDisabled"
				:bl-has-hover="!blDisabled"
				:accepted-file-types="acceptedMimeType"
				:bl-allow-delete-initial-files="blAllowDeleteInitialFiles"
				@mkt-draggable-file:remove="removeFile"
				@mkt-file-upload:promise-file="emitPromiseFile"
			></mkt-draggable-file>
			<mkt-file-loader :files="onProgressFiles" tx-file-progress="progress"></mkt-file-loader>
		</div>
	</div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import MimeType from 'domains/MimeType'

import mktDraggableFile from 'components/generics/mktFileComponents/mktDraggableFile/mktDraggableFile.vue'
import mktFileLoader from 'components/generics/mktFileComponents/mktFileLoader/mktFileLoader.vue'
import mktFileDropzoneContainer from 'components/generics/mktFileComponents/mktFileDropzoneContainer/mktFileDropzoneContainer.vue'

export default {
	components: {
		mktDraggableFile,
		mktFileLoader,
		mktFileDropzoneContainer
	},
	props: {
		value: new PropConfig({ type: [Array, Object], required: false }),
		txIcon: new PropConfig({ type: String, required: false, valueDefault: 'icon-image-file' }),
		txDescription: new PropConfig({ type: String, required: false, valueDefault: 'Arraste e solte aqui o seu arquivo' }),
		txAdditionalDescription: new PropConfig({ type: String, required: false, valueDefault: ' ' }),
		txUrlUpload: new PropConfig({ required: false }),
		txLabel: new PropConfig({ type: String, required: false }),
		txDropzoneId: new PropConfig({ type: String, required: true }),
		txImagePath: new PropConfig({ type: String, required: false, valueDefault: 'dataURL' }),
		txImageHash: new PropConfig({ type: String, required: false, valueDefault: 'tempFileKey' }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		blFileModelObject: new PropConfig({ type: Boolean, valueDefault: false }),
		blAllowDeleteInitialFiles: new PropConfig({ type: Boolean, valueDefault: true }),
		blShowFilename: new PropConfig({ type: Boolean, valueDefault: false }),
		blDisabled: new PropConfig({ type: Boolean, valueDefault: false }),
		blSingleUpload: new PropConfig({ type: Boolean, valueDefault: false }),
		blHasDrag: new PropConfig({ type: Boolean, valueDefault: true }),
		blSignedKey: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		nrMaxFilesize: new PropConfig({ type: Number, required: false, valueDefault: 3 }),
		nrMaxFiles: new PropConfig({ type: Number, required: false, valueDefault: 10 }),
		obParams: new PropConfig({ type: Object }),
		acceptedMimeType: new PropConfig({ type: MimeType, required: true }),
		fileType: new PropConfig({ type: String, required: false })
	},
	data() {
		return {
			draggeableFiles: [],
			onProgressFiles: [],
			events: {
				updateModel: (value) => this.$emit('input', value),
				onLoadend: (value) => this.$emit('mkt-file-upload:on-loadend', value)
			}
		}
	},
	mounted() {
		if (this.uploadedFiles && this.uploadedFiles.length) {
			this.draggeableFiles = this.uploadedFiles
		}
	},
	methods: {
		setProgressFiles(files) {
			this.onProgressFiles = files
		},
		updateModel(val) {
			if (this.blSingleUpload) {
				this.events.updateModel(val[0])
			} else {
				this.events.updateModel(val)
			}
		},
		emitPromiseFile(promise) {
			this.$emit('mkt-file-upload:promise-file', promise)
		},
		verifyValue(val) {
			if (val) {
				const isArray = val instanceof Array
				return isArray ? val : [val]
			} else {
				return []
			}
		},
		removeFile(file) {
			this.updateModel(this.uploadedFiles)
			this.$refs.dropzoneContainer.clearFileInput()
		}
	},
	computed: {
		uploadedFiles: {
			get() {
				return this.verifyValue(this.value)
			},
			set(val) {
				this.updateModel(val)
			}
		}
	},
	watch: {
		uploadedFiles(uploadedFiles) {
			this.draggeableFiles = uploadedFiles
		}
	}
}
</script>
<style src="./mktFileUpload.scss" lang="scss">
</style>
