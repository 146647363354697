import {
	ROUTE_DELETE_DATA
} from 'constants/RouterNames'

const viewData = () => import('src/components/pages/secured/configuration/deleteData/deleteData')

const configurationDeleteData = {
	path: '/consultar',
	component: viewData,
	name: ROUTE_DELETE_DATA.name,
	meta: { breadcrumb: { name: 'LGPD - Exclusão de Dados' }, requiresAuthorities: ROUTE_DELETE_DATA.routerAuth }
}

export default configurationDeleteData
