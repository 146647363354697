import {
	ROUTE_CONFIGURATION_USER_SELLER
	, ROUTE_USER_SELLER_LIST
	, ROUTE_USER_REGISTRATION_SELLER
	, ROUTE_USER_SELLER_UPDATE
} from 'constants/RouterNames'

const configurationUser = () => import('components/pages/secured/configuration/configurationUser/configurationUser.vue')
const userList = () => import('components/pages/secured/configuration/configurationUser/userSeller/userSellerList/userSellerList')
const userRegistrationSeller = () => import('components/pages/secured/configuration/configurationUser/userSeller/userSellerRegistration')
const userUpdateSeller = () => import('components/pages/secured/configuration/configurationUser/userSeller/userSellerUpdate/userSellerUpdate')

const userConfiguration = {
	path: '/usuarios-seller',
	redirect: '/listagem-usuarios-seller',
	meta: { breadcrumb: { name: 'Usuários' }, requiresAuthorities: ROUTE_CONFIGURATION_USER_SELLER.routerAuth },
	component: configurationUser,
	name: ROUTE_CONFIGURATION_USER_SELLER.name,
	children: [
		{ path: '/cadastro-usuarios-seller', component: userRegistrationSeller, name: ROUTE_USER_REGISTRATION_SELLER.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_USER_REGISTRATION_SELLER.routerAuth } },
		{ path: '/listagem-usuarios-seller', component: userList, name: ROUTE_USER_SELLER_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_USER_SELLER_LIST.routerAuth } },
		{ path: '/edicao-usuarios-seller/:userId', props: true, component: userUpdateSeller, name: ROUTE_USER_SELLER_UPDATE.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_USER_SELLER_UPDATE.routerAuth } }
	]
}

export default userConfiguration
