export default class Recaptcha {
	constructor({ blRenderOnMount, elementId, opts, siteKey }) {
		this.elementId = elementId
		this.widgetId = null
		this.options = opts
		if (blRenderOnMount) {
			this.renderOnMount(siteKey)
		}
	}

	renderOnMount(siteKey) {
		if (this.isRecaptchaLoaded) {
			this.render(siteKey)
		} else {
			const self = this
			window.onloadCaptchaCallback = function() {
				self.render(siteKey)
			}
		}
	}

	render(sitekey) {
		if (!this.isCaptchaRendered) {
			const options = { ...this.options, sitekey }
			this.widgetId = window.grecaptcha.render(document.getElementById(this.elementId), options)
		} else {
			this.reset()
		}
	}

	reset() {
		if (this.isCaptchaRendered) {
			window.grecaptcha.reset(this.widgetId)
		} else {
			throw new Error('ReCAPTCHA not rendered!')
		}
	}

	execute() {
		if (this.isCaptchaRendered) {
			window.grecaptcha.execute(this.widgetId)
		} else {
			throw new Error('ReCAPTCHA not rendered!')
		}
	}

	get isCaptchaRendered() {
		return this.widgetId !== null
	}

	get isRecaptchaLoaded() {
		return window.hasOwnProperty('grecaptcha') && window.grecaptcha.render
	}
}
