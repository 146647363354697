import { SET_SELLER_PAYMENT_TICKET_SETTINGS } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerPaymentTicketSettings = new SellerRegistrationBaseModule('sellerPaymentTicketSettings', SET_SELLER_PAYMENT_TICKET_SETTINGS, [])

const state = sellerPaymentTicketSettings.getState()
const initialState = sellerPaymentTicketSettings.getInitialState()
const getters = sellerPaymentTicketSettings.getGetters()
const actions = sellerPaymentTicketSettings.getSetters()
const mutations = {
	[SET_SELLER_PAYMENT_TICKET_SETTINGS](state, paymentSettings) {
		state.sellerRegistration.sellerPaymentTicketSettings = paymentSettings
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
