import {
	ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY,
	ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY_LIST
} from 'constants/RouterNames'

const reportOrdersByProductHistory = () => import('components/pages/secured/reports/reportOrdersByProductHistory/reportOrdersByProductHistory')
const reportOrdersByProductHistoryList = () => import('components/pages/secured/reports/reportOrdersByProductHistory/reportOrdersByProductHistoryList/reportOrdersByProductHistoryList')

const reportOrdersByProductHistoryRoute = {
	path: '/report-orders-by-product-history',
	redirect: '/relatorio-pedidos-por-produto-historico',
	component: reportOrdersByProductHistory,
	name: ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY.name,
	meta: { breadcrumb: { name: 'Pedidos por histórico de produto' }, requiresAuthorities: ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY.routerAuth },
	children: [
		{ path: '/relatorio-pedidos-por-produto-historico', component: reportOrdersByProductHistoryList, name: ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY_LIST.routerAuth } }
	]
}

export default reportOrdersByProductHistoryRoute
