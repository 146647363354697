import Enum from './Domain'
import pdfAltThumbnail from 'src/assets/img/icone-pdf.png'

class MimeType extends Enum { }

MimeType.initEnum({
	PDF: {
		code: ['application/pdf'],
		format: 'PDF',
		thumbnailImg: pdfAltThumbnail,
		fileSignature: ['25504446']
	},
	IMAGE: {
		code: ['image/jpeg', 'image/jpg', 'image/*'],
		format: 'JPG, JPEG',
		fileSignature: ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8', '89504e47']
	}
})

/*
	Lista de referência para assinatura de arquivos
	https://en.wikipedia.org/wiki/List_of_file_signatures
*/

export default MimeType
