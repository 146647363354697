import {
	ROUTE_CONFIGURATION_PAYMENT_DIARY
	, ROUTE_CONFIGURATION_PAYMENT_DIARY_LIST
} from 'constants/RouterNames'

const diarySettings = () => import('components/pages/secured/configuration/diarySettings/diarySettings')
const diarySettingsList = () => import('components/pages/secured/configuration/diarySettings/diarySettingsList/diarySettingsList')

const configurationDiarySettings = {
	path: '/diarios-pagamento',
	redirect: '/listagem-diarios-pagamento',
	component: diarySettings,
	name: ROUTE_CONFIGURATION_PAYMENT_DIARY.name,
	meta: { requiresAuthorities: ROUTE_CONFIGURATION_PAYMENT_DIARY.routerAuth },
	children: [
		{ path: '/listagem-diarios-pagamento', component: diarySettingsList, name: ROUTE_CONFIGURATION_PAYMENT_DIARY_LIST.name, meta: { breadcrumb: { name: 'Diários de Pagamento' }, requiresAuthorities: ROUTE_CONFIGURATION_PAYMENT_DIARY_LIST.routerAuth } }
	]
}

export default configurationDiarySettings
