import loggedUser from './loggedUser'
import stepsFooter from './stepsFooter'
import routeTitle from './routeTitle'
import loaderState from './loaderHeader'
import passwordRecovery from './passwordRecovery'
import notificationHeader from './notificationHeader'
import userSession from './userSession'

export default {
	loggedUser,
	stepsFooter,
	routeTitle,
	loaderState,
	passwordRecovery,
	notificationHeader,
	userSession
}
