import {
	ROUTE_SERVER_ERROR
} from 'constants/RouterNames'

import securedRoute from './secured/'
import loginRoute from './login/'

// error page
import serverError from 'components/pages/errorPages/serverError/serverError'

const routes = [
	securedRoute,
	loginRoute,
	{
		path: '*',
		component: serverError,
		name: ROUTE_SERVER_ERROR.name
	}
]

export default routes
