
'use strict'

export default class PromotionDto {
	constructor({ fullNameLock, lockModeration, reprovedReason, sellerName, promotionId, description, startPromotion, endPromotion, paymentMethod, promotionType, promotionStatus }) {
		this.fullNameLock = fullNameLock || null
		this.lockModeration = lockModeration || false
		this.sellerName = sellerName || null
		this.promotionId = promotionId || null
		this.description = description || null
		this.startPromotion = startPromotion || null
		this.endPromotion = endPromotion || null
		this.paymentMethod = paymentMethod || null
		this.promotionType = promotionType || null
		this.promotionStatus = promotionStatus || null
		this.reprovedReason = reprovedReason || null
	}

	removeId() {
		this.promotionId = null
	}
}
