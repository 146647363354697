import {
	ROUTE_REPORTS_ORDERS_STATUS_HISTORY,
	ROUTE_REPORTS_ORDERS_STATUS_HISTORY_LIST
} from 'constants/RouterNames'

const reportOrdersByStatusHistory = () => import('components/pages/secured/reports/reportOrdersByStatusHistory/reportOrdersByStatusHistory')
const reportOrdersByStatusHistoryList = () => import('components/pages/secured/reports/reportOrdersByStatusHistory/reportOrdersByStatusHistoryList/reportOrdersByStatusHistoryList')

const reportOrdersByStatusHistoryRoute = {
	path: '/report-orders-by-status-history',
	redirect: '/relatorio-pedidos-por-status-historico',
	component: reportOrdersByStatusHistory,
	name: ROUTE_REPORTS_ORDERS_STATUS_HISTORY.name,
	meta: { breadcrumb: { name: 'Pedidos por histórico de status' }, requiresAuthorities: ROUTE_REPORTS_ORDERS_STATUS_HISTORY.routerAuth },
	children: [
		{ path: '/relatorio-pedidos-por-status-historico', component: reportOrdersByStatusHistoryList, name: ROUTE_REPORTS_ORDERS_STATUS_HISTORY_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_REPORTS_ORDERS_STATUS_HISTORY_LIST.routerAuth } }
	]
}

export default reportOrdersByStatusHistoryRoute
