// Offset para o nosso header fixo
const headerOffset = 180
/*
	Smooth scroll function from Daniel Sawka's answer in
	https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page
*/
const verticalScroll = (id, duration) => {
	const element = document.getElementById(id)

	if (!element) return

	verticalScrollToElement(element, duration)
}

const verticalScrollToElement = (element, duration) => {
	const animationTime = (duration || 1000)
	const startingY = window.pageYOffset
	const elementY = startingY + getElementY(element)
	// Ajuste de height caso o elemento esteja muito proximo ao fundo da pagina
	const targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
	const diff = targetY - startingY - headerOffset
	// Caso a diferença do inicio e do target seja 0 então abortar animação
	if (!diff) return

	let start
	window.requestAnimationFrame(function step(timestamp) {
		if (!start) start = timestamp
		// Elapsed miliseconds since start of scrolling.
		var time = timestamp - start
		// Get percent of completion in range [0, 1].
		var percent = Math.min(time / animationTime, 1)
		// Apply the easing.
		// It can cause bad-looking slow frames in browser performance tool, so be careful.
		percent = easing(percent)

		window.scrollTo(0, startingY + diff * percent)

		// Proceed with animation as long as we wanted it to.
		if (time < animationTime) {
			window.requestAnimationFrame(step)
		}
	})
}

const getElementY = (element) => {
	return element.getBoundingClientRect().top
}

/*
 Easing function: easeInOutCubic
 From: https://gist.github.com/gre/1650294
*/
const easing = (t) => {
	return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
}

export { verticalScroll, verticalScrollToElement }
