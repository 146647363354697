<template>
	<div class="mkt-html-textarea">
		<md-field
			:class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }"
			:md-counter="blDisplayCounter"
		>
			<div>
				<mkt-label class="mkt-field__label" :txLabel="txLabel" :bl-has-slot="true" :bl-required="blRequired">
					<button
						type="button"
						class="mkt-html-textarea__label__html-preview"
						:class="{ 'is-active': previewMode }"
						@click.prevent="previewMode = !previewMode"
						:title="previewMode ? 'Editar' : 'Visualizar em HTML'"
					>
						<i class="icon-chevron-left"></i>
						<i class="icon-chevron-right"></i>
					</button>
					<button
						v-if="blShowBrInsert"
						type="button"
						class="mkt-html-textarea__label__insert-br"
						@click.prevent="replaceLineBreakWithBr"
					>
						Inserir &#60;br&#62;
					</button>
					<button
						v-if="blShowBrInsert"
						type="button"
						class="mkt-html-textarea__label__remove-br"
						@click.prevent="replaceBrWithLineBreak"
					>
						Excluir &#60;br&#62;
					</button>
				</mkt-label>
			</div>
			<md-textarea
				v-if="!previewMode"
				:placeholder="txPlaceholder"
				:maxlength="nrMaxlength"
				:required="blRequired"
				:disabled="blDisabled || blReadOnly"
				v-model="fieldModel"
				:id="txId"
				ref="textArea"
				@focus="focusElement"
				@blur="onBlur"
				:md-autogrow="true">
			</md-textarea>
			<div
				v-else
				class="mkt-html-textarea__preview"
				v-html="sanitizedModel"
				:id="txId">
			</div>
			<span class="md-error">{{txErrorMessage || 'Campo obrigatório'}}</span>
		</md-field>
	</div>
</template>

<script>
import DOMPurify from 'dompurify'
import formFieldMixin from 'components/globals/mktField/formFieldMixin.js'
import { replaceBy } from 'services/stringBuilderService.js'
import { REGEX_BR_OR_LINE_BREAK } from 'constants/Regex.js'

export default {
	name: 'mkt-html-textarea',
	mixins: [formFieldMixin],
	props: {
		txId: { type: String, required: false },
		nrMaxlength: { type: Number, default: 200 },
		nrMinlength: { type: Number, required: false },
		blShowBrInsert: { type: Boolean, default: false },
		blDisplayCounter: { type: Boolean, default: false },
		blReadOnly: { type: Boolean, required: false, default: false }
	},
	data() {
		return {
			previewMode: false,
			hasFocus: false
		}
	},
	methods: {
		refreshStyle() {
			this.$nextTick(() => {
				this.$refs.textArea && this.$refs.textArea.applyStyles()
			})
		},
		focusElement() {
			this.hasFocus = true
			this.$emit('focus')
		},
		onBlur() {
			if (this.hasFocus) {
				this.hasFocus = false
				this.$emit('blur')
				this.validateField()
			}
		},
		replaceBrWithLineBreak() {
			this.fieldModel = replaceBy(this.fieldModel, REGEX_BR_OR_LINE_BREAK, '\n')
		},
		replaceLineBreakWithBr() {
			this.fieldModel = replaceBy(this.fieldModel, REGEX_BR_OR_LINE_BREAK, '<br>')
		}
	},
	computed: {
		fieldModel: {
			get() {
				this.refreshStyle()
				return this.value
			},
			set(value) {
				this.$emit('input', value)
				this.refreshStyle()
			}
		},
		sanitizedModel() {
			return DOMPurify.sanitize(this.value, {
				ALLOWED_TAGS: ['b', 'strong', 'i', 'p', 'br']
			})
		}
	}
}
</script>
<style src="./mktHtmlTextarea.scss" lang="scss">
</style>
