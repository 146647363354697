import { SET_SELLER_DETAILS } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerDetails = new SellerRegistrationBaseModule('sellerDetails', SET_SELLER_DETAILS)

const state = sellerDetails.getState()
const initialState = sellerDetails.getInitialState()
const getters = sellerDetails.getGetters()
const actions = sellerDetails.getSetters()
const mutations = sellerDetails.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
