import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const OrderBulkActionsUpload = new AuthorityBuilder({
	accessGroup: 'ORDERS_BULK_ACTIONS_UPLOAD',
	accessType: ['ACCESS', 'READ', 'WRITE']
})

const OrdersOrders = new AuthorityBuilder({
	accessGroup: 'ORDERS_ORDERS',
	accessType: ['EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'LIST_RESEND_WRITE']
})

const OrdersStatus = new AuthorityBuilder({
	accessGroup: 'ORDER_STATUS',
	accessType: ['WRITE']
})

const Orders = new AuthorityBuilder({
	accessGroup: 'ORDERS',
	accessType: ['ACCESS']
})

export default [
	OrderBulkActionsUpload,
	Orders,
	OrdersOrders,
	OrdersStatus
]
