import moment from 'moment'
import MonthType from 'domains/MonthType'

export const simpleDateFormat = (d) => {
	return moment(d).format('DD/MM/YYYY')
}

export const dateTimeFormat = (d, dateTimeDivider = '') => {
	const date = new Date(d)

	const month = String(date.getMonth() + 1).padLeft(2, '0')
	const day = String(date.getDate()).padLeft(2, '0')
	const year = String(date.getFullYear())

	const hour = String(date.getHours()).padLeft(2, '0')
	const min = String(date.getMinutes()).padLeft(2, '0')

	return `${day}/${month}/${year} ${dateTimeDivider} ${hour}:${min}`
}

export const toIsoDateFormat = (d) => {
	const date = new Date(d)

	const day = String(date.getDate()).padLeft(2, '0')
	const month = String(date.getMonth() + 1).padLeft(2, '0')
	const year = String(date.getFullYear())

	return `${year}-${month}-${day}`
}

export const isoToBrazilFormat = (date) => {
	return date && date.split('-').reverse().join('/')
}

export const sinceThen = (date) => {
	const now = new Date()
	const seconds = (now - date) / 1000

	let interval = Math.floor(seconds / 31536000)
	if (interval > 1) {
		return interval + ' ano(s) atrás'
	}
	interval = Math.floor(seconds / 2592000)
	if (interval > 1) {
		return interval + ' mes(es) atrás'
	}
	interval = Math.floor(seconds / 86400)
	if (interval > 1) {
		return interval + ' dia(s) atrás'
	}
	interval = Math.floor(seconds / 3600)
	if (interval > 1) {
		return interval + ' hora(s) atrás'
	}
	interval = Math.floor(seconds / 60)
	if (interval > 1) {
		return interval + ' minuto(s) atrás'
	}
	return Math.floor(seconds) + ' segundo(s) atrás'
}

export const toDescriptionMonthYear = (date) => {
	return MonthType.enumValues[date.getUTCMonth()].description + '/' + date.getUTCFullYear()
}
