'use strict'

import PromotionDto from './PromotionDto'
import PromotionCategoryDataDto from './PromotionCategoryDataDto'
import PromotionProductItemSellerDto from './PromotionProductItemSellerDto'

export default class PromotionCategoryDto extends PromotionDto {
	constructor({ fullNameLock, lockModeration, reprovedReason, sellerName, promotionId, promotionStatus, description, startPromotion, endPromotion, paymentMethod, promotionType, promotionCategories, promotionProductItemSellers }) {
		super({ fullNameLock, lockModeration, reprovedReason, sellerName, promotionId, description, startPromotion, endPromotion, paymentMethod, promotionType, promotionStatus })

		this.promotionCategories = (promotionCategories || []).map(cat => new PromotionCategoryDataDto(cat))
		this.promotionProductItemSellers = (promotionProductItemSellers || []).map(dto => new PromotionProductItemSellerDto(dto))
	}

	clearData() {
		this.promotionProductItemSellers = []
		this.promotionCategories = []
	}

	removeId() {
		this.promotionId = null

		this.promotionCategories.forEach(promotionCategory => {
			promotionCategory.promotionCategoryId = null
		})

		this.promotionProductItemSellers.forEach(promotionProductItemSeller => {
			promotionProductItemSeller.promotionProductItemSellerId = null
		})
	}
}
