import {
	ROUTE_PROMOTION_REGISTER,
	ROUTE_PROMOTION_REGISTER_PRODUCT,
	ROUTE_PROMOTION_REGISTER_CATEGORY
} from 'constants/RouterNames'

const promotionRegister = () => import('components/pages/secured/promotion/promotionSeller/promotionRegister/promotionRegister.vue')
const promotionRegisterProduct = () => import('components/pages/secured/promotion/promotionBase/promotionBaseProduct/promotionBaseProduct.vue')
const promotionRegisterCategory = () => import('components/pages/secured/promotion/promotionBase/promotionBaseCategory/promotionBaseCategory.vue')

const promotionRegisterRoute = {
	path: '/cadastrar-promocao',
	component: promotionRegister,
	name: ROUTE_PROMOTION_REGISTER.name,
	meta: { breadcrumb: { name: 'Cadastro', active: false } },
	children: [
		{ path: '/cadastrar-promocao-produto', component: promotionRegisterProduct, name: ROUTE_PROMOTION_REGISTER_PRODUCT.name, meta: { breadcrumb: { name: 'Promoção por produto' }, requiresAuthorities: ROUTE_PROMOTION_REGISTER_PRODUCT.routerAuth } },
		{ path: '/cadastrar-promocao-categoria', component: promotionRegisterCategory, name: ROUTE_PROMOTION_REGISTER_CATEGORY.name, meta: { breadcrumb: { name: 'Promoção por categoria' }, requiresAuthorities: ROUTE_PROMOTION_REGISTER_CATEGORY.routerAuth } }
	]
}

export default promotionRegisterRoute
