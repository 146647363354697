import {
	ROUTE_PRODUCT_COLOMBO
	, ROUTE_PRODUCT_COLOMBO_DETAIL_PRODUCT
	, ROUTE_PRODUCT_COLOMBO_DETAIL_CONTENT
	, ROUTE_PRODUCT_COLOMBO_LIST
} from 'constants/RouterNames'

const productColombo = () => import('components/pages/secured/product/productColombo/productColombo')
const productColomboDetail = () => import('components/pages/secured/product/productColombo/productColomboDetail/productColomboDetail')
const productColomboList = () => import('components/pages/secured/product/productColombo/productColomboList/productColomboList')

const productColomboRoute = {
	path: '/produtos-colombo',
	redirect: '/listagem-produtos',
	component: productColombo,
	name: ROUTE_PRODUCT_COLOMBO.name,
	meta: { requiresAuthorities: ROUTE_PRODUCT_COLOMBO.routerAuth },
	children: [
		{ path: '/listagem-produtos', component: productColomboList, name: ROUTE_PRODUCT_COLOMBO_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_PRODUCT_COLOMBO_LIST.routerAuth } },
		{ path: '/edicao-produto/:productId', props: true, component: productColomboDetail, name: ROUTE_PRODUCT_COLOMBO_DETAIL_PRODUCT.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_PRODUCT_COLOMBO_DETAIL_PRODUCT.routerAuth } },
		{ path: '/edicao-conteudo/:contentId', props: true, component: productColomboDetail, name: ROUTE_PRODUCT_COLOMBO_DETAIL_CONTENT.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_PRODUCT_COLOMBO_DETAIL_CONTENT.routerAuth } }
	]
}

export default productColomboRoute
