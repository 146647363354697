<template>
	<div class="mkt-title" id="mkt-title" v-cloak>
		<mkt-icon tx-font-size="16px" tx-iconColor ="#F58220" :tx-icon="txIcon"></mkt-icon>
		<span class="mkt-title__label">{{ txTitle }}</span>
		<span class="mkt-title__subtitle" v-if="txSubtitle"> {{txSubtitle}} </span>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'

	export default {
		name: 'mkt-title',
		props: {
			txTitle: new PropConfig({	type: String }),
			txSubtitle: new PropConfig({	type: String }),
			txIcon: new PropConfig({ type: String })
		}
	}
</script>

<style src="./mktTitle.scss" lang="scss">

</style>
