import { SET_SELLER_SETTINGS } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerSettings = new SellerRegistrationBaseModule('sellerSettings', SET_SELLER_SETTINGS)

const state = sellerSettings.getState()
const initialState = sellerSettings.getInitialState()
const getters = sellerSettings.getGetters()
const actions = sellerSettings.getSetters()
const mutations = sellerSettings.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
