
'use strict'
/**
 * Objeto com configurações de botões.
 *
 */

export default class Button {
	constructor(options) {
		this.if = options.if
		this.icon = options.icon
		this.label = options.label
		this.onClick = options.onClick
		this.disabled = options.disabled
		this.loading = options.loading
		this.loaderColor = options.loaderColor || '#FFF'
		this.model = options.model
		this.authorization = options.authorization
		this.hasLoading = options.hasLoading
		this.type = options.type
		this.title = options.title
		this.iconSize = options.iconSize || '11px'
		this.className = options.className || 'md-primary md-raised'
		this.size = options.size || 'md'
		this.throttle = options.throttle || 800
	}
}
