<template>
	<div class="mkt-simple-file-upload"	 :class="{'mkt-simple-file-upload--single-upload': blSingleUpload}">
		<div class="row col-12" v-if="txLabel || txHighligthedLabel">
			<mkt-label class="mkt-field__label" :txLabel="txLabel" :bl-required="blRequired" :tx-highligthed-label="txHighligthedLabel"></mkt-label>
		</div>
		<div class="mkt-simple-file-upload__wrapper">
			<mkt-draggable-file
				v-if="shouldRenderDraggableFiles"
				v-model="uploadedFiles"
				:tx-image-source="txImageSource"
				:bl-read-only="blReadOnly"
				:tx-id-file-upload="txIdFileUpload"
				:tx-group="txGroup"
				:bl-file-swap="blFileSwap"
				:bl-show-filename="blShowFilename"
				:bl-has-hover="blHasHover"
				:bl-has-preview="blHasPreview"
				:bl-has-drag="blHasDrag"
				:bl-single-upload="blSingleUpload"
				:bl-delete-file="blDeleteFile"
				:acceptedFileTypes="acceptedFileTypes"
				@mkt-draggable-file:remove="removeFile"
			>
				<template v-if="!blReadOnly" slot="extra-action-slot" slot-scope="props">
					<slot
						name="extra-action"
						v-bind="props"
					></slot>
				</template>
			</mkt-draggable-file>

			<mkt-file-loader :files="onProgressFiles" :bl-single-upload="blSingleUpload"></mkt-file-loader>

			<mkt-file-upload-container
				v-if="blHasUpload"
				v-model="uploadedFiles"
				:bl-read-only="blReadOnly"
				:bl-signed-key="blSignedKey"
				:file-type="fileType"
				:lt-processing-files="onProgressFiles"
				:ob-params="obParams"
				:tx-id-file-upload="txIdFileUpload"
				:tx-image-source="txImageSource"
				:tx-image-hash="txImageHash"
				:tx-url-upload="txUrlUpload"
				:tx-subtitle="txSubtitle"
				:bl-single-upload="blSingleUpload"
				:nr-max-files="nrMaxFiles"
				:nr-max-filesize="nrMaxFilesize"
				:accepted-file-types="acceptedFileTypes"
				@mkt-file-upload-container:loading="setProgressFiles"
				@mkt-file-upload-container:load-end="events.onLoadend"
				@mkt-file-upload:promise-file="emitPromiseFile"
			>
				<slot slot="upload-icon-override" name="upload-icon">
				</slot>
			</mkt-file-upload-container>
		</div>
	</div>
</template>

<script>
import MimeType from 'domains/MimeType'
import PropConfig from 'pojos/PropConfig'
import mktFileUploadContainer from 'components/generics/mktFileComponents/mktFileUploadContainer/mktFileUploadContainer.vue'
import mktDraggableFile from 'components/generics/mktFileComponents/mktDraggableFile/mktDraggableFile.vue'
import mktFileLoader from 'components/generics/mktFileComponents/mktFileLoader/mktFileLoader.vue'

export default {
	name: 'mkt-simple-file-upload',
	components: {
		mktDraggableFile,
		mktFileLoader,
		mktFileUploadContainer
	},
	props: {
		value: new PropConfig({ required: false }),
		txIdFileUpload: new PropConfig({ type: String, required: true }),
		txLabel: new PropConfig({ type: String, required: false }),
		txUrlUpload: new PropConfig({ type: String, required: false }),
		txHighligthedLabel: new PropConfig({ type: String, required: false }),
		txGroup: new PropConfig({ required: false, valueDefault: null }),
		txImageSource: new PropConfig({ type: String, required: false, valueDefault: 'imageThumbnail' }),
		txImageHash: new PropConfig({ type: String, required: false, valueDefault: 'tempFileKey' }),
		txSubtitle: new PropConfig({ type: String, required: false, valueDefault: 'Adicionar Foto' }),
		blRequired: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blShowFilename: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blHasUpload: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blHasHover: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blHasPreview: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blSingleUpload: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blFileSwap: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blDeleteFile: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blHasDrag: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
		blSignedKey: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		nrMaxFiles: new PropConfig({ type: Number, required: false, valueDefault: 10 }),
		nrMaxFilesize: new PropConfig({ type: Number, required: false, valueDefault: 10 }),
		obParams: new PropConfig({ required: false }),
		acceptedFileTypes: new PropConfig({ type: MimeType, required: false, valueDefault: () => MimeType.IMAGE }),
		fileType: new PropConfig({ type: String, required: false })
	},
	data() {
		return {
			onProgressFiles: [],
			events: {
				updateModel: (value) => this.$emit('input', value),
				onLoadend: (value) => this.$emit('mkt-simple-file-upload:on-loadend', value)
			}
		}
	},
	methods: {
		setProgressFiles(files) {
			this.onProgressFiles = files
		},
		emitPromiseFile(promise) {
			this.$emit('mkt-file-upload:promise-file', promise)
		},
		updateModel(val) {
			if (this.blSingleUpload) {
				this.events.updateModel(val[0])
			} else {
				this.events.updateModel(val)
			}
		},
		verifyValue(val) {
			if (val) {
				const isArray = val instanceof Array
				return isArray ? val : [val]
			} else {
				return []
			}
		},
		removeFile(file) {
			this.updateModel(this.uploadedFiles)
		}
	},
	computed: {
		shouldRenderDraggableFiles() {
			return !(this.blSingleUpload && this.onProgressFiles.length) && (this.uploadedFiles && this.uploadedFiles.length)
		},
		uploadedFiles: {
			get() {
				return this.verifyValue(this.value)
			},
			set(val) {
				this.updateModel(val)
			}
		}
	}
}
</script>

<style src="./mktSimpleFileUpload.scss" lang="scss">
</style>
