import { SET_NOTIFICATIONS_HEADER, SET_TOTAL_NOTIFICATIONS_HEADER } from 'constants/MutationTypes'
import { getTotalGlobalNotifications } from 'httpServices/globalNotificationHttpService'

// initial state
const state = {
	total: 0,
	notifications: []
}

// getters
const getters = {
	getTotalNotifications: () => state.total,
	getNotifications: () => state.notifications
}

// actions
const actions = {
	setNotifications: ({ commit }, notifications) => {
		commit(SET_NOTIFICATIONS_HEADER, notifications)
	}
}

// mutations
const mutations = {
	[SET_TOTAL_NOTIFICATIONS_HEADER](state) {
		getTotalGlobalNotifications().then(response => {
			state.total = response.data
		})
	},
	[SET_NOTIFICATIONS_HEADER](state, notifications) {
		state.notifications = notifications
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
