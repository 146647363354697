import { Get } from './baseHttpService'

const baseUploadUrl = '/upload'

const getPresignedUrl = () => {
	return Get(`${baseUploadUrl}/presigned-url`)
}

export {
	getPresignedUrl
}
