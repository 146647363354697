<template>
	<div class="mkt-recaptcha" :class="{ 'is-not-rendered': !isRendered }">
		<div :id="txRecaptchaId">
		</div>
		<div class="mkt-recaptcha__error" :class="{ hidden: !hasError }">
			<div class="mkt-recaptcha__error__message">
				<mkt-icon tx-icon="icon-error" :bl-hover="false" tx-font-size="16px"></mkt-icon>	{{ errorMessage }}
			</div>
		</div>
	</div>
</template>

<script>
import { eventListen, EVENT_RECAPTCHA_ERROR	} from 'services/eventBusService'
import PropConfig from 'pojos/PropConfig'
import Recaptcha from 'src/pojos/Recaptcha'

export default {
	props: {
		blRenderOnMount: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		txRecaptchaId: new PropConfig({ type: String, required: false, valueDefault: 'g-recaptcha' }),
		siteKey: new PropConfig({ type: String, required: false })
	},
	data() {
		return {
			widgetId: null,
			hasError: false,
			isRendered: this.blRenderOnMount,
			recaptcha: null
		}
	},
	methods: {
		execute() {
			this.recaptcha.execute()
		},
		reset() {
			this.recaptcha.reset()
		},
		callbackVerifyCaptcha(response) {
			this.hasError = false
			this.$emit('mkt-recaptcha:verify', response)
		},
		callbackExpireCaptcha() {
			this.$emit('mkt-recaptcha:expire')
		},
		render(recaptchaSiteKey) {
			this.recaptcha.render(recaptchaSiteKey)
			this.isRendered = true
		}
	},
	computed: {
		errorMessage() {
			return 'Recaptcha não pode ficar vazio'
		}
	},
	mounted() {
		this.recaptcha = new Recaptcha({
			blRenderOnMount: this.blRenderOnMount,
			elementId: 'g-recaptcha',
			siteKey: this.siteKey,
			opts: {
				callback: this.callbackVerifyCaptcha,
				'expired-callback': this.callbackExpireCaptcha
			}
		})

		eventListen(EVENT_RECAPTCHA_ERROR, () => {
			this.hasError = true
		})
	}
}
</script>
<style src="./mktRecaptcha.scss" lang="scss">

</style>
