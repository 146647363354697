<template>
	<div class="mkt-radio" v-click-outside="events.blur">
		<div v-if="!blReadOnly">
			<mkt-label class="mkt-radio__label" :tx-label="txLabel" v-if="txLabel"></mkt-label>
			<div class="mkt-radio__container">
				<md-radio v-for="item in ltListOptions"
				v-bind:key="item.text" v-model="model"
				:value="cpValue(item)"
				@change="events.focus"
				class="md-primary"
				:disabled="blDisabled"
				> {{cpText(item)}}</md-radio>
			</div>
		</div>
		<md-field v-else class="md-read-only">
			<mkt-label class="mkt-radio__label" :tx-label="txLabel" v-if="txLabel"></mkt-label>
			<md-input :value="optionValue" class="mkt-radio__container__disabled-value" :disabled="blReadOnly"></md-input>
		</md-field>
	</div>
</template>
<script>
	import PropConfig from 'pojos/PropConfig'

	export default {
		props: {
			value: new PropConfig({ required: false }),
			txLabel: new PropConfig({ type: String, required: false }),
			txFieldValue: new PropConfig({ type: String, required: true }),
			txFieldText: new PropConfig({ type: String, required: true }),
			ltListOptions: new PropConfig({ type: [Array, Object] }),
			blDisabled: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false })
		},
		data() {
			return {
				hasFocus: false,
				events: {
					blur: this.onBlur,
					focus: this.onFocus,
					updateModel: (val) => this.$emit('input', val)
				}
			}
		},
		methods: {
			cpValue(currentObject) {
				return currentObject && currentObject[this.txFieldValue]
			},
			cpText(currentObject) {
				return currentObject && currentObject[this.txFieldText]
			},
			onBlur() {
				if (this.hasFocus) {
					this.hasFocus = false
					this.$emit('blur')
				}
			},
			onFocus() {
				this.hasFocus = true
				this.$emit('focus')
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.events.updateModel(val)
				}
			},
			optionValue() {
				return this.ltListOptions instanceof Array
					? this.cpText(this.ltListOptions.find(opt => opt.value === this.value))
					: this.cpText(this.ltListOptions[this.value])
			}
		}
	}
</script>
<style src="./mktRadio.scss" lang="scss">
</style>
