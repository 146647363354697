import {
	ROUTE_ORDER_COLOMBO
	, ROUTE_ORDER_COLOMBO_LIST
	, ROUTE_ORDER_COLOMBO_DETAIL
} from 'constants/RouterNames'

const orderColombo = () => import('components/pages/secured/order/orderColombo/orderColombo')
const orderColomboList = () => import('components/pages/secured/order/orderColombo/orderColomboList/orderColomboList')
const orderColomboDetail = () => import('components/pages/secured/order/orderColombo/orderColomboDetail/orderColomboDetail')

const orderColomboRoute = {
	path: '/pedidos-colombo',
	redirect: '/listagem-pedidos',
	component: orderColombo,
	name: ROUTE_ORDER_COLOMBO.name,
	meta: { breadcrumb: { name: 'Pedidos' }, requiresAuthorities: ROUTE_ORDER_COLOMBO.routerAuth },
	children: [
		{ path: '/listagem-pedidos', component: orderColomboList, name: ROUTE_ORDER_COLOMBO_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_ORDER_COLOMBO_LIST.routerAuth } },
		{ path: '/detalhamento-pedidos/:idOrderColombo', props: true, component: orderColomboDetail, name: ROUTE_ORDER_COLOMBO_DETAIL.name, meta: { breadcrumb: { name: 'Detalhe' }, requiresAuthorities: ROUTE_ORDER_COLOMBO_DETAIL.routerAuth } }
	]
}

export default orderColomboRoute
