<template>
  <div class="mkt-alert">
		<mkt-form @submit="submit">
			<mkt-modal v-if="showAlert" :fnCallback="cancel"  @mkt-modal:close="onClickClose">
				<div slot="content" class="mkt-alert__modal">
					<div class="mkt-alert__modal__title">
						<h1>{{txTitle}}</h1>
					</div>
					<div class="mkt-alert__modal__body" v-if="txBody">
						<p>{{txBody}}</p>
					</div>
					<div class="mkt-alert__modal__body">
						<slot name="alertBody"></slot>
					</div>
					<div class="mkt-alert__modal__slot-content">
						<slot name="mkt-alert-content">
						</slot>
					</div>
					<div class="mkt-alert__modal__footer" v-if="txFooter">
						<span>{{txFooter}}</span>
					</div>
					<div class="mkt-alert__modal__actions" v-if="!blRemoveActions" >
						<mkt-button v-if="blHasButtonCancel" :button="buttonCancel" class="mkt-alert__modal__actions__button"></mkt-button>
						<mkt-button :button="buttonContinue" class="mkt-alert__modal__actions__button"></mkt-button>
					</div>
				</div>
			</mkt-modal>
		</mkt-form>
  </div>
</template>
<script>
import mktModal from 'generics/mktModal/mktModal'
import Button from 'pojos/Button'

export default {
	name: 'mkt-alert',
	components: {
		mktModal
	},
	props: {
		txTitle: { type: String, required: true },
		txBody: { type: String, required: false },
		txFooter: { type: String, required: false },
		txButtonContinue: { type: String, required: false, default: 'Continuar' },
		txButtonCancel: { type: String, required: false, default: 'Cancelar' },
		fnContinueDisabled: { type: Function, required: false },
		value: { required: false },
		blHasButtonCancel: { type: Boolean, required: false, default: false },
		blRemoveActions: { type: Boolean, required: false, default: false },
		blRenderImmediatly: { type: Boolean, required: false, default: false },
		onConfirm: { type: Function, required: false, default: null },
		onCancel: { type: Function, required: false, default: null }
	},
	data() {
		return {
			showAlert: this.blRenderImmediatly,
			resposta: false,
			buttonContinue: new Button({
				label: this.txButtonContinue,
				disabled: this.fnContinueDisabled,
				type: 'submit'
			}),
			buttonCancel: new Button({
				label: this.txButtonCancel,
				onClick: this.cancel,
				className: 'md-white'
			})
		}
	},
	methods: {
		cancel() {
			this.$emit('mkt-alert:cancel', this.value)
			this.onCancel && this.onCancel(this.value)
			this.closeAlert()
		},
		submit() {
			this.$emit('mkt-alert:continue', this.value)
			this.onConfirm && this.onConfirm(this.value)
			this.closeAlert()
		},
		openAlert() {
			this.showAlert = true
		},
		onClickClose() {
			this.$emit('mkt-alert:close', this.value)
			this.onCancel && this.onCancel(this.value)
			this.closeAlert()
		},
		closeAlert() {
			this.showAlert = false
		}
	}
}
</script>
<style lang="scss" src="./mktAlert.scss">
</style>
