import Vue from 'vue'

import { simpleDateFormat, dateTimeFormat } from 'services/dateService.js'

const simpleDateFormatFilter = function(d) {
	return simpleDateFormat(d)
}

const dateTimeFormatFilter = function(d) {
	return dateTimeFormat(d)
}

Vue.filter('simpleDateFormat', simpleDateFormatFilter)
Vue.filter('dateTimeFormat', dateTimeFormatFilter)
