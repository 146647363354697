import { SET_LOADER_STATE_MUTATION } from 'constants/MutationTypes'

// initial state
const state = {
	loaderState: false
}

// getters
const getters = {
	getLoaderState: state => state.loaderState
}

// actions
const actions = {
	setLoaderState: ({ commit }, loaderState) => {
		commit(SET_LOADER_STATE_MUTATION, loaderState)
	}
}

// mutations
const mutations = {
	[SET_LOADER_STATE_MUTATION](state, loaderState) {
		state.loaderState = loaderState
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
