import Vue from 'vue'

import Tooltip from './tooltip'
import './tooltip.scss'

Vue.directive('tooltip', {
	inserted(el, binding) {
		const dontRenderTooltip = binding.value.show !== undefined && binding.value.show === false
		if (dontRenderTooltip) return

		const elPosition = el.style.position
		if (elPosition !== 'relative' && elPosition !== 'absolute') {
			el.style.position = 'relative'
		}

		el.tooltip = new Tooltip(el, binding.value)
	},

	update(el, binding) {
		const dontRenderTooltip = binding.value.show !== undefined && binding.value.show === false
		if (dontRenderTooltip) {
			el.tooltip && el.tooltip.eject()
			el.tooltip = undefined
			return
		}

		if (el.tooltip) {
			const content = binding.value.text || binding.value
			const hasToUpdateContent = el.tooltip.content !== content

			if (hasToUpdateContent) {
				el.tooltip.setContent(content)

				if (el.tooltip.isRendered) {
					el.tooltip.calculateTooltipPosition()
				}
			}
		} else {
			el.tooltip = new Tooltip(el, binding.value)
		}
	},

	unbind(el) {
		el.tooltip && el.tooltip.eject()
		el.tooltip = undefined
	}
})
