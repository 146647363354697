<template>
	<div class="mkt-file-loader">
		<div
			class="mkt-file-loader__container"
			:class="{ 'mkt-file-loader__container--single-container': blSingleUpload }"
			v-for="(loadingFile, $index) in files"
			:key="'loading-key-' + $index"
		>
			<div class="mkt-file-loader__container__upload-progress progress active"
				role="progressbar"
				aria-valuemin="0"
				aria-valuemax="100"
				aria-valuenow="0"
			>
				<div class="progress-bar progress-bar-success progress-bar-striped progress-bar-animated" :style="{ width: getFileProgress(loadingFile) + '%' }"></div>
			</div>
		</div>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import _ from 'lodash-core'

export default {
	name: 'mkt-file-loader',
	props: {
		files: new PropConfig({ type: Array, required: true }),
		blSingleUpload: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		txFileProgress: new PropConfig({ type: String, required: false, valueDefault: 'progress' })
	},
	methods: {
		getFileProgress(file) {
			return _.get(file, this.txFileProgress) || 0
		}
	}
}
</script>
<style src="./mktFileLoader.scss" lang="scss">
</style>

