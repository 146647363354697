<template>
	<div class="mkt-product-preview" @click="eventEmitClick">
		<mkt-icon tx-icon="icon-search2" tx-font-size="26px"></mkt-icon>
	</div>
</template>

<script>
import mktPushButton from 'components/generics/mktPushButton/mktPushButton.vue'
import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-product-preview',
	props: {
		rowData: new PropConfig({
			type: Object,
			required: true
		}),
		rowIndex: new PropConfig({
			type: Number
		})
	},
	methods: {
		eventEmitClick(event) {
			this.$parent.onCellClicked(this.rowData, 'mkt-product-preview', event)
		}
	},
	components: {
		mktPushButton
	}
}
</script>

<style src="./mktProductPreview.scss" lang="scss">
</style>
