import { RouteAuthorities } from 'src/router/routeAuthorities/'

// Secured Routes
export const ROUTE_DASHBOARD = { name: 'dashboard', routerAuth: [RouteAuthorities.PANEL_SELLER_DASHBOARD_ACCESS, RouteAuthorities.PANEL_COLOMBO_DASHBOARD_ACCESS] }
export const ROUTE_PROFILE = { name: 'profile', routerAuth: [RouteAuthorities.MARKETPLACE_PROFILE_ACCESS] }
/*
 --- Product ---
*/
export const ROUTE_PRODUCT = { name: 'product', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_ACCESS, RouteAuthorities.PANEL_COLOMBO_PRODUCTS_ACCESS] }
// Seller
export const ROUTE_PRODUCT_SELLER = { name: 'product-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_PRODUCTS_ACCESS] }
export const ROUTE_PRODUCT_SELLER_LIST = { name: 'product-seller-list', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_PRODUCTS_LIST_READ] }
export const ROUTE_PRODUCT_SELLER_SEARCH = { name: 'product-seller-search', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_NEW_PRODUCT_ACCESS] }
export const ROUTE_SELLER_NEW_VARIATION_REGISTRATION = { name: 'seller-new-variation-registration', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_NEW_PRODUCT_WRITE] }
export const ROUTE_SELLER_NEW_PRODUCT_REGISTRATION = { name: 'seller-new-product-registration', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_NEW_PRODUCT_WRITE] }
export const ROUTE_PRODUCT_SELLER_EDIT = { name: 'product-seller-edit', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_PRODUCTS_EDIT_APPROVAL_SELLER_READ] }
export const ROUTE_CONTENT_SELLER_EDIT = { name: 'content-seller-edit', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_PRODUCTS_EDIT_READ] }
export const ROUTE_BULK_ACTIONS = { name: 'bulk-actions', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_BULK_ACTIONS_UPLOAD_ACCESS] }
export const ROUTE_SELLER_BULK_ACTIONS = { name: 'seller-bulk-actions', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_BULK_ACTIONS_UPLOAD_ACCESS] }

export const ROUTE_BULK_ACTIONS_LOGS = { name: 'bulk-actions-logs', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_BULK_ACTIONS_UPLOAD_LOGS_ACCESS] }
export const ROUTE_BULK_ACTIONS_LOGS_DETAILS = { name: 'bulk-actions-logs-details', routerAuth: [RouteAuthorities.PANEL_SELLER_PRODUCTS_BULK_ACTIONS_UPLOAD_LOGS_ACCESS] }

// Moderation
export const ROUTE_PRODUCT_MODERATION = { name: 'product-moderation', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_MODERATION_ACCESS] }
export const ROUTE_PRODUCT_MODERATION_LIST = { name: 'product-moderation-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_MODERATION_LIST_READ] }
export const ROUTE_PRODUCT_MODERATION_DETAIL = { name: 'product-moderation-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_MODERATION_EDIT_READ] }
// Colombo
export const ROUTE_PRODUCT_COLOMBO = { name: 'product-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_PRODUCTS_ACCESS] }
export const ROUTE_PRODUCT_COLOMBO_LIST = { name: 'product-colombo-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_PRODUCTS_LIST_READ] }
export const ROUTE_PRODUCT_COLOMBO_DETAIL_PRODUCT = { name: 'product-colombo-detail-product', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_PRODUCTS_EDIT_READ] }
export const ROUTE_PRODUCT_COLOMBO_DETAIL_CONTENT = { name: 'product-colombo-detail-content', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_PRODUCTS_EDIT_READ] }
// Match
export const ROUTE_PRODUCT_MATCH = { name: 'product-match', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_MATCH_PRODUCT_APPROVED_ACCESS] }
export const ROUTE_PRODUCT_MATCH_SEARCH = { name: 'product-match-search', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_MATCH_PRODUCT_APPROVED_READ] }
export const ROUTE_PRODUCT_MATCH_MODERATION = { name: 'product-match-moderation', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PRODUCTS_MATCH_PRODUCT_APPROVED_WRITE] }

/*
--- Configuration ---
*/
export const ROUTE_CONFIGURATION = { name: 'configuration', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ACCESS, RouteAuthorities.PANEL_SELLER_CONFIGURATION_ACCESS] }
// Characterisitic
export const ROUTE_CHARACTERISTIC = { name: 'characteristic', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ATTRIBUTES_ACCESS] }
export const ROUTE_CHARACTERISTIC_LIST = { name: 'characteristic-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ATTRIBUTES_LIST_READ] }
export const ROUTE_CHARACTERISTIC_DETAIL = { name: 'characteristic-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ATTRIBUTES_EDIT_READ] }
// Category
export const ROUTE_CATEGORY = { name: 'category', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_CATEGORIES_ACCESS] }
// Seller
export const ROUTE_CONFIGURATION_SETTINGS = { name: 'seller-settings', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_SELLERS_ACCESS] }
export const ROUTE_CONFIGURATION_SELLER = { name: 'seller-configuration', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_ACCESS] }
export const ROUTE_SELLER_REGISTRATION = { name: 'seller-registration', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_ADD_WRITE] }
export const ROUTE_SELLER_DETAIL = {
	name: 'seller-detail',
	routerAuth: [
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_ABOUT_STORE_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_CANCELLATION_REASONS_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_COMMISSION_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_CONFIGURATION_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_DATA_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_FINANCIAL_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_INTEGRATION_READ,
		RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_EDIT_SEO_READ
	]
}

export const ROUTE_SELLER_PERSONAL_DETAIL = {
	name: 'seller-personal-detail',
	routerAuth: [
		RouteAuthorities.PANEL_SELLER_CONFIGURATION_SELLERS_EDIT_ABOUT_STORE_READ,
		RouteAuthorities.PANEL_SELLER_CONFIGURATION_SELLERS_EDIT_COMMISSION_READ,
		RouteAuthorities.PANEL_SELLER_CONFIGURATION_SELLERS_EDIT_DATA_READ,
		RouteAuthorities.PANEL_SELLER_CONFIGURATION_SELLERS_EDIT_FINANCIAL_READ,
		RouteAuthorities.PANEL_SELLER_CONFIGURATION_SELLERS_EDIT_INTEGRATION_READ
	]
}

export const ROUTE_SELLER_LIST = { name: 'seller-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_SELLERS_LIST_READ] }
// General
export const ROUTE_CONFIGURATION_GENERAL = { name: 'configuration-general', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_GENERAL_ACCESS, RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_GENERAL_READ] }
// Payment Diary
export const ROUTE_CONFIGURATION_PAYMENT_DIARY = { name: 'payment-diary', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_DIARY_SETTINGS_ACCESS] }
export const ROUTE_CONFIGURATION_PAYMENT_DIARY_LIST = { name: 'payment-diary-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_DIARY_SETTINGS_LIST_READ] }
export const ROUTE_CONFIGURATION_PAYMENT_DIARY_UPDATE = { name: 'payment-diary-update', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_DIARY_SETTINGS_LIST_EDIT] }
// Delete Data
export const ROUTE_DELETE_DATA = { name: 'delete-data', routerAuth: [ RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_DELETE_CUSTOMER_EDIT_READ ] }
export const ROUTE_DELETE_DATA_ACCESS = { name: 'delete-data-access', routerAuth: [ RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_DELETE_CUSTOMER_ACCESS ] }
/*

--- Freight ---
*/
export const ROUTE_CONFIGURATION_FREIGHT = { name: 'freight', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FREIGHT_ACCESS, RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FREIGHT_ACCESS] }
// Freight - Seller
export const ROUTE_CONFIGURATION_FREIGHT_SELLER = { name: 'freight-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FREIGHT_ACCESS] }
export const ROUTE_CONFIGURATION_FREIGHT_SELLER_LIST = { name: 'freight-list-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FREIGHT_LIST_READ] }
export const ROUTE_CONFIGURATION_FREIGHT_SELLER_REGISTRATION = { name: 'freight-registration', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FREIGHT_EDIT_READ] }
// Freight - Colombo
export const ROUTE_CONFIGURATION_FREIGHT_COLOMBO = { name: 'freight-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FREIGHT_ACCESS] }
export const ROUTE_CONFIGURATION_FREIGHT_COLOMBO_LIST = { name: 'freight-list-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FREIGHT_LIST_READ] }

// User
export const ROUTE_CONFIGURATION_USER_SELLER = { name: 'user-configuration-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_USERS_PERMISSION_ACCESS] }
export const ROUTE_CONFIGURATION_USER_COLOMBO = { name: 'user-configuration-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_USERS_ACCESS] }
// User Colombo
export const ROUTE_USER_REGISTRATION_COLOMBO = { name: 'user-colombo-registration', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_USERS_ADD_WRITE] }
export const ROUTE_USER_COLOMBO_LIST = { name: 'user-colombo-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_USERS_LIST_READ] }
export const ROUTE_USER_COLOMBO_UPDATE = { name: 'user-colombo-update', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_USERS_EDIT_READ] }
// User Seller
export const ROUTE_USER_REGISTRATION_SELLER = { name: 'user-seller-registration', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_USERS_ADD_WRITE] }
export const ROUTE_USER_SELLER_LIST = { name: 'user-seller-list', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_USERS_LIST_READ] }
export const ROUTE_USER_SELLER_UPDATE = { name: 'user-seller-update', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_USERS_EDIT_READ] }

export const ROUTE_CONFIGURATION_ORDER_REASON = { name: 'order-reason', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_CANCELLATION_REASON_ACCESS] }
export const ROUTE_CONFIGURATION_ORDER_REASON_REGISTRATION = { name: 'order-reason-registration', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_CANCELLATION_REASON_EDIT_READ] }
export const ROUTE_CONFIGURATION_ORDER_REASON_UPDATE = { name: 'order-reason-update', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_CANCELLATION_REASON_EDIT_READ] }
export const ROUTE_CONFIGURATION_ORDER_REASON_LIST = { name: 'order-reason-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_CANCELLATION_REASON_LIST_READ] }
// MDR
export const ROUTE_CONFIGURATION_MDR = { name: 'mdr', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_MDR_ACCESS] }
export const ROUTE_CONFIGURATION_MDR_LIST = { name: 'mdr-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_MDR_LIST_READ] }
export const ROUTE_CONFIGURATION_MDR_REGISTER = { name: 'mdr-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_MDR_ADD_WRITE] }
export const ROUTE_CONFIGURATION_MDR_DETAIL = { name: 'mdr-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_MDR_EDIT_READ] }
export const ROUTE_CONFIGURATION_MDR_DUPLICATE = { name: 'mdr-duplicate', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_MDR_ADD_WRITE] }
// FEE
export const ROUTE_CONFIGURATION_FEE = { name: 'fee', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FEE_ACCESS] }
export const ROUTE_CONFIGURATION_FEE_LIST = { name: 'fee-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FEE_LIST_READ] }
export const ROUTE_CONFIGURATION_FEE_REGISTER = { name: 'fee-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FEE_ADD_WRITE] }
export const ROUTE_CONFIGURATION_FEE_DETAIL = { name: 'fee-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FEE_EDIT_READ] }
// Access Profile
export const ROUTE_CONFIGURATION_ACCESS_PROFILE = { name: 'access-profile', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ACCESS_PROFILE_ACCESS] }
export const ROUTE_CONFIGURATION_ACCESS_PROFILE_LIST = { name: 'access-profile-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ACCESS_PROFILE_LIST_READ] }
export const ROUTE_CONFIGURATION_ACCESS_PROFILE_REGISTER = { name: 'access-profile-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ACCESS_PROFILE_ADD_WRITE] }
export const ROUTE_CONFIGURATION_ACCESS_PROFILE_DETAIL = { name: 'access-profile-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ACCESS_PROFILE_EDIT_READ] }
// Reproved Reason
export const ROUTE_CONFIGURATION_REPROVED_REASON = { name: 'reproved-reason', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_REPROVED_REASON_ACCESS] }
export const ROUTE_CONFIGURATION_REPROVED_REASON_LIST = { name: 'reproved-reason-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_REPROVED_REASON_LIST_READ] }
export const ROUTE_CONFIGURATION_REPROVED_REASON_REGISTER = { name: 'reproved-reason-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_REPROVED_REASON_ADD_WRITE] }
export const ROUTE_CONFIGURATION_REPROVED_REASON_DETAIL = { name: 'reproved-reason-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_REPROVED_REASON_EDIT_READ] }

/*
--- Order ---
*/
export const ROUTE_ORDER = { name: 'order', routerAuth: [RouteAuthorities.PANEL_COLOMBO_ORDERS_ACCESS, RouteAuthorities.PANEL_SELLER_ORDERS_ACCESS] }
// colombo
export const ROUTE_ORDER_COLOMBO = { name: 'order-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_ORDERS_ACCESS] }
export const ROUTE_ORDER_COLOMBO_LIST = { name: 'order-colombo-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_ORDERS_ORDERS_LIST_READ] }
export const ROUTE_ORDER_COLOMBO_DETAIL = { name: 'order-colombo-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_ORDERS_ORDERS_EDIT_READ] }
// seller
export const ROUTE_ORDER_SELLER = { name: 'order-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_ORDERS_ACCESS] }
export const ROUTE_ORDER_SELLER_LIST = { name: 'order-seller-list', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_ORDERS_LIST_READ] }
export const ROUTE_ORDER_SELLER_DETAIL = { name: 'order-seller-detail', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_ORDERS_EDIT_READ] }
// bulk actions
export const ROUTE_ORDER_SELLER_BULK_ACTIONS = { name: 'order-seller-bulk-actions', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_BULK_ACTIONS_UPLOAD_ACCESS] }
export const ROUTE_ORDER_SELLER_BULK_ACTIONS_IMPORT = { name: 'order-seller-bulk-actions-import', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_BULK_ACTIONS_UPLOAD_READ] }
export const ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS = { name: 'order-seller-bulk-actions-logs', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_BULK_ACTIONS_LOGS_ACCESS] }
export const ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS_DETAILS = { name: 'order-seller-bulk-actions-logs-details', routerAuth: [RouteAuthorities.PANEL_SELLER_ORDERS_BULK_ACTIONS_LOGS_ACCESS] }

/*
--- SAC ---
*/
export const ROUTE_SAC = { name: 'sac', routerAuth: [RouteAuthorities.PANEL_COLOMBO_SAC_ACCESS, RouteAuthorities.PANEL_SELLER_SAC_ACCESS] }

export const ROUTE_SELLER_SAC = { name: 'sac-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_SAC_CUSTOMER_SERVICE_ACCESS] }
export const ROUTE_SELLER_SAC_LIST = { name: 'sac-seller-list', routerAuth: [RouteAuthorities.PANEL_SELLER_SAC_CUSTOMER_SERVICE_LIST_READ] }
export const ROUTE_SELLER_SAC_DETAIL = { name: 'sac-seller-detail', routerAuth: [RouteAuthorities.PANEL_SELLER_SAC_CUSTOMER_SERVICE_EDIT_READ] }

export const ROUTE_SELLER_SAC_NEWCALL = { name: 'sac-seller-newcall', routerAuth: [RouteAuthorities.PANEL_SELLER_SAC_NEWCALL_ACCESS] }

export const ROUTE_COLOMBO_SAC = { name: 'sac-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_SAC_CUSTOMER_SERVICE_ACCESS] }
export const ROUTE_COLOMBO_SAC_LIST = { name: 'sac-colombo-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_SAC_CUSTOMER_SERVICE_LIST_READ] }
export const ROUTE_COLOMBO_SAC_DETAIL = { name: 'sac-colombo-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_SAC_CUSTOMER_SERVICE_EDIT_READ] }

// Notifications
export const ROUTE_COLOMBO_NOTIFICATION = { name: 'notification-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_SAC_NOTIFICATION_ACCESS] }
export const ROUTE_COLOMBO_NOTIFICATION_LIST = { name: 'notification-list-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_SAC_NOTIFICATION_ACCESS] }

export const ROUTE_SELLER_NOTIFICATION = { name: 'notification-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_SAC_NOTIFICATION_ACCESS] }
export const ROUTE_SELLER_NOTIFICATION_LIST = { name: 'notification-list-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_SAC_NOTIFICATION_ACCESS] }

/*
--- Financial ---
*/
export const ROUTE_FINANCIAL = { name: 'financial', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_ACCESS, RouteAuthorities.PANEL_SELLER_CONFIGURATION_FINANCIAL_ACCESS] }

export const ROUTE_FINANCIAL_STATEMENT_SELLER = { name: 'financial-statement-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FINANCIAL_EXTRACT_ACCESS] }
export const ROUTE_FINANCIAL_STATEMENT_COLOMBO = { name: 'financial-statement-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_EXTRACT_ACCESS] }

export const ROUTE_FINANCIAL_COMMISSION = { name: 'financial-commission', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_COMMISSION_ACCESS] }

export const ROUTE_FINANCIAL_ENTRY = { name: 'financial-entry', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_CAST_ACCESS] }
export const ROUTE_FINANCIAL_ENTRY_REGISTER = { name: 'financial-entry-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_CAST_ADD_WRITE] }
export const ROUTE_FINANCIAL_ENTRY_DETAIL = { name: 'financial-entry-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_CAST_EDIT_READ] }
export const ROUTE_FINANCIAL_ENTRY_LIST = { name: 'financial-entry-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_CAST_LIST_READ] }

export const ROUTE_FINANCIAL_ENTRY_SPLIT = { name: 'financial-manual-entry', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_ENTRY_ACCESS] }
export const ROUTE_FINANCIAL_ENTRY_SPLIT_REGISTER = { name: 'financial-manual-entry-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_ENTRY_ADD_WRITE] }
export const ROUTE_FINANCIAL_ENTRY_SPLIT_DETAIL = { name: 'financial-manual-entry-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_ENTRY_EDIT_READ] }
export const ROUTE_FINANCIAL_ENTRY_SPLIT_LIST = { name: 'financial-manual-entry-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_ENTRY_LIST_READ] }

export const ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE = { name: 'financial-account', routerAuth: [RouteAuthorities.PANEL_COLOMBO_FINANCIAL_ACCOUNTS_RECEIVABLE_REPORT_ACCESS] }
export const ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE_LIST = { name: 'financial-account-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_FINANCIAL_ACCOUNTS_RECEIVABLE_REPORT_LIST_READ] }

export const ROUTE_FINANCIAL_ANTICIPATION = { name: 'financial-anticipation', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_ANTICIPATION_ACCESS] }
export const ROUTE_FINANCIAL_ANTICIPATION_LIST = { name: 'financial-anticipation-list', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_ANTICIPATION_LIST_READ] }
export const ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_SELLER = { name: 'financial-anticipation-scheduled', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_ANTICIPATION_SCHEDULED_ACCESS] }
export const ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_SELLER = { name: 'financial-anticipation-scheduled-list', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_ANTICIPATION_SCHEDULED_LIST_READ] }
export const ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_COLOMBO = { name: 'financial-anticipation-scheduled-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ANTICIPATION_SCHEDULED_ACCESS] }
export const ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_COLOMBO = { name: 'financial-anticipation-scheduled-list-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_ANTICIPATION_SCHEDULED_LIST_READ] }

export const ROUTE_FINANCIAL_REPORT_COLOMBO = { name: 'financial-report-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT_ACCESS] }
export const ROUTE_FINANCIAL_REPORT_LIST_COLOMBO = { name: 'financial-report-list-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT_LIST_READ] }
export const ROUTE_FINANCIAL_REPORT_EXPORT_COLOMBO = { name: 'financial-report-export-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT_LIST_EXPORT_READ] }
export const ROUTE_FINANCIAL_REPORT_SELLER = { name: 'financial-report-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT_ACCESS] }
export const ROUTE_FINANCIAL_REPORT_LIST_SELLER = { name: 'financial-report-list-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT_LIST_READ] }
export const ROUTE_FINANCIAL_REPORT_EXPORT_SELLER = { name: 'financial-report-export-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT_LIST_EXPORT_READ] }

/*
--- Reports ---
*/
export const ROUTE_REPORTS = { name: 'reports', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_ACCESS, RouteAuthorities.PANEL_SELLER_REPORTS_ACCESS] }

export const ROUTE_REPORTS_ORDERS_STATUS_HISTORY = { name: 'reports-orders-status-history', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_ORDER_STATUS_HISTORY_ACCESS] }
export const ROUTE_REPORTS_ORDERS_STATUS_HISTORY_LIST = { name: 'reports-orders-status-history-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_ORDER_STATUS_HISTORY_LIST_READ] }
export const ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY = { name: 'reports-orders-product-history', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_ORDER_PRODUCT_HISTORY_ACCESS] }
export const ROUTE_REPORTS_ORDERS_PRODUCT_HISTORY_LIST = { name: 'reports-orders-product-history-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_ORDER_PRODUCT_HISTORY_LIST_READ] }

export const ROUTE_REPORTS_BILLING_BY_PRODUCT = { name: 'reports-billing-by-product', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_BILLING_BY_PRODUCT_ACCESS] }
export const ROUTE_REPORTS_BILLING_BY_PRODUCT_LIST = { name: 'reports-billing-by-product-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_BILLING_BY_PRODUCT_LIST_READ] }
export const ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT = { name: 'reports-seller-billing-by-product', routerAuth: [RouteAuthorities.PANEL_SELLER_REPORTS_BILLING_BY_PRODUCT_ACCESS] }
export const ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT_LIST = { name: 'reports-seller-billing-by-product-list', routerAuth: [RouteAuthorities.PANEL_SELLER_REPORTS_BILLING_BY_PRODUCT_LIST_READ] }

export const ROUTE_REPORTS_BILLING_BY_CATEGORY = { name: 'reports-billing-by-category', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_BILLING_BY_CATEGORY_ACCESS] }
export const ROUTE_REPORTS_BILLING_BY_CATEGORY_LIST = { name: 'reports-billing-by-category-list', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REPORTS_BILLING_BY_CATEGORY_LIST_READ] }
export const ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY = { name: 'reports-seller-billing-by-category', routerAuth: [RouteAuthorities.PANEL_SELLER_REPORTS_BILLING_BY_CATEGORY_ACCESS] }
export const ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY_LIST = { name: 'reports-seller-billing-by-category-list', routerAuth: [RouteAuthorities.PANEL_SELLER_REPORTS_BILLING_BY_CATEGORY_LIST_READ] }

/*
--- Promotion ---
*/
export const ROUTE_PROMOTION = { name: 'promotion', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_ACCESS, RouteAuthorities.PANEL_COLOMBO_PROMOTION_ACCESS] }

// colombo
export const ROUTE_PROMOTION_COLOMBO = { name: 'promotion-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_MODERATION_ACCESS] }
export const ROUTE_PROMOTION_MODERATION_LISTING_COLOMBO = { name: 'promotion-colombo-moderation-listing', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_MODERATION_LIST_READ] }
export const ROUTE_PROMOTION_MODERATION_COLOMBO = { name: 'promotion-moderation', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_MODERATION_EDIT_READ] }
export const ROUTE_PROMOTION_PRODUCT_MODERATION_COLOMBO = { name: 'promotion-product-moderation', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_MODERATION_EDIT_READ] }
export const ROUTE_PROMOTION_CATEGORY_MODERATION_COLOMBO = { name: 'promotion-category-moderation', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_MODERATION_EDIT_READ] }
export const ROUTE_PROMOTION_LISTING_COLOMBO = { name: 'promotion-colombo-listing', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_LIST_READ] }
export const ROUTE_PROMOTION_COLOMBO_DETAIL = { name: 'promotion-detail-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_EDIT_READ] }
export const ROUTE_PROMOTION_COLOMBO_DETAIL_PRODUCT = { name: 'promotion-detail-product-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_EDIT_READ] }
export const ROUTE_PROMOTION_COLOMBO_DETAIL_CATEGORY = { name: 'promotion-detail-category-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_PROMOTION_EDIT_READ] }
export const ROUTE_REBATE_COLOMBO = { name: 'rebate-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_ACCESS] }
export const ROUTE_REBATE_REGISTER_COLOMBO = { name: 'rebate-colombo-register', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_ADD_WRITE] }
export const ROUTE_REBATE_REGISTER_COLOMBO_PRODUCT = { name: 'rebate-colombo-register-product', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_ADD_WRITE] }
export const ROUTE_REBATE_REGISTER_COLOMBO_CATEGORY = { name: 'rebate-colombo-register-category', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_ADD_WRITE] }
export const ROUTE_REBATE_LISTING_COLOMBO = { name: 'rebate-colombo-listing', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_LIST_READ] }
export const ROUTE_REBATE_LISTING_COLOMBO_DETAIL = { name: 'rebate-colombo-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_LIST_READ] }
export const ROUTE_REBATE_COLOMBO_DETAIL_PRODUCT = { name: 'rebate-detail-product-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_LIST_READ] }
export const ROUTE_REBATE_COLOMBO_DETAIL_CATEGORY = { name: 'rebate-detail-category-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_LIST_READ] }
export const ROUTE_REBATE_COLOMBO_EDIT_PRODUCT = { name: 'rebate-edit-product-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_EDIT_READ] }
export const ROUTE_REBATE_COLOMBO_EDIT_CATEGORY = { name: 'rebate-edit-category-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_EDIT_READ] }
export const ROUTE_REBATE_BULK_ACTION_COLOMBO = { name: 'rebate-bulk-action-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_BULK_ACTIONS_UPLOAD_ACCESS] }
export const ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO = { name: 'rebate-bulk-action-logs-colombo', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_BULK_ACTIONS_UPLOAD_LOGS_ACCESS] }
export const ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO_DETAIL = { name: 'rebate-bulk-action-logs-colombo-detail', routerAuth: [RouteAuthorities.PANEL_COLOMBO_REBATE_BULK_ACTIONS_UPLOAD_LOGS_ACCESS] }

// seller
export const ROUTE_PROMOTION_SELLER = { name: 'promotion-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_ACCESS] }
export const ROUTE_PROMOTION_REGISTER = { name: 'promotion-register', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_ADD_WRITE] }
export const ROUTE_PROMOTION_REGISTER_PRODUCT = { name: 'promotion-register-product', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_ADD_WRITE] }
export const ROUTE_PROMOTION_REGISTER_CATEGORY = { name: 'promotion-register-category', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_ADD_WRITE] }
export const ROUTE_PROMOTION_LISTING_SELLER = { name: 'promotion-seller-listing', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_LIST_READ] }
export const ROUTE_PROMOTION_DETAIL = { name: 'promotion-detail', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_EDIT_READ] }
export const ROUTE_PROMOTION_DETAIL_PRODUCT = { name: 'promotion-detail-product', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_EDIT_READ] }
export const ROUTE_PROMOTION_DETAIL_CATEGORY = { name: 'promotion-detail-category', routerAuth: [RouteAuthorities.PANEL_SELLER_PROMOTION_PROMOTION_EDIT_READ] }
export const ROUTE_REBATE_LIST_MODERATION_SELLER = { name: 'rebate-list-moderation-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_MODERATION_WRITE] }
export const ROUTE_REBATE_MODERATION_SELLER = { name: 'rebate-moderation-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_MODERATION_WRITE] }
export const ROUTE_REBATE_ACCESS_SELLER = { name: 'rebate-seller', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_ACCESS] }
export const ROUTE_REBATE_ACCESS_LISTING_SELLER = { name: 'rebate-seller-listing', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_LIST_READ] }
export const ROUTE_REBATE_ACCESS_DETAIL_SELLER = { name: 'rebate-seller-detail', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_LIST_READ] }
export const ROUTE_REBATE_DETAIL_PRODUCT = { name: 'rebate-detail-product', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_LIST_READ] }
export const ROUTE_REBATE_DETAIL_CATEGORY = { name: 'rebate-detail-category', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_LIST_READ] }
export const ROUTE_REBATE_MODERATION_PRODUCT = { name: 'rebate-moderation-product', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_MODERATION_WRITE] }
export const ROUTE_REBATE_MODERATION_CATEGORY = { name: 'rebate-moderation-category', routerAuth: [RouteAuthorities.PANEL_SELLER_REBATE_MODERATION_WRITE] }

// Unsecured Routes
export const ROUTE_LOGIN = { name: 'login' }
export const ROUTE_FORGOT_PASSWORD = { name: 'forgot-password' }
export const ROUTE_EMAIL_SENT = { name: 'email-sent' }
export const ROUTE_CHANGE_PASSWORD = { name: 'change-password' }
export const ROUTE_ACCOUNT_PASSWORD_RECOVERY = { name: 'account-password-recovery' }

// Page Error Routes
export const ROUTE_SERVER_ERROR = { name: '404' }
