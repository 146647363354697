import {
	ROUTE_PRODUCT
} from 'constants/RouterNames'

// product
import productColomboRoute from './productColombo'
import productModerationRoute from './productModeration'
import productSellerRoute from './productSeller'
import productBulkActions from './productBulkActions'
import productMatch from './productMatch'
const product = () => import('components/pages/secured/product/product')

const productRoute = {
	path: '/product',
	component: product,
	name: ROUTE_PRODUCT.name,
	meta: { breadcrumb: { name: 'Produtos', active: false } },
	children: [
		productColomboRoute,
		productModerationRoute,
		productSellerRoute,
		productBulkActions,
		productMatch
	]
}

export default productRoute
