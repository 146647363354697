import { SET_ACCOUNT_PASSWORD_RECOVERY } from 'constants/MutationTypes'

// initial state
const state = {
	accountPasswordRecovery: ''
}

// getters
const getters = {
	getAccountPasswordRecovery: state => state.accountPasswordRecovery
}

// actions
const actions = {
	setAccountPasswordRecovery: ({ commit }, accountPasswordRecovery) => {
		commit(SET_ACCOUNT_PASSWORD_RECOVERY, accountPasswordRecovery)
	}
}

// mutations
const mutations = {
	[SET_ACCOUNT_PASSWORD_RECOVERY](state, accountPasswordRecovery) {
		state.accountPasswordRecovery = accountPasswordRecovery
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
