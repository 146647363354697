import {
	ROUTE_FINANCIAL_ENTRY_SPLIT,
	ROUTE_FINANCIAL_ENTRY_SPLIT_LIST,
	ROUTE_FINANCIAL_ENTRY_SPLIT_REGISTER,
	ROUTE_FINANCIAL_ENTRY_SPLIT_DETAIL
} from 'constants/RouterNames'

const financialEntrySplit = () => import('components/pages/secured/financial/financialEntrySplit/financialEntrySplit')
const financialEntrySplitList = () => import('components/pages/secured/financial/financialEntrySplit/financialEntrySplitList/financialEntrySplitList')
const financialEntrySplitDetail = () => import('components/pages/secured/financial/financialEntrySplit/financialEntrySplitDetail/financialEntrySplitDetail')

const financialEntrySplitRoute = {
	path: '/lancamentos-financeiros-split',
	redirect: '/listagem-lancamentos-financeiros-split',
	component: financialEntrySplit,
	name: ROUTE_FINANCIAL_ENTRY_SPLIT.name,
	meta: { breadcrumb: { name: 'Lançamentos manuais' }, requiresAuthorities: ROUTE_FINANCIAL_ENTRY_SPLIT.routerAuth },
	children: [
		{ path: '/listagem-lancamentos-financeiros-split', component: financialEntrySplitList, name: ROUTE_FINANCIAL_ENTRY_SPLIT_LIST.name, meta: { requiresAuthorities: ROUTE_FINANCIAL_ENTRY_SPLIT_LIST.routerAuth } },
		{ path: '/cadastro-lancamentos-financeiros-split', component: financialEntrySplitDetail, name: ROUTE_FINANCIAL_ENTRY_SPLIT_REGISTER.name, meta: { requiresAuthorities: ROUTE_FINANCIAL_ENTRY_SPLIT_REGISTER.routerAuth } },
		{ path: '/edicao-lancamentos-financeiros-split/:financialEntryId', props: true, component: financialEntrySplitDetail, name: ROUTE_FINANCIAL_ENTRY_SPLIT_DETAIL.name, meta: { requiresAuthorities: ROUTE_FINANCIAL_ENTRY_SPLIT_DETAIL.routerAuth } }
	]
}

export default financialEntrySplitRoute
