import {
	ROUTE_REBATE_BULK_ACTION_COLOMBO,
	ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO,
	ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO_DETAIL
} from 'constants/RouterNames'

const viewData = () => import('src/components/pages/secured/promotion/rebateBulkAction/rebateBulkActionBase.vue')
const bulkAction = () => import('src/components/pages/secured/promotion/rebateBulkAction/rebateBulkAction.vue')
const logs = () =>
	import('src/components/pages/secured/promotion/rebateBulkAction/rebateBulkActionLogs/rebateBulkActionLogs.vue')
const logsDetail = () =>
	import('src/components/pages/secured/promotion/rebateBulkAction/rebateBulkActionLogs/rebateBulkActionLogsDetail/rebateBulkActionLogsDetail.vue')

const rebateBulkActionRoute = {
	path: '/rebate/acao-em-massa/base',
	redirect: '/rebate/acao-em-massa',
	component: viewData,
	name: ROUTE_REBATE_BULK_ACTION_COLOMBO.name,
	meta: {
		breadcrumb: { name: 'Rebate ações em massa' },
		requiresAuthorities: ROUTE_REBATE_BULK_ACTION_COLOMBO.routerAuth
	},
	children: [
		{
			path: '/rebate/acao-em-massa',
			component: bulkAction,
			name: ROUTE_REBATE_BULK_ACTION_COLOMBO.name,
			meta: {
				requiresAuthorities: ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO.routerAuth
			}

		},
		{
			path: '/rebate/acao-em-massa/logs',
			component: logs,
			name: ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO.name,
			meta: {
				requiresAuthorities: ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO.routerAuth,
				breadcrumb: { name: 'Logs' }
			}

		},
		{
			path: '/rebate/acao-em-massa/log/detalhe/:id',
			props: true,
			component: logsDetail,
			name: ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO_DETAIL.name,
			meta: {
				breadcrumb: { name: 'Logs' },
				requiresAuthorities: ROUTE_REBATE_BULK_ACTION_LOGS_COLOMBO_DETAIL.routerAuth
			}
		}
	]
}

export default rebateBulkActionRoute
