<template>
	<div class="mkt-product-moderation-status" @click="eventEmitClick">
		<mkt-icon tx-font-size="20px" tx-icon="icon-format_list_bulleted" tx-icon-color="#54C484"></mkt-icon>
		<mkt-icon tx-font-size="20px" tx-icon="icon-info_outline" tx-icon-color="#54C484"></mkt-icon>
		<mkt-icon tx-font-size="20px" tx-icon="icon-description" tx-icon-color="#54C484"></mkt-icon>
		<mkt-icon tx-font-size="20px" tx-icon="icon-straighten" tx-icon-color="#54C484"></mkt-icon>
		<mkt-icon tx-font-size="20px" tx-icon="icon-photo" tx-icon-color="#54C484"></mkt-icon>
		<mkt-icon tx-font-size="20px" tx-icon="icon-control_point_duplicate" tx-icon-color="#54C484"></mkt-icon>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-product-moderation-status',
	props: {
		rowData: new PropConfig({
			type: Object,
			required: true
		}),
		rowIndex: new PropConfig({
			type: Number
		})
	},
	methods: {
		eventEmitClick(event) {
			this.$parent.onCellClicked(this.rowData, 'mkt-product-moderation-status', event)
		}
	}
}
</script>

<style src="./mktProductModerationStatus.scss" lang="scss">
</style>
