import Vue from 'vue'

const eventBus = new Vue()

const eventEmit = (eventName, parameters) => {
	eventBus.$emit(eventName, parameters)
}

const stopListen = (eventName, parameters) => {
	eventBus.$off(eventName, parameters)
}

const eventListen = (eventName, action) => {
	eventBus.$on(eventName, action)
}

export { eventEmit }
export { eventListen }
export { stopListen }

export const EVENT_DRAWER_TOGGLE = 'EVENT_DRAWER_TOGGLE'
export const EVENT_MODAL_CLOSE = 'EVENT_MODAL_CLOSE'
export const EVENT_RECAPTCHA_ERROR = 'EVENT_RECAPTCHA_ERROR'
export const EVENT_MODAL_OPEN = 'EVENT_MODAL_OPEN'
export const EVENT_ALERT_OPEN = 'EVENT_ALERT_OPEN'
export const EVENT_ALERT_CLOSE = 'EVENT_ALERT_CLOSE'
export const EVENT_SEND_NOTIFICATION = 'EVENT_SEND_NOTIFICATION'
export const EVENT_CLOSE_SESSION = 'EVENT_CLOSE_SESSION'
export const EVENT_WARNING_SESSION = 'EVENT_WARNING_SESSION'
export const EVENT_UPDATE_SESSION = 'EVENT_CLOSE_SESSION'
export const EVENT_LIGHT_BOX_CLOSE = 'EVENT_LIGHT_BOX_CLOSE'
export const EVENT_EXPORT_FINISH = 'EVENT_EXPORT_FINISH'
export const EVENT_EXPORT_ERROR = 'EVENT_EXPORT_ERROR'
export const EVENT_CLEAR_PRODUCTS_MATCH = 'EVENT_CLEAR_PRODUCTS_MATCH'
