import {
	ROUTE_CONFIGURATION_REPROVED_REASON
	, ROUTE_CONFIGURATION_REPROVED_REASON_LIST
	, ROUTE_CONFIGURATION_REPROVED_REASON_REGISTER
	, ROUTE_CONFIGURATION_REPROVED_REASON_DETAIL
} from 'constants/RouterNames'

const reprovedReason = () => import('src/components/pages/secured/configuration/reprovedReason/reprovedReason')
const reprovedReasonList = () => import('src/components/pages/secured/configuration/reprovedReason/reprovedReasonList/reprovedReasonList')
const reprovedReasonRegister = () => import('src/components/pages/secured/configuration/reprovedReason/reprovedReasonRegister/reprovedReasonRegister')
const reprovedReasonDetail = () => import('src/components/pages/secured/configuration/reprovedReason/reprovedReasonDetail/reprovedReasonDetail')

const reprovedReasonRoute = {
	path: '/motivo-reprovacao',
	redirect: '/listagem-motivo-reprovacao',
	component: reprovedReason,
	name: ROUTE_CONFIGURATION_REPROVED_REASON.name,
	meta: { breadcrumb: { name: 'Motivo Reprovação' }, requiresAuthorities: ROUTE_CONFIGURATION_REPROVED_REASON.routerAuth },
	children: [
		{ path: '/listagem-motivo-reprovacao', component: reprovedReasonList, name: ROUTE_CONFIGURATION_REPROVED_REASON_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_REPROVED_REASON_LIST.routerAuth } },
		{ path: '/cadastro-motivo-reprovacao', component: reprovedReasonRegister, name: ROUTE_CONFIGURATION_REPROVED_REASON_REGISTER.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_CONFIGURATION_REPROVED_REASON_REGISTER.routerAuth } },
		{ path: '/edicao-motivo-reprovacao/:reprovedReasonId', props: true, component: reprovedReasonDetail, name: ROUTE_CONFIGURATION_REPROVED_REASON_DETAIL.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_CONFIGURATION_REPROVED_REASON_DETAIL.routerAuth } }
	]
}

export default reprovedReasonRoute
