import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Products = new AuthorityBuilder({
	accessGroup: 'PRODUCTS',
	accessType: ['ACCESS']
})

const ProductsModeration = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_MODERATION',
	accessType: ['EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'LIST_APPROVE_WRITE', 'LIST_PREVIEW_READ', 'LIST_PREVIEW_WRITE', 'LIST_REPROVE_WRITE', 'ACCESS']
})

const ProductsNewProduct = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_NEW_PRODUCT',
	accessType: ['ACCESS', 'WRITE']
})

const ProductsApprovedMatch = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_MATCH_PRODUCT_APPROVED',
	accessType: ['ACCESS', 'READ', 'WRITE']
})

const ProductsProducts = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_PRODUCTS',
	accessType: ['EDIT_READ', 'EDIT_WRITE', 'EDIT_CHANGE_STATUS_READ', 'EDIT_CHANGE_STATUS_WRITE', 'EDIT_UNDO_MATCH_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'LIST_CHANGE_SITUATION_WRITE', 'ACCESS', 'EDIT_UNDO_MATCH_WRITE']
})

export default [
	Products,
	ProductsModeration,
	ProductsNewProduct,
	ProductsProducts,
	ProductsApprovedMatch
]
