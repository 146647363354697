import {
	ROUTE_REPORTS
} from 'constants/RouterNames'

import reportOrdersByStatusHistory from './reportOrdersByStatusHistory'
import reportOrdersByProductHistory from './reportOrdersByProductHistory'
import reportBillingByProduct from './reportBillingByProduct'
import reportSellerBillingByProduct from './reportSellerBillingByProduct'

import reportBillingByCategory from './reportBillingByCategory'
import reportSellerBillingByCategory from './reportSellerBillingByCategory'
const report = () => import('components/pages/secured/reports/reports')

const reportRoute = {
	path: '/reports',
	component: report,
	name: ROUTE_REPORTS.name,
	meta: { breadcrumb: { name: 'Relatórios', active: false }, requiresAuthorities: ROUTE_REPORTS.routerAuth },
	children: [
		reportOrdersByStatusHistory,
		reportOrdersByProductHistory,
		reportBillingByProduct,
		reportSellerBillingByProduct,
		reportBillingByCategory,
		reportSellerBillingByCategory
	]
}

export default reportRoute
