import {
	ROUTE_CONFIGURATION_SETTINGS
	, ROUTE_SELLER_PERSONAL_DETAIL
} from 'constants/RouterNames'

const configurationSeller = () => import('components/pages/secured/configuration/configurationSeller/configurationSeller.vue')
const sellerEdit = () => import('components/pages/secured/configuration/configurationSeller/seller/edit/sellerEdit.vue')

const sellerConfiguration = {
	path: '/configuracao',
	redirect: '/meus-dados',
	meta: { requiresAuthorities: ROUTE_CONFIGURATION_SETTINGS.routerAuth },
	component: configurationSeller,
	name: ROUTE_CONFIGURATION_SETTINGS.name,
	children: [
		{ path: '/meus-dados', component: sellerEdit, name: ROUTE_SELLER_PERSONAL_DETAIL.name, meta: { breadcrumb: { name: 'Meus Dados' }, requiresAuthorities: ROUTE_SELLER_PERSONAL_DETAIL.routerAuth } }
	]
}

export default sellerConfiguration
