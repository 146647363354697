<template>
	<div class="mkt-characteristic-detail-needed">
		<mkt-radio
			:lt-list-options="ltCharacteristicsBooleanOptions"
			tx-field-value="value"
			tx-field-text="description"
			tx-model="rowData.needed"
			v-model="rowData.needed"
		></mkt-radio>
	</div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import RadioTrueFalseOptions from 'src/constants/RadioTrueFalseBooleanOptions'

export default {
	name: 'mkt-characteristic-detail-needed',
	props: {
		rowData: new PropConfig({
			type: Object,
			required: true
		})
	},
	data() {
		return {
			ltCharacteristicsBooleanOptions: RadioTrueFalseOptions
		}
	}
}
</script>

<style src="./mktCharacteristicDetailNeeded.scss" lang="scss">
</style>
