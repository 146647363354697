<template>
	<div class="mkt-table__footer row">
		<div class="col-md-4 col-5">
			<vuetable-pagination-info
				info-template="Mostrando {from} a {to} de {total} resultados"
				no-data-template=""
				ref="paginationInfo"
			></vuetable-pagination-info>
		</div>
		<div class="col-md-4 col-7">
			<vuetable-pagination
				ref="pagination"
				@vuetable-pagination:change-page="onChangePage"
				:css="css"
			>
			</vuetable-pagination>
		</div>
		<div class="mkt-table__footer__right col-md-4 col-12">
			<slot name="mkt-table-footer-slot">
				<mkt-button v-if="hasExport" class="export-button" :button="buttonExport"></mkt-button>
			</slot>
		</div>
	</div>
</template>

<script>
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Button from 'pojos/Button'

export default {
	name: 'mkt-table-footer',
	props: {
		paginationData: { required: false },
		exportAuthority: { type: [String, Array], required: false },
		hasExport: { type: Boolean, required: false, default: false },
		exportDisabled: { type: Boolean, required: false, default: false },
		isProcessing: { type: Boolean, required: false, default: false }
	},
	components: {
		VuetablePagination,
		VuetablePaginationInfo
	},
	methods: {
		onChangePage(page) {
			this.$emit('changePage', page)
		},
		exportData() {
			this.$emit('mkt-table:export')
		}
	},
	data() {
		return {
			css: {
				wrapperClass: 'ui right floated pagination menu',
				activeClass: 'active large',
				disabledClass: 'disabled',
				pageClass: 'item',
				linkClass: 'icon item',
				paginationClass: 'ui bottom attached segment grid',
				paginationInfoClass: 'left floated left aligned six wide column',
				dropdownClass: 'ui search dropdown',
				icons: {
					prev: 'icon-chevron-left',
					next: 'icon-chevron-right'
				}
			},
			buttonExport: new Button({
				label: 'Exportar',
				icon: 'icon-common-file',
				className: 'md-dense mkt-export-button',
				onClick: this.exportData,
				loading: () => this.isProcessing,
				disabled: () => this.exportDisabled,
				hasLoading: true,
				iconSize: '13px',
				loaderColor: '#003C86',
				authorization: this.exportAuthority
			})
		}
	},
	watch: {
		paginationData(paginationData) {
			this.$refs.pagination.setPaginationData(paginationData)
			this.$refs.paginationInfo.setPaginationData(paginationData)
		}
	}
}
</script>

<style src="./mktTableFooter.scss" lang="scss">
</style>
