import { getPresignedUrl, postValidateFile, postFile } from 'httpServices/fileHttpService.js'
import { Put } from 'httpServices/crossOriginHttpService.js'
import PropConfig from 'pojos/PropConfig'

import { sendErrorNotification } from 'src/services/notificationService'
import { validateFileBlobType } from 'services/fileService'

const fileUploadMixin = {
	props: {
		value: new PropConfig({ required: false }),
		obParams: new PropConfig({ required: false }),
		ltProcessingFiles: new PropConfig({ required: false, valueDefault: () => [] }),
		txIdFileUpload: new PropConfig({ type: String, required: true }),
		txImageSource: new PropConfig({ type: String, required: true }),
		txImageHash: new PropConfig({ type: String, required: true }),
		txUrlUpload: new PropConfig({ type: String, required: false }),
		txSubtitle: new PropConfig({ required: false }),
		blSingleUpload: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blSignedKey: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		nrMaxFiles: new PropConfig({ type: Number, required: false, valueDefault: 10 }),
		nrMaxFilesize: new PropConfig({ type: Number, required: false, valueDefault: 10 }),
		acceptedFileTypes: new PropConfig({ type: Object, required: false, valueDefault: () => MimeType.IMAGE }),
		txDefaultType: new PropConfig({ type: String, required: false }),
		fileType: new PropConfig({ type: String, required: false })
	},
	mounted() {
		this.onLoadend = this.onLoadend.bind(this)
	},
	data() {
		return {
			onProgressFiles: [],
			currentFile: null,
			events: {
				updateModel: (value) => this.$emit('input', value),
				onLoading: (value) => this.$emit('mkt-file-upload-container:loading', value),
				onLoadend: (value) => this.$emit('mkt-file-upload-container:load-end', value)
			}
		}
	},
	methods: {
		onChangeValue(event) {
			if (!event.target.files.length && this.blSingleUpload) {
				return
			}

			this.loadFiles(Object.values(event.target.files))
		},
		loadFiles(files) {
			this.onProgressFiles = this.verifyFiles(files)

			this.onProgressFiles.forEach((file, index) => {
				const fileReader = new FileReader()
				file.progress = 0
				fileReader.fileName = file.name
				fileReader.type = file.type

				fileReader.onprogress = (data) => this.onProgress(data, file)
				fileReader.onload = () => this.onLoadend(fileReader, file)
				fileReader.onerror = () => this.onError(file)

				fileReader.readAsDataURL(file)
			})
		},
		updateModel(value) {
			this.events.updateModel(value)
		},
		verifyFiles(uploadedFiles) {
			if (this.blSingleUpload && this.uploadedFiles.length) {
				this.currentFile = this.uploadedFiles[0]
				this.uploadedFiles.pop()
			}

			const validFiles = uploadedFiles.filter(this.validateFile)
			const totalFiles = (this.uploadedFiles.length + validFiles.length)
			const maxFilesReached = totalFiles > this.nrMaxFiles

			if (maxFilesReached) {
				const elementsToRemove = (totalFiles - this.nrMaxFiles)

				validFiles.splice(validFiles.length - elementsToRemove, elementsToRemove)
				sendErrorNotification(`Só é possível enviar ${this.nrMaxFiles > 1 ? 'um total de' : ''} ${this.nrMaxFiles} ${this.nrMaxFiles > 1 ? 'arquivos' : 'arquivo'}!`)
			}

			return validFiles
		},
		validateFile(file) {
			const isFileSizeValid = ((file.size / 1000) / 1000) < this.nrMaxFilesize
			const isBlobTypeValid = validateFileBlobType(file, this.acceptedFileTypes)

			if (!isFileSizeValid) {
				sendErrorNotification(`Arquivo muito grande! Tamanho máximo suportado é ${this.nrMaxFilesize}MB`)
			}
			if (!isBlobTypeValid) {
				sendErrorNotification('Extensão do arquivo não suportada')
			}

			if ((!isFileSizeValid || !isBlobTypeValid) && this.blSingleUpload && this.currentFile) {
				this.returnCurrentFile()
			}

			return isFileSizeValid && isBlobTypeValid
		},
		returnCurrentFile() {
			this.uploadedFiles.push(this.currentFile)
			this.currentFile = null
		},
		onLoadend({ fileName, result, type }, file) {
			let fileLoadingPromise
			if (this.blSignedKey) {
				fileLoadingPromise = this.generateTempFile(file)
					.then(({ fileDownloadUrl, fileKey }) => {
						this.removeOnProgressFile(file)
						const newFile = { [this.txImageSource]: fileDownloadUrl, [this.txImageHash]: fileKey, fileName, type, result, order: this.uploadedFiles.length + 1 }
						this.pushNewFile(newFile)
					}).catch((err) => {
						if (err) {
							this.onError(file, true)
						}
					})
			} else {
				fileLoadingPromise = postFile(this.txUrlUpload, file, this.obParams)
					.then(response => {
						this.removeOnProgressFile(file)
						const newFile = { ...response.data, [this.txImageSource]: response.data.path, fileName, type, result, order: this.uploadedFiles.length + 1 }
						this.pushNewFile(newFile)
					}).catch((err) => {
						if (err) {
							this.onError(file, true)
						}
					})
			}

			this.$emit('mkt-file-upload:promise-file', fileLoadingPromise)
		},
		pushNewFile(newFile) {
			this.uploadedFiles.push(newFile)
			this.updateModel(this.uploadedFiles)
			this.events.onLoadend(newFile)
		},
		onError(file, blDontSendGenericError) {
			this.removeOnProgressFile(file)
			!blDontSendGenericError && sendErrorNotification('Ocorreu um erro! Tente novamente mais tarde.')

			if (this.blSingleUpload && this.currentFile) {
				this.returnCurrentFile()
			}
		},
		onProgress(data, file) {
			if (!data.lengthComputable) return

			const fileIndex = this.onProgressFiles.indexOf(file)
			const uploadingFile = this.onProgressFiles[fileIndex]

			uploadingFile.progress = parseInt(((data.loaded / data.total) * 100), 10)
			this.onProgressFiles.splice(fileIndex, 1, uploadingFile)
		},
		removeOnProgressFile(file) {
			this.onProgressFiles.splice(this.onProgressFiles.indexOf(file), 1)
		},
		generateTempFile(file) {
			return new Promise((resolve, reject) => {
				getPresignedUrl()
					.then(({ data }) => {
						return Put(data.fileUploadUrl, file,
							{
								headers: {
									'Content-Type': file.type || this.txDefaultType,
									'Access-Control-Allow-Origin': '*',
									'Access-Control-Allow-Methods': 'PUT'
								}
							})
							.then(() => {
								return postValidateFile(data.fileDownloadUrl, this.fileType)
									.then(() => resolve(data))
									.catch(err => reject(err))
							})
							.catch(err => reject(err))
					})
					.catch(err => reject(err))
			})
		}
	},
	computed: {
		uploadedFiles: {
			get() {
				return this.value || []
			},
			set(files) {
				this.updateModel(files)
			}
		}
	},
	watch: {
		onProgressFiles(files) {
			this.events.onLoading(files)
		}
	}
}

export default fileUploadMixin
