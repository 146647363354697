import {
	ROUTE_REBATE_MODERATION_SELLER,
	ROUTE_REBATE_MODERATION_PRODUCT,
	ROUTE_REBATE_MODERATION_CATEGORY
} from 'constants/RouterNames'

const rebateModeration = () => import('components/pages/secured/promotion/rebate/rebateSeller/rebateModeration/rebateModeration.vue')
const rebateModerationProduct = () => import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseProduct/rebateBaseProduct.vue')
const rebateModerationCategory = () => import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseCategory/rebateBaseCategory.vue')

const rebateModerationRoute = {
	path: '/rebate-moderacao/:rebateId',
	component: rebateModeration,
	name: ROUTE_REBATE_MODERATION_SELLER.name,
	meta: { requiresAuthorities: ROUTE_REBATE_MODERATION_SELLER.routerAuth, breadcrumb: { name: 'Moderação', active: false } },
	children: [
		{ path: '/rebate-moderacao/:rebateId/produto', props: { isModeration: true }, component: rebateModerationProduct, name: ROUTE_REBATE_MODERATION_PRODUCT.name, meta: { requiresAuthorities: ROUTE_REBATE_MODERATION_PRODUCT.routerAuth } },
		{ path: '/rebate-moderacao/:rebateId/categoria', props: { isModeration: true }, component: rebateModerationCategory, name: ROUTE_REBATE_MODERATION_CATEGORY.name, meta: { requiresAuthorities: ROUTE_REBATE_MODERATION_CATEGORY.routerAuth } }
	]
}

export default rebateModerationRoute
