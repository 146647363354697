<template>
	<div class="mkt-box" :class="boxSizeClass">
		<div class="mkt-box__header" v-if="blHasHeader">
			<div class="mkt-box__header__title" :class="{ 'has-collapse': blHasCollapse }">
				<slot name="title"> {{txTitle}} </slot>
			</div>
			<div v-if="blHasCollapse" class="mkt-box__collapse" :class="{ 'mkt-box__collapse--disabled': blCollapseDisabled }" @click="!blCollapseDisabled ? toggleBox() : null ">
				<mkt-icon :tx-icon="isCollapsed ? 'icon-chevron-down' : 'icon-chevron-up'" tx-font-size="14px" />
			</div>
		</div>
		<div class="mkt-box__content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'
	import TypeSize from 'domains/TypeSize'

	export default {
		props: {
			tpPaddingSize: new PropConfig({ type: TypeSize, valueDefault: () => TypeSize.MEDIUM }),
			txTitle: new PropConfig({ type: String, required: false }),
			blHasCollapse: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blHasHeader: new PropConfig({ type: Boolean, required: false, valueDefault: true }),
			blCollapse: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			blCollapseDisabled: new PropConfig({ type: Boolean, required: false, valueDefault: false })
		},
		data() {
			return {
				isCollapsed: this.blCollapse
			}
		},
		computed: {
			boxSizeClass() {
				let classes = this.isCollapsed ? 'mkt-box__collapsed ' : ''
				switch (this.tpPaddingSize) {
				case TypeSize.NONE:
					return classes + 'mkt-bot--padding-none'
				case TypeSize.SMALL:
					return classes + 'mkt-bot--padding-small'
				case TypeSize.MEDIUM:
					return classes + 'mkt-bot--padding-medium'
				case TypeSize.LARGE:
					return classes + 'mkt-bot--padding-large'
				default:
					return classes
				}
			}
		},
		methods: {
			toggleBox() {
				this.isCollapsed = !this.isCollapsed
			},
			closeBox() {
				this.isCollapsed = true
			},
			openBox() {
				this.isCollapsed = false
			}
		}
	}
</script>

<style src="./mktBox.scss" lang="scss">

</style>
