import {
	ROUTE_LOGIN
	, ROUTE_CHANGE_PASSWORD
	, ROUTE_ACCOUNT_PASSWORD_RECOVERY
	, ROUTE_FORGOT_PASSWORD
	, ROUTE_EMAIL_SENT
} from 'constants/RouterNames'

import loginGuard from 'src/router/guards/loginGuard.js'

import login from 'components/pages/unsecured/login/login'
import unsecured from 'components/pages/unsecured/unsecured'
const emailSent = () => import('components/pages/unsecured/emailSent/emailSent')
const forgotPassword = () => import('components/pages/unsecured/forgotPassword/forgotPassword')
const changePassword = () => import('components/pages/unsecured/changePassword/changePassword')

const loginRoute = {
	path: '/',
	redirect: '/login',
	component: unsecured,
	children: [
		{ path: '/login', component: login, name: ROUTE_LOGIN.name, beforeEnter: loginGuard },
		{ path: '/change-password', component: changePassword, name: ROUTE_CHANGE_PASSWORD.name, meta: { requiresAuth: true } },
		{ path: '/password-recovery', component: changePassword, name: ROUTE_ACCOUNT_PASSWORD_RECOVERY.name },
		{ path: '/forgot-password', component: forgotPassword, name: ROUTE_FORGOT_PASSWORD.name },
		{ path: '/email-sent', component: emailSent, name: ROUTE_EMAIL_SENT.name }
	]
}

export default loginRoute
