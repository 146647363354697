import {
	ROUTE_PRODUCT_MATCH
	, ROUTE_PRODUCT_MATCH_SEARCH
	, ROUTE_PRODUCT_MATCH_MODERATION
} from 'constants/RouterNames'

const productMatch = () => import('components/pages/secured/product/productMatch/productMatch.vue')
const productMatchSearch = () => import('components/pages/secured/product/productMatch/productMatchSearch/productMatchSearch.vue')
const productMatchModeration = () => import('components/pages/secured/product/productMatch/productMatchModeration/productMatchModeration.vue')

const productMatchRoute = {
	path: '/match-produtos-aprovados',
	redirect: '/busca-match-produtos-aprovados',
	component: productMatch,
	name: ROUTE_PRODUCT_MATCH.name,
	meta: { requiresAuthorities: ROUTE_PRODUCT_MATCH.routerAuth },
	children: [
		{ path: '/busca-match-produtos-aprovados',
			component: productMatchSearch,
			name: ROUTE_PRODUCT_MATCH_SEARCH.name,
			meta: { breadcrumb: { name: 'Match de Produtos Aprovados' }, requiresAuthorities: ROUTE_PRODUCT_MATCH_SEARCH.routerAuth },
			children: [{
				path: '/match-produtos-aprovados', props: true, component: productMatchModeration, name: ROUTE_PRODUCT_MATCH_MODERATION.name, meta: { requiresAuthorities: ROUTE_PRODUCT_MATCH_MODERATION.routerAuth }
			}]
		}
	]
}

export default productMatchRoute
