import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const FinancialAccountsReceivable = new AuthorityBuilder({
	accessGroup: 'FINANCIAL_ACCOUNTS_RECEIVABLE_REPORT',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

export default [
	FinancialAccountsReceivable
]
