import { ROUTE_REBATE_ACCESS_SELLER } from 'constants/RouterNames'
import rebateListRoute from './rebateList'
import rebateDetailRoute from './rebateDetail'
import rebateListModeration from './rebateListModeration'
import rebateModeration from './rebateModeration'

const rebate = () => import('components/pages/secured/promotion/rebate/rebate.vue')

const rebateRoute = {
	path: '/rebate',
	redirect: '/listar-rebates-seller',
	component: rebate,
	name: ROUTE_REBATE_ACCESS_SELLER.name,
	meta: { breadcrumb: { name: 'Rebate automático' }, requiresAuthorities: ROUTE_REBATE_ACCESS_SELLER.routerAuth },
	children: [
		rebateListRoute,
		rebateDetailRoute,
		rebateListModeration,
		rebateModeration

	]
}

export default rebateRoute
