<template>
	<div class="mkt-light-box" :class="{'fade-out': fadeOut }">
		<div class="mkt-light-box__container" v-click-outside="onclickOutside">
			<button class="mkt-light-box__close-button" @click.prevent="closeLightBox">
				<mkt-icon tx-font-size="13px" tx-icon-color="#e8e8e8" tx-icon="icon-close"></mkt-icon>
			</button>
			<div class="loader" v-if="!isLoaded"></div>
			<img :src="url" v-on:load="loaded">
		</div>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import { eventEmit, EVENT_LIGHT_BOX_CLOSE } from 'services/eventBusService.js'

export default {
	name: 'mkt-light-box',
	props: {
		url: new PropConfig({
			type: String,
			required: true
		})
	},
	data() {
		return {
			isDestroyed: false,
			isLoaded: false,
			fadeOut: false,
			allowClickOutside: false
		}
	},
	mounted() {
		this.allowClickOutside = false
		setTimeout(() => {
			this.allowClickOutside = true
		}, 200)
	},
	methods: {
		loaded() {
			this.isLoaded = true
		},
		onclickOutside() {
			this.allowClickOutside && this.closeLightBox()
		},
		closeLightBox() {
			this.fadeOut = true
			setTimeout(() => {
				eventEmit(EVENT_LIGHT_BOX_CLOSE)
			}, 200)
		}
	}
}
</script>
<style src="./mktLightBox.scss" lang="scss">
</style>
