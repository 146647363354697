import {
	ROUTE_PRODUCT_MODERATION
	, ROUTE_PRODUCT_MODERATION_LIST
	, ROUTE_PRODUCT_MODERATION_DETAIL
} from 'constants/RouterNames'

const productModeration = () => import('components/pages/secured/product/productModeration/productModeration')
const productModerationList = () => import('components/pages/secured/product/productModeration/productModerationList/productModerationList')
const productModerationDetail = () => import('components/pages/secured/product/productModeration/productModerationDetail/productModerationDetail')

const productModerationRoute = {
	path: '/moderacao-produtos',
	redirect: '/listagem-moderacao',
	component: productModeration,
	name: ROUTE_PRODUCT_MODERATION.name,
	meta: { breadcrumb: { name: 'Moderação' }, requiresAuthorities: ROUTE_PRODUCT_MODERATION.routerAuth },
	children: [
		{ path: '/listagem-moderacao', component: productModerationList, name: ROUTE_PRODUCT_MODERATION_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_PRODUCT_MODERATION_LIST.routerAuth } },
		{ path: '/moderacao/:id', props: true, component: productModerationDetail, name: ROUTE_PRODUCT_MODERATION_DETAIL.name, meta: { requiresAuthorities: ROUTE_PRODUCT_MODERATION_DETAIL.routerAuth } }
	]
}

export default productModerationRoute
