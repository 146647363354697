import {
	ROUTE_SAC
} from 'constants/RouterNames'

import sacSeller from './sacSeller'
import sacColombo from './sacColombo'
import notificationColombo from './notificationColombo'
import notificationSeller from './notificationSeller'
const sac = () => import('components/pages/secured/sac/sac')

const sacRoute = {
	path: '/sac',
	component: sac,
	name: ROUTE_SAC.name,
	meta: { breadcrumb: { name: 'SAC', active: false }, requiresAuthorities: ROUTE_SAC.routerAuth },
	children: [
		sacSeller,
		sacColombo,
		notificationColombo,
		notificationSeller
	]
}

export default sacRoute
