import axios from 'axios'
import { interceptorRequest, interceptorRequestError, interceptorResponseSuccess, interceptorResponseError } from 'httpServices/httpServiceInterceptor.js'

const config = { timeout: 30000 }

const axiosInstance = axios.create(config)

axiosInstance.interceptors.response.use(interceptorResponseSuccess, interceptorResponseError)
axiosInstance.interceptors.request.use(interceptorRequest, interceptorRequestError)

const Get = (url, params, config) => {
	return axiosInstance.get(url, addParams(params, config))
}

const Delete = (url, params) => {
	return axiosInstance.delete(url, { params })
}

const Post = (url, data, headers) => {
	return axiosInstance.post(url, data, headers)
}

const Put = (url, data, config) => {
	return axiosInstance.put(url, data, config)
}

const addParams = (params, config) => {
	return Object.assign({}, { params }, config)
}

export { Post, Get, Put, Delete }
