<template>
	<div class="mkt-checkbox">
		<mkt-label v-if="txComponentLabel" :txLabel="txComponentLabel"></mkt-label>
		<div class="mkt-checkbox__container" v-if="blArrayCheckbox">
			<md-checkbox
				v-for="item in ltOptions"
				v-model="componentModel"
				:disabled="blDisabled || blReadOnly"
				:value="cpFieldValue(item)"
				:class="{ 'md-read-only': blReadOnly }"
				:key="cpFieldValue(item)"
				class="md-primary">
				{{cpFieldLabel(item)}}
		</md-checkbox>
	</div>
		<md-checkbox
			v-else
			v-model="componentModel"
			:disabled="blDisabled || blReadOnly"
			:class="{ 'md-read-only': blReadOnly }"
			class="md-primary"
			>
			{{txLabel}}
		</md-checkbox>
	</div>
</template>

<script>
import _ from 'lodash-core'
import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-checkbox',
	props: {
		model: new PropConfig({ type: [Boolean, Array, Object, String], required: true }),
		ltOptions: new PropConfig({ type: Array, required: false }),
		txFieldLabel: new PropConfig({ type: String, required: false }),
		txLabel: new PropConfig({ type: String, required: false }),
		txFieldValue: new PropConfig({ type: String, required: false }),
		txComponentLabel: new PropConfig({ type: String, required: false }),
		blArrayCheckbox: new PropConfig({ type: Boolean, required: true }),
		blDisabled: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false })
	},
	data() {
		return {
			events: {
				updateModel: (selectedItem) => this.$emit('input', selectedItem)
			}
		}
	},
	methods: {
		cpFieldLabel(currentObject) {
			return _.get(currentObject, this.txFieldLabel)
		},
		cpFieldValue(currentObject) {
			return _.get(currentObject, this.txFieldValue)
		}
	},
	computed: {
		componentModel: {
			get() {
				return this.model
			},
			set(selectedItem) {
				this.events.updateModel(selectedItem)
			}
		}
	}
}
</script>

<style src="./mktCheckbox.scss" lang="scss">
</style>
