import Enum from './Domain'

class OccurrenceType extends Enum { }

OccurrenceType.initEnum({
	WITHOUT_STOCK: {
		message: 'Sem estoque',
		icon: 'icon-inbox',
		color: '#ABA9A9'
	},
	PRICE_CHANGE: {
		message: 'Alteração de preço',
		icon: 'icon-cached',
		color: '#6E6E6E'
	},
	CATEGORY_DISABLED: {
		message: 'Categoria Desativada',
		icon: 'icon-format_list_bulleted',
		color: '#ABA9A9'
	}
})

export default OccurrenceType
