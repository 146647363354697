<template>
	<div class="mkt-data-field">
			<span class="mkt-data-field__title">{{txTitle}}:</span>
			<slot name="value">
				<input v-if="txMask" class="mkt-data-field__masked-value" disabled="true" v-mask="txMask" :value="txData"/>
				<span v-else class="mkt-data-field__value" >{{txData}}</span>
			</slot>
			<mkt-icon
				class="mkt-data-field__observation"
				tx-icon="icon-info-circle"
				tx-font-size="19px"
				v-if="!!txObservation"
				tx-icon-color="#F58220"
				v-tooltip="{ text: txObservation, show: !!txObservation }">
			</mkt-icon>
	</div>
</template>
<script>
import { mask } from 'vue-the-mask'

export default {
	name: 'mkt-data-field',
	directives: {
		mask
	},
	props: {
		txTitle: {
			required: true
		},
		txData: {
			required: false
		},
		txMask: {
			required: false
		},
		txObservation: {
			required: false
		}
	}
}
</script>
<style src="./mktDataField.scss" lang="scss">
</style>

