import { SET_LOGGED_USER_MUTATION, SET_REDIRECT_TO_LOGIN_MUTATION } from 'constants/MutationTypes'

// initial state
const state = {
	loggedUser: null,
	redirectToLogin: false
}

// getters
const getters = {
	isLoggedIn: state => !!state.loggedUser,
	wasRedirectToLogin: state => state.redirectToLogin,
	getLoggedUser: state => state.loggedUser,
	getLoggedUserSellerId: state => state.loggedUser && state.loggedUser.sellerId,
	getLoggedUsername: state => state.loggedUser && state.loggedUser.username,
	getLoggedSellerId: state => state.loggedUser && state.loggedUser.sellerId
}

// actions
const actions = {
	setLoggedUser: ({ commit }, loggedUser) => {
		commit(SET_LOGGED_USER_MUTATION, loggedUser)
	},
	setRedirectToLogin: ({ commit }, redirected) => {
		commit(SET_REDIRECT_TO_LOGIN_MUTATION, redirected)
	}
}

// mutations
const mutations = {
	[SET_LOGGED_USER_MUTATION](state, loggedUser) {
		state.loggedUser = loggedUser
	},
	[SET_REDIRECT_TO_LOGIN_MUTATION](state, redirected) {
		state.redirectToLogin = redirected
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
