import { SET_SELLER_PAYMENT_SETTINGS } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerPaymentSettings = new SellerRegistrationBaseModule('sellerPaymentSettings', SET_SELLER_PAYMENT_SETTINGS, [])

const state = sellerPaymentSettings.getState()
const initialState = sellerPaymentSettings.getInitialState()
const getters = sellerPaymentSettings.getGetters()
const actions = sellerPaymentSettings.getSetters()
const mutations = {
	[SET_SELLER_PAYMENT_SETTINGS](state, paymentSettings) {
		state.sellerRegistration.sellerPaymentSettings = paymentSettings
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
