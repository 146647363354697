import { REGEX_NOT_NUMBER } from 'src/constants/Regex.js'

const buildParenthesesString = (outerString, innerString) => {
	const innerStringParentheses = innerString ? ` (${innerString})` : ''
	return `${outerString}${innerStringParentheses}`
}

const replaceBy = (value, splitCriteria, replace) => {
	return value.split(splitCriteria)
		.reduce((acc, curr) => acc + replace + curr)
}

const getStringNumbers = (val) => {
	return val && parseFloat(val.replace(REGEX_NOT_NUMBER, ''))
}

export {
	buildParenthesesString,
	getStringNumbers,
	replaceBy
}
