<template>
	<div
		v-if="(nrMaxFiles > uploadedFiles.length || (blSingleUpload))"
		:class="{ 'is-invisible': isInvisible }"
		class="mkt-file-upload-container"
	>
			<label
				v-if="!blReadOnly"
				:for="txIdFileUpload"
				class="mkt-file-upload-container__container__upload-zone"
			>
				<slot name="upload-icon-override">
					<i aria-hidden="true" class="mkt-file-upload-container__container__upload-zone__icon icon-add_circle"></i>
				</slot>
			</label>

			<div v-if="txSubtitle" class="mkt-file-upload-container__container__text">
				{{ txSubtitle }}
			</div>
		
		<input
			v-if="!blReadOnly && blSingleUpload"
			@change="onChangeValue($event)" 
			:id="txIdFileUpload"
			:accept="acceptedFileTypes.code.join(', ')"
			type="file"
		>
		<input
			v-else-if="!blReadOnly" 
			@change="onChangeValue($event)"
		  :id="txIdFileUpload"
			:accept="acceptedFileTypes.code.join(', ')"
		 	type="file"
			multiple
		>
		<img 
			v-else
			:src="value[txImageSource]"
		>
	</div>
</template>

<script>
import fileUploadMixin from 'components/generics/mktFileComponents/fileUploadMixin.js'

export default {
	name: 'mkt-file-upload-container',

	mixins: [fileUploadMixin],

	computed: {
		isInvisible() {
			return this.blSingleUpload && (this.uploadedFiles.length || this.ltProcessingFiles.length)
		}
	}
}
</script>

<style src="./mktFileUploadContainer.scss" lang="scss">
</style>
