<template>
  <div class="mkt-field" :class="{ 'mkt-field--disabled': blDisabled }">
    <md-field
      :md-clearable="blDisplayClean"
      :md-counter="blDisplayCounter"
      :class="{ 'md-invalid': hasErrors, [txFieldClass]: txFieldClass, 'md-read-only': blReadOnly }"
      :md-toggle-password="blTogglePassword"
    >
      <mkt-label
        v-if="txLabel || txHighligthedLabel"
        class="mkt-field__label"
        :txLabel="txLabel"
        :tx-observation="txObservation"
        :bl-required="blRequired"
        :tx-highligthed-label="txHighligthedLabel"
      ></mkt-label>

      <mkt-input
        v-if="tpFieldIsInput && !blReadOnly"
        class="mkt-field__field"
        :tx-placeholder="!blReadOnly ? txPlaceholder : ' '"
        :tp-input-type="tpInputType"
        :bl-required="blRequired"
        :nr-maxlength="nrMaxlength"
        :bl-prevent-default="blPreventDefault"
        :bl-disabled="blDisabled"
        :bl-read-only="blReadOnly"
        :tx-id="txId"
        v-model.trim="fieldModel"
        @focus="$emit('focus')"
        @blur="onBlur"
      />

      <mkt-textarea
        v-else-if="tpFieldIsTextarea || blReadOnly"
        class="mkt-field__textarea"
        :tx-placeholder="!blReadOnly ? txPlaceholder : '-'"
        :bl-required="blRequired"
        :nr-maxlength="nrMaxlength"
        :bl-disabled="blDisabled"
        :bl-read-only="blReadOnly"
        v-model.trim="fieldModel"
        :tx-id="txId"
        @focus="$emit('focus')"
        @blur="onBlur"
      />

      <span class="md-icon-error" v-if="hasErrors && isIconError">
        <mkt-icon
          tx-icon="icon-error"
          tx-icon-color="#EB5757"
          tx-font-size="20px"
    			v-tooltip="{ text: (txErrorMessage || 'Campo obrigatório'), show: true, style: { maxWidth: '200px' }, options: { appendTo: 'document' } }"
        ></mkt-icon>
      </span>
      <span class="md-error" v-else-if="hasErrors">{{txErrorMessage || 'Campo obrigatório'}}</span>
    </md-field>
  </div>
</template>

<script>
import TypeField from 'domains/TypeField'
import InputType from 'domains/InputType'
import mktInput from 'src/components/globals/mktInput/mktInput'
import mktTextarea from 'src/components/globals/mktTextarea/mktTextarea'
import formFieldMixin from './formFieldMixin'

export default {
	name: 'mkt-field',
	mixins: [formFieldMixin],
	components: {
		mktInput,
		mktTextarea
	},
	props: {
		tpField: { type: TypeField, default: () => TypeField.INPUT },
		tpInputType: { type: InputType, default: () => InputType.TEXT },
		blDisplayClean: { type: Boolean, default: false },
		blDisplayCounter: { type: Boolean, default: false },
		nrMinlength: { type: Number, required: false },
		nrMaxlength: { type: Number, default: 100 },
		fnValidate: { type: Function },
		txFieldClass: { type: String, required: false, default: '' },
		blTogglePassword: { type: Boolean, required: false, default: true },
		txObservation: { required: false }
	},
	computed: {
		tpFieldIsInput: function() {
			return this.tpField === TypeField.INPUT
		},
		tpFieldIsTextarea: function() {
			return this.tpField === TypeField.TEXTAREA
		}
	}
}
</script>

<style src="./mktField.scss" lang="scss">
</style>
