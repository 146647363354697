import { SET_SESSION_TIMER, SET_END_SESSION, SET_CURRENT_TIMEOUT, SET_ON_LOGOUT } from 'constants/MutationTypes'

import { SESSION_EXPIRE } from 'constants/LocalStorageKeys.js'

const state = {
	sessionTimer: null,
	currentTimeout: null,
	onLogout: null
}

const mutations = {
	[SET_SESSION_TIMER](state, currentTime) {
		localStorage.setItem(SESSION_EXPIRE, currentTime)
		state.sessionTimer = localStorage.getItem(SESSION_EXPIRE)
	},
	[SET_CURRENT_TIMEOUT](state, currentTimeout) {
		state.currentTimeout = currentTimeout
	},
	[SET_END_SESSION](state) {
		localStorage.removeItem(SESSION_EXPIRE)
		state.sessionTimer = null
	},
	[SET_ON_LOGOUT](state, onLogout) {
		state.onLogout = onLogout instanceof Function ? onLogout : () => onLogout
	}
}

const getters = {
	getSessionTimer: (state) => {
		return state.sessionTimer
	},
	getCurrentTimeout: (state) => state.currentTimeout,
	getOnLogout: (state) => state.onLogout
}

const actions = {
	setUpdateSessionTimer: ({ commit }, currentTime) => {
		commit(SET_SESSION_TIMER, currentTime)
	},
	setEndSession: ({ commit }) => {
		commit(SET_END_SESSION)
	},
	setCurrentTimeout: ({ commit }, timeout) => {
		commit(SET_CURRENT_TIMEOUT, timeout)
	},
	setOnLogout: ({ commit }, onLogout) => {
		commit(SET_ON_LOGOUT, onLogout)
	}
}

export default {
	state,
	mutations,
	getters,
	actions
}
