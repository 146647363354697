import Vue from 'vue'

// let pageHeight

Vue.directive('reactive-position', {
	bind(el, binding, vNode) {
		el.addEventListener('animationstart', function() {
			const pageHeight = document.documentElement.getBoundingClientRect().height
			const rect = el.getBoundingClientRect()
			const footerSize = document.querySelector('.page-footer__wrapper').getBoundingClientRect().height

			const overlap = (pageHeight - rect.bottom)
			if (overlap < footerSize) {
				el.style.top = (overlap - footerSize) + 'px'
			} else if (overlap > footerSize) {
				el.style.top = '0'
			}
		}, false)
	},
	unbind(el, binding) {
		document.removeEventListener('animationstart', el)
	}
})
