export const orderCharacteristicsByActive = (a, b) => {
	if (a.active && !b.active) {
		return -1
	} else if (!a.active && b.active) {
		return 1
	} else {
		const nameThis = a.description && a.description.toLowerCase()
		const nameThat = b.description && b.description.toLowerCase()
		return nameThis && nameThis.localeCompare(nameThat)
	}
}
