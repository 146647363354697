<template>
  <div class="server-error">
    <div class="container" v-cloak>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="server-error__circle-container">
            <div class="server-error__circle-container__icon">
              <img :src="icon">
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="server-error__text-container">
            <h1>
              Ops!
              <br>Página não encontrada.
            </h1>
            <h2>(Erro 404)</h2>
            <h3>
              A página que você estava buscando não existe
              <br>ou não está mais disponível.
            </h3>
            <h4>Possíveis causas:</h4>
            <ul>
              <li>Verifique se você digitou corretamente o endereço desejado.</li>
              <li>O conteúdo pode ter sido removido ou não estar mais disponível.</li>
              <li>O servidor pode estar fora do ar no momento.</li>
            </ul>
            <h5>O que é Colombo Marketplace?</h5>
            <nav>
              <mkt-button :button="button"></mkt-button>
              <mkt-button :button="buttonStore"></mkt-button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon from 'src/assets/img/errorBlue.png'
import Button from 'pojos/Button'

export default {
	data() {
		return {
			icon,
			button: new Button({
				label: 'conheça a plataforma',
				className: 'md-white md-raised',
				size: 'lg',
				onClick: () => this.redirectDashboard()
			}),
			buttonStore: new Button({
				label: 'acesse a nossa loja',
				className: 'md-white md-raised',
				size: 'lg',
				onClick: () => this.redirectStore()
			})
		}
	},
	methods: {
		redirectDashboard(route) {
			window.open('https://www.colombo.com.br/marketplace', '_blank')
		},
		redirectStore(route) {
			window.open('https://www.colombo.com.br', '_blank')
		}
	}
}
</script>

<style src="./serverError.scss" lang="scss"></style>
