import {
	ROUTE_SELLER_NOTIFICATION,
	ROUTE_SELLER_NOTIFICATION_LIST
} from 'constants/RouterNames'

const notificationSeller = () => import('components/pages/secured/sac/notificationSeller/notificationSeller')
const notificationSellerList = () => import('components/pages/secured/sac/notificationSeller/notificationSellerList/notificationSellerList')

const notificationColomboRoute = {
	path: '/notificacoes',
	redirect: '/listagem-notificacoes',
	component: notificationSeller,
	name: ROUTE_SELLER_NOTIFICATION.name,
	meta: { breadcrumb: { name: 'Notificação' }, requiresAuthorities: ROUTE_SELLER_NOTIFICATION.routerAuth },
	children: [
		{ path: '/listagem-notificacoes', component: notificationSellerList, name: ROUTE_SELLER_NOTIFICATION_LIST.name, meta: { requiresAuthorities: ROUTE_SELLER_NOTIFICATION_LIST.routerAuth } }
	]
}

export default notificationColomboRoute
