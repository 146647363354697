/**
 * @description convert complex object to simple object. Example change prop with array to prop with string
*/
import { REGEX_NUMBERS } from 'constants/Regex.js'

const toObjectUrl = (data) => {
	const param = {}
	return iterateObject(data, param)
}

const iterateObject = (data, param) => {
	for (let [key, value] of Object.entries(data)) {
		if (value !== null && value !== undefined) {
			if (value instanceof Array || !(value instanceof Object)) {
				param[key] = value.toString()
			} else if (value instanceof Object) {
				iterateObject(value, param)
			}
		}
	}
	return param
}

const serializeObject = (obj) => {
	const simplifiedObject = toObjectUrl(obj)
	const keysToReduce = Object.keys(simplifiedObject)

	if (!keysToReduce.length) return ''

	if (keysToReduce.length === 1) {
		const objKey = keysToReduce[0]
		return `${objKey}=${encodeURIComponent(simplifiedObject[objKey])}`
	}

	return Object.keys(simplifiedObject).reduce((previousValue, ActualObjKey) => {
		const serializedObject = simplifiedObject[previousValue] ? `${previousValue}=${encodeURIComponent(simplifiedObject[previousValue])}&` : `${previousValue}&`
		return `${serializedObject}${ActualObjKey}=${encodeURIComponent(simplifiedObject[ActualObjKey])}`
	})
}

const transcribeRouteQuery = (query = {}, reference = {}) => {
	const queryParam = {}

	for (const prop in query) {
		const val = query[prop]
		if (!val || val.length === 0) {
			continue
		}

		const isStringParam = typeof val === 'string'
		if (isStringParam) {
			const isBooleanParam = val === 'false' || val === 'true'
			if (isBooleanParam) {
				queryParam[prop] = (val === 'true')
			} else {
				queryParam[prop] = isInstanceofArray(reference[prop]) ? [parseToNumber(val)] : parseToNumber(val)
			}
		} else if (val.length) {
			queryParam[prop] = val.map(parseToNumber)
		} else {
			queryParam[prop] = val
		}
	}

	return { ...reference, ...queryParam }
}

const isInstanceofArray = (val) => {
	return val instanceof Array
}

const parseToNumber = (val) => {
	return (val.match && val.match(REGEX_NUMBERS)) ? parseInt(val) : val
}

export { toObjectUrl, serializeObject, transcribeRouteQuery }
