<template>
  <md-field :class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }" class="mkt-time-input">
		<mkt-label v-if="txLabel" class="mkt-field__label" :txLabel="txLabel" :bl-required="blRequired"></mkt-label>
		<md-input
			class="mkt-time-input__time"
			@keyup.esc="modelTime = null"
			:placeholder="txPlaceholder"
			:disabled="blDisabled || blReadOnly"
			type="time"
			:required="blRequired"
			:id="txId"
			v-model="modelTime"
			@blur="validateField"
		></md-input>
		<span class="md-error" v-if="hasErrors">{{formValidationError || 'Campo obrigatório'}}</span>
  </md-field>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import InputType from 'domains/InputType'

export default {
	name: 'mkt-time-input',
	props: {
		txLabel: new PropConfig({ type: String }),
		txPlaceholder: new PropConfig({ type: String }),
		txMask: new PropConfig({ type: String }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		blDisabled: new PropConfig({ type: Boolean }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		nrMaxlength: new PropConfig({ type: Number, valueDefault: 10 }),
		txId: new PropConfig({ type: String, required: false }),
		value: new PropConfig({ required: false })
	},
	data() {
		return {
			tpInputType: InputType.NUMBER,
			hasErrors: false,
			formValidationError: ''
		}
	},
	computed: {
		modelTime: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	},
	methods: {
		validateField() {
			if (!this.value) {
				this.modelTime = null
			}

			this.$emit('blur')
			if (this.blRequired) {
				this.$nextTick(() => {
					this.hasErrors = !this.value
				})
			} else {
				this.hasErrors = false
			}
		}
	}
}
</script>

<style src="./mktTimeInput.scss" lang="scss">
</style>
