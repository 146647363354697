<template>
	<div class="mkt-input-money">
		<md-field :md-counter="false"	:class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }">
			<mkt-label v-if="txLabel" class="mkt-label__input" :txLabel="txLabel"></mkt-label>
			<md-input
				:placeholder="txPlaceholder"
				v-model="fieldModel"
				@focus="isInputActive = true"
				:required="blRequired"
				:maxlength="nrMaxlength"
				@blur="validateField"
				v-on:keypress="isNumber"
				:disabled="blReadOnly"
				type="text"
			></md-input>
		</md-field>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import { REGEX_NOT_PURE_NUMBER } from 'constants/Regex'
import { formatMoney } from 'services/accountingServices'
import { transformValue } from 'services/transformDecimalService.js'

export default {
	props: {
		txPlaceholder: new PropConfig({
			type: String
		}),
		txLabel: new PropConfig({
			type: String,
			required: false
		}),
		blRequired: new PropConfig({
			type: Boolean,
			valueDefault: false
		}),
		blReadOnly: new PropConfig({
			type: Boolean,
			valueDefault: false
		}),
		blValidateOnSet: new PropConfig({
			type: Boolean,
			valueDefault: false
		}),
		nrMaxlength: new PropConfig({
			type: [String, Number],
			required: false,
			valueDefault: 11
		}),
		value: new PropConfig({
			required: true
		})
	},
	data() {
		return {
			isInputActive: false,
			hasErrors: false
		}
	},
	created() {
		if (this.value) {
			const val = parseFloat(this.value)
			this.$emit('input', val.toFixed(2))
		}
	},
	methods: {
		isNumber(e) {
			const isNotNumber = e.key.match(REGEX_NOT_PURE_NUMBER)
			if (isNotNumber) {
				e.preventDefault()
			}
		},
		replaceComma(val) {
			return val.replace(',', '.')
		},
		validateField() {
			this.isInputActive = false
			if (this.blRequired) {
				this.hasErrors = !this.value
			}
			this.$emit('blur')
		}
	},
	computed: {
		fieldModel: {
			get() {
				if (!this.value) return null
				if (this.isInputActive) {
					return transformValue(this.value)
				} else {
					return formatMoney(this.value)
				}
			},
			set(newValue) {
				if (!newValue) {
					this.$emit('input', null)
				} else {
					const formattedVal = transformValue(newValue)
					this.$emit('input', this.replaceComma(formattedVal))
					if (this.blValidateOnSet && this.hasErrors) {
						this.validateField()
					}
				}
			}
		}
	}

}
</script>
<style src="./mktInputMoney.scss" lang="scss">
</style>
