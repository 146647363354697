import {
	ROUTE_PROMOTION
} from 'constants/RouterNames'

import promotionSeller from './promotionSeller/'
import promotionColombo from './promotionColombo/'
import rebateColombo from './rebateColombo'
import rebateSeller from './rebateSeller'
import rebateBulkAction from './rebateBulkAction'

const promotion = () => import('components/pages/secured/promotion/promotion.vue')

const promotionRoute = {
	path: '/promotion',
	component: promotion,
	name: ROUTE_PROMOTION.name,
	meta: { breadcrumb: { name: 'Promoção', active: false } },
	children: [
		promotionSeller,
		promotionColombo,
		rebateColombo,
		rebateSeller,
		rebateBulkAction
	]
}

export default promotionRoute
