import { SET_SELLER_INTEGRATION } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerIntegration = new SellerRegistrationBaseModule('sellerIntegration', SET_SELLER_INTEGRATION)

const state = sellerIntegration.getState()
const initialState = sellerIntegration.getInitialState()
const getters = sellerIntegration.getGetters()
const actions = sellerIntegration.getSetters()
const mutations = sellerIntegration.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
