import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Promotion = new AuthorityBuilder({
	accessGroup: 'PROMOTION',
	accessType: ['ACCESS', 'PROMOTION_ACCESS', 'PROMOTION_ADD_WRITE', 'PROMOTION_EDIT_READ', 'PROMOTION_EDIT_WRITE', 'PROMOTION_LIST_READ', 'PROMOTION_LIST_EXPORT_READ']
})

const Rebate = new AuthorityBuilder({
	accessGroup: 'REBATE',
	accessType: ['ACCESS', 'LIST_READ', 'EDIT_READ', 'MODERATION_WRITE']
})

export default [
	Promotion,
	Rebate
]
