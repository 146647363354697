import Vue from 'vue'
import {
	SET_PRODUCT_MODERATION,
	SET_INITIAL_STATE_PRODUCT_MODERATION,
	SET_MATCHED_PRODUCT,
	SET_PRODUCT_MODERATION_INITIAL_STATE,
	SET_PRODUCT_CHARACTERISTICS,
	SET_MATCHED_PRODUCT_CHARACTERISTICS,
	SET_ONE_MATCHED_PRODUCT_CHARACTERISTICS,
	REMOVE_MATCHED_PRODUCT,
	SET_SWAP_PRODUCT_AND_MATCHED_MODERATION,
	SET_RESET_MODERATION
} from 'constants/MutationTypes'

import ProductModerationDto from 'src/dtos/ProductModerationDto'

// initial state
const state = {
	initialState: new ProductModerationDto({}),
	productModeration: new ProductModerationDto({}),
	hasMatch: false,
	matchedProduct: new ProductModerationDto({}),
	matchedProductInitialState: new ProductModerationDto({})
}

// getters
const getters = {
	getProductModeration: state => state.productModeration,
	getProductModerationHasMatch: state => state.hasMatch,
	getProductModerationMatched: state => state.matchedProduct,
	getProductModerationInitialState: state => state.initialState,
	getMatchedProductInitialState: state => state.matchedProductInitialState
}

// actions
const actions = {
	setProductModeration: ({ commit }, productModeration) => {
		commit(SET_INITIAL_STATE_PRODUCT_MODERATION, productModeration)
	},
	setMatchedProduct: ({ commit }, productModeration) => {
		commit(SET_MATCHED_PRODUCT, productModeration)
	},
	setProductCharacteristics: ({ commit }, characteristics) => {
		commit(SET_PRODUCT_CHARACTERISTICS, characteristics)
	},
	setMatchedProductCharacteristics: ({ commit }, characteristics) => {
		commit(SET_MATCHED_PRODUCT_CHARACTERISTICS, characteristics)
	},
	setOneMatchedProductCharacteristics: ({ commit }, characteristic) => {
		commit(SET_ONE_MATCHED_PRODUCT_CHARACTERISTICS, characteristic)
	},
	modifyProductModeration: ({ commit }, productModeration) => {
		commit(SET_PRODUCT_MODERATION, productModeration)
	},
	modifyProductModerationInitialState: ({ commit }, productModeration) => {
		commit(SET_PRODUCT_MODERATION_INITIAL_STATE, productModeration)
	},
	removeMatchedProduct: ({ commit }) => {
		commit(REMOVE_MATCHED_PRODUCT)
	},
	setSwapProductAndMatchModeration: ({ commit }) => {
		commit(SET_SWAP_PRODUCT_AND_MATCHED_MODERATION)
	},
	setResetModeration: ({ commit }) => {
		commit(SET_RESET_MODERATION)
	}
}

// mutations
const mutations = {
	[SET_INITIAL_STATE_PRODUCT_MODERATION](state, productModeration) {
		state.initialState = state.productModeration = productModeration
	},
	[SET_PRODUCT_MODERATION](state, productModeration) {
		state.productModeration = { ...state.productModeration, ...productModeration }
	},
	[SET_PRODUCT_CHARACTERISTICS](state, characteristics) {
		Vue.set(state.productModeration, 'characteristics', characteristics)
	},
	[SET_MATCHED_PRODUCT_CHARACTERISTICS](state, characteristics) {
		Vue.set(state.matchedProduct, 'characteristics', characteristics)
	},
	[SET_ONE_MATCHED_PRODUCT_CHARACTERISTICS](state, characteristic) {
		const index = state.matchedProduct.characteristics.map(characteristic => characteristic.attributeId).indexOf(characteristic.attributeId)
		state.matchedProduct.characteristics.splice(index, 1, { ...state.matchedProduct.characteristics[index], ...characteristic })
	},
	[SET_MATCHED_PRODUCT](state, matchedProduct) {
		state.hasMatch = true
		state.matchedProduct = { ...state.matchedProduct, ...matchedProduct }
	},
	[SET_PRODUCT_MODERATION_INITIAL_STATE](state, matchedProduct) {
		state.hasMatch = true
		state.matchedProductInitialState = state.matchedProduct = matchedProduct
	},
	[REMOVE_MATCHED_PRODUCT](state) {
		state.hasMatch = false
		state.matchedProductInitialState = state.matchedProduct = new ProductModerationDto({})
	},
	[SET_SWAP_PRODUCT_AND_MATCHED_MODERATION](state) {
		const initialState = state.initialState
		state.productModeration = state.initialState = state.matchedProductInitialState
		state.matchedProduct = state.matchedProductInitialState = initialState
	},
	[SET_RESET_MODERATION](state) {
		state.initialState = new ProductModerationDto({})
		state.productModeration = new ProductModerationDto({})
		state.hasMatch = false
		state.matchedProduct = new ProductModerationDto({})
		state.matchedProductInitialState = new ProductModerationDto({})
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
