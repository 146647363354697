import { SET_SELLER_SEO } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerSeo = new SellerRegistrationBaseModule('sellerSeo', SET_SELLER_SEO)

const state = sellerSeo.getState()
const initialState = sellerSeo.getInitialState()
const getters = sellerSeo.getGetters()
const actions = sellerSeo.getSetters()
const mutations = sellerSeo.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
