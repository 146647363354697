<script>
// Importing Bar class from the vue-chartjs wrapper
import {	Doughnut } from 'vue-chartjs'
// Exporting this so it can be used in other components
export default {
	extends: Doughnut,
	data() {
		return {
			datacollection: {
				labels: ['Red', 'Yellow', 'Blue'],
				datasets: [{
					data: [10, 20, 30],
					backgroundColor: ['#F00', 'yellow', 'blue'],
					hoverBorderWidth: ['0', '0', '0'],
					borderWidth: ['0', '0', '0'],
					borderColor: ['rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)', 'rgba(255, 255, 255, 0)']
				}],
				options: {
					title: {
						display: true,
						text: 'Teste Colombo'
					},
					events: []
				}
			}
		}
	},
	mounted() {
		// renderChart function renders the chart with the datacollection and options object.
		this.renderChart(this.datacollection, this.options)
	}
}
</script>
