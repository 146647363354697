import { Get, Post, Put } from './baseHttpService'
import { Get as SimpleGet } from './simpleBaseHttpService'
import store from 'src/store/store'
import { SET_LOGGED_USER_MUTATION, SET_END_SESSION } from 'constants/MutationTypes'
import { USER_VALID } from 'constants/LocalStorageKeys.js'

const login = ({ username, password, recaptchaResponse }) => {
	return Post('/login', recaptchaResponse, { auth: { username, password } })
}

const getSessionInactiveInterval = () => {
	return SimpleGet('/login/session-inactive-interval')
}

const changePassword = (changePasswordFormDto) => {
	return Put('/login/change-password', changePasswordFormDto)
}

const logout = () => {
	return Get('/logout').then(() => {
		localStorage.removeItem(USER_VALID)
		store.commit(SET_LOGGED_USER_MUTATION, null)
		store.commit(SET_END_SESSION)
	})
}

const restoreUserSession = () => {
	return Post('/login')
}

export {
	login,
	getSessionInactiveInterval,
	logout,
	changePassword,
	restoreUserSession
}
