<template>
	<div class="unsecured">
		<div class="unsecured__bar"></div>
		<div class="unsecured__logo">
			<mkt-brand></mkt-brand>
		</div>

		<div class="unsecured__router">
			<!-- Exemplo Renderização rotas -->
			<sub-route-view transition-type="fade" :is-slide-transition="false" />
		</div>
	</div>
</template>

<script>
	import mktBrand from 'generics/mktBrand/mktBrand'

	export default {
		name: 'unsecured',
		components: {
			mktBrand
		}
	}
</script>

<style src="./unsecured.scss" lang="scss">

</style>
