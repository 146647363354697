<template>
	<transition
		:name="transitionName"
		mode="out-in"
	>
		<router-view></router-view>
	</transition>
</template>

<script>

export default {
	name: 'sub-route-view',
	props: {
		isSlideTransition: { required: false, default: true },
		transitionType: { required: false, default: 'slide-right' }
	},
	data() {
		return {
			transitionName: this.transitionType
		}
	},
	watch: {
		'$route'(to, from) {
			if (this.isSlideTransition) {
				const toDepth = to.path.split('/').length
				const fromDepth = from.path.split('/').length
				this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
			}
		}
	}
}
</script>
<style lang="scss" src="./subRouteView.scss">
</style>
