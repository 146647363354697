<template>
	<div id="mkt-brand">
		<img class="float-left mkt-brand__image" :src="logo" alt="Colombo" :class="imgSizeClass">
		<img class="float-left mkt-brand__image mkt-brand__image--min" :src="logoMin" alt="Colombo">
	</div>
</template>

<script>
	import logo from 'src/assets/img/logoColomboWhite.svg'
	import logoMin from 'src/assets/img/logoMin.png'
	import PropConfig from 'pojos/PropConfig'
	import TypeSize from 'domains/TypeSize'
	
	export default {
		name: 'mkt-brand',
		data() {
			return {
				logo,
				logoMin
			}
		},
		props: {
			tpLogoSize: new PropConfig({
				type: TypeSize,
				valueDefault: () => TypeSize.MEDIUM
			})
		},
		computed: {
			imgSizeClass() {
				switch (this.tpLogoSize) {
				case TypeSize.SMALL:
					return 'small'
				case TypeSize.MEDIUM:
					return 'medium'
				case TypeSize.LARGE:
					return 'large'
				default:
					return ''
				}
			}
		}
	}
</script>

<style src="./mktBrand.scss" lang="scss">

</style>
