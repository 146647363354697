'use strict'

import PromotionDto from './PromotionDto'
import PromotionProductItemSellerDto from './PromotionProductItemSellerDto'

export default class PromotionProductDto extends PromotionDto {
	constructor({ fullNameLock, lockModeration, reprovedReason, sellerName, promotionId, promotionStatus, description, startPromotion, endPromotion, paymentMethod, promotionType, promotionProductItemSellers }) {
		super({ fullNameLock, lockModeration, reprovedReason, sellerName, promotionId, description, startPromotion, endPromotion, paymentMethod, promotionType, promotionStatus })

		this.promotionProductItemSellers = (promotionProductItemSellers || []).map(dto => new PromotionProductItemSellerDto(dto))
	}

	clearData() {
		this.promotionProductItemSellers = []
	}

	removeId() {
		this.promotionId = null

		this.promotionProductItemSellers.forEach(promotionProductItemSeller => {
			promotionProductItemSeller.promotionProductItemSellerId = null
		})
	}
}
