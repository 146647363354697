<template>
	<div class="mkt-attachments">
		<div class="mkt-attachments__wrapper mkt-attachment__upload">
			<label class="mkt-attachment__label" :for="txId" v-tooltip="{ text: 'Anexar' }">
				<i aria-hidden="true" class="icon-clip"></i>
			</label>
			<input
				:id="txId"
				class="mkt-attachment__input"
				type="file"
				multiple
				ref="fileInput"
				:accept="txAcceptType"
				@change="onChangeValue($event)"
				:disabled="blDisabled"
			>
		</div>
		<div class="mkt-attachments__wrapper mkt-attachments__wrapper--pills">
			<div class="mkt-attachments__pill" v-for="(fileKey, index) in fileKey" :key="index">
				<span>
					<b class="file-name">
						<span>
							{{fileKey.file.name}}
						</span>
					</b> ({{getSize(fileKey.file.size)}})
					</span> <i title="Remover" @click="removeFile(index)" class="icon-cancel"></i>
			</div>
		</div>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import { sendErrorNotification } from 'services/notificationService'
import { getPresignedUrl } from 'httpServices/uploadHttpService.js'
import { Put } from 'httpServices/crossOriginHttpService.js'

export default {
	name: 'mkt-file-input',
	props: {
		txAcceptType: new PropConfig({ type: String, required: true }),
		txDefaultType: new PropConfig({ type: String, required: false }),
		blDisabled: new PropConfig({ type: Boolean }),
		// max size in Megabytes
		nrMaxSizeMb: new PropConfig({ type: Number, required: false }),
		txId: new PropConfig({ type: String, required: true }),
		value: new PropConfig({ required: false })
	},
	data() {
		return {
			uploadedFiles: [],
			fileKey: []
		}
	},
	methods: {
		onChangeValue(ev) {
			const newFiles = Array.from(ev.target.files).filter(this.isValidSize)
			if (newFiles.length) {
				this.$emit('mkt-attachments:file-process', newFiles.length)
				newFiles.forEach(this.generateTempFile)
			}
		},
		removeFile(index) {
			this.fileKey.splice(index, 1)
			this.updateModel()
			// É setado o valor de value para uma string vazia pois o input type file guarda aqui a referencia para o path do ultimo arquivo que
			// assim ele tenta evitar que o mesmo arquivo seja feito reupload mesmo que ele tenha sido deletado
			this.$refs.fileInput.value = ''
		},
		removeByKey(key) {
			this.removeFile(this.fileKey.indexOf(this.fileKey.find(file => file.key === key)))
		},
		clearValues() {
			this.fileKey = []
			this.updateModel()
		},
		generateTempFile(file) {
			getPresignedUrl().then(({ data }) => {
				Put(data.fileUploadUrl, file,
					{
						headers: {
							'Content-Type': file.type || this.txDefaultType,
							'Access-Control-Allow-Origin': '*',
							'Access-Control-Allow-Methods': 'PUT'
						}
					}).then(() => this.mapFileToKey(file, data.fileKey, data.fileDownloadUrl))
			})
		},
		mapFileToKey(file, key, urlSource) {
			this.$emit('mkt-attachments:new-file', { file, key, urlSource })
			this.fileKey.push({ file, key, urlSource })
			this.updateModel()
		},
		updateModel() {
			this.$emit('input', this.fileKey.map(fileKey => ({ fileKey: fileKey.key, urlSource: fileKey.urlSource })))
		},
		getSize(size) {
			const kb = size / 1024
			if (kb >= 1) {
				const mb = kb / 1024
				if (mb >= 1) {
					return `${this.roundValue(mb)}MB`
				} else {
					return `${this.roundValue(kb)}kB`
				}
			} else {
				return `${this.roundValue(size)}B`
			}
		},
		roundValue(val) {
			return Math.round(val * 100) / 100
		},
		isValidSize(file) {
			const isValid = !this.nrMaxSizeMb || file.size <= this.nrMaxSizeMb * (1024 * 1024)

			if (!isValid) {
				sendErrorNotification(`Tamanho do arquivo ${file.name} não suportado, o tamanho máximo é: ${this.nrMaxSizeMb}MB`)
			}

			return isValid
		}
	}
}
</script>
<style src="./mktAttachments.scss" lang="scss">
</style>
