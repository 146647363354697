<template>
	<div class="mkt-error">
		<div class="row">
			<div class="col-lg-3 mkt-error__image">
				<img :src="imgError" class="imgError"/>
			</div>
			<div class="col-lg-9">
				<div class="mkt-error__button-wrapper">
					<h2>Ops! Não encontrou o produto que estava procurando?</h2>
					<mkt-button :button="button"></mkt-button>
				</div>
				<mkt-spacing></mkt-spacing>
				<div class="mkt-errror__tips">
					<h3>Dicas para melhorar sua pesquisa:</h3>
					<ul>
						<li>Verifique se você digitou as palavras corretamente.</li>
						<li>Você pode ter sido muito específico em sua busca. Tente utilizar um termo de busca mais amplo.</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import imgError from 'src/assets/img/errorComp.png'
	import TypeSize from 'domains/TypeSize'
	import PropConfig from 'pojos/PropConfig'
	import Button from 'pojos/Button'

	export default {
		name: 'mkt-error',
		props: {
			fnCallback: new PropConfig({ type: Function, required: true })
		},
		data() {
			return {
				imgError,
				tpSize: TypeSize,
				button: new Button({
					label: 'cadastre um novo produto',
					icon: 'icon-add_circle',
					onClick: this.fnCallback
				})
			}
		}
	}
</script>

<style src="./mktError.scss" lang="scss">

</style>
