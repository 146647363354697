import {
	ROUTE_PROMOTION_COLOMBO_DETAIL,
	ROUTE_PROMOTION_COLOMBO_DETAIL_PRODUCT,
	ROUTE_PROMOTION_COLOMBO_DETAIL_CATEGORY
} from 'constants/RouterNames'

const promotionDetailColombo = () => import('components/pages/secured/promotion/promotionColombo/promotionDetail/promotionDetail.vue')
const promotionDetailColomboProduct = () => import('components/pages/secured/promotion/promotionBase/promotionBaseProduct/promotionBaseProduct.vue')
const promotionDetailColomboCategory = () => import('components/pages/secured/promotion/promotionBase/promotionBaseCategory/promotionBaseCategory.vue')

const promotionDetailColomboRoute = {
	path: '/detalhar-promocao-colombo/:promotionId',
	component: promotionDetailColombo,
	name: ROUTE_PROMOTION_COLOMBO_DETAIL.name,
	meta: { requiresAuthorities: ROUTE_PROMOTION_COLOMBO_DETAIL.routerAuth, breadcrumb: { name: 'Detalhamento', active: false } },
	children: [
		{ path: '/detalhar-promocao-colombo/:promotionId/produto', component: promotionDetailColomboProduct, name: ROUTE_PROMOTION_COLOMBO_DETAIL_PRODUCT.name, meta: { breadcrumb: { name: 'Promoção por produto' }, requiresAuthorities: ROUTE_PROMOTION_COLOMBO_DETAIL_PRODUCT.routerAuth } },
		{ path: '/detalhar-promocao-colombo/:promotionId/categoria', component: promotionDetailColomboCategory, name: ROUTE_PROMOTION_COLOMBO_DETAIL_CATEGORY.name, meta: { breadcrumb: { name: 'Promoção por categoria' }, requiresAuthorities: ROUTE_PROMOTION_COLOMBO_DETAIL_CATEGORY.routerAuth } }
	]
}

export default promotionDetailColomboRoute
