<template>
	<transition name="modal">
		<div class="modal">
			<div class="modal__mask">
			</div>
			<div class="modal__wrapper">
				<div class="modal__wrapper__container">
					<div class="modal__wrapper__container__content" :class="modalSizeClass">
						<slot name="content">
						</slot>
					</div>
					<div class="modal__wrapper__container__footer">
						<button class="modal__wrapper__container__footer__button" @click.prevent="callCallback()">
							<mkt-icon tx-font-size="10px" tx-iconColor ="#003C86" :tx-icon="'icon-close'"></mkt-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'
	import { eventEmit, EVENT_MODAL_CLOSE } from 'services/eventBusService'
	import ModalSize from 'domains/ModalSize.js'

	export default {
		name: 'mkt-modal',
		props: {
			fnCallback: new PropConfig({ type: Function, required: false }),
			modalSize: new PropConfig({ type: ModalSize, required: false })
		},
		methods: {
			callCallback() {
				this.$emit('mkt-modal:close')
				eventEmit(EVENT_MODAL_CLOSE)
			}
		},
		computed: {
			modalSizeClass() {
				switch (this.modalSize) {
				case ModalSize.SMALL:
					return 'modal__wrapper__container__content--SMALL'
				case ModalSize.MEDIUM:
					return 'modal__wrapper__container__content--MEDIUM'
				case ModalSize.LARGE:
					return 'modal__wrapper__container__content--LARGE'
				default:
					return ''
				}
			}
		}
	}
</script>

<style src="./mktModal.scss" lang="scss">

</style>
