import { Get, Post, Put } from './baseHttpService'
import { Get as SimpleGet } from './simpleBaseHttpService'

const getLoggedUser = () => {
	return SimpleGet('/user/logged-user').then(response => response.data)
}

const baseUserUrl = '/user-configuration'

const getAllSellers = () => {
	return Get(`${baseUserUrl}/seller`)
}

const getAllProfiles = (sellerId) => {
	return Get(`${baseUserUrl}/profiles/${sellerId}`)
}

const getAllProfilesLoggedSeller = () => {
	return Get(`${baseUserUrl}/profiles`)
}

const saveUserColombo = (userRegistrationDto) => {
	return Post(`${baseUserUrl}/account`, userRegistrationDto)
}

const saveUserSeller = (userRegistrationDto) => {
	return Post(`${baseUserUrl}/account/logged-seller`, userRegistrationDto)
}

const updateUser = (accountId, userUpdateDto) => {
	return Put(`${baseUserUrl}/account/${accountId}`, userUpdateDto)
}

const getUserById = (userId) => {
	return Get(`${baseUserUrl}/account/${userId}`)
}

export { getLoggedUser, getAllSellers, getAllProfiles, saveUserColombo, saveUserSeller, updateUser, getAllProfilesLoggedSeller, getUserById }
