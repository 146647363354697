import {
	ROUTE_CONFIGURATION_SELLER,
	ROUTE_SELLER_REGISTRATION,
	ROUTE_SELLER_LIST,
	ROUTE_SELLER_DETAIL
} from 'constants/RouterNames'

const configurationSeller = () => import('components/pages/secured/configuration/configurationSeller/configurationSeller.vue')
const sellerRegistration = () => import('components/pages/secured/configuration/configurationSeller/seller/registration/sellerRegistration.vue')
const sellerEdit = () => import('components/pages/secured/configuration/configurationSeller/seller/edit/sellerEdit.vue')
const sellerList = () => import('src/components/pages/secured/configuration/configurationSeller/sellerList/sellerList')

const sellerConfiguration = {
	path: '/configuracao-seller',
	redirect: '/listagem-seller',
	meta: { breadcrumb: { name: 'Seller' }, requiresAuthorities: ROUTE_CONFIGURATION_SELLER.routerAuth },
	component: configurationSeller,
	name: ROUTE_CONFIGURATION_SELLER.name,
	children: [
		{
			path: '/listagem-seller',
			component: sellerList,
			name: ROUTE_SELLER_LIST.name,
			meta: {
				breadcrumb: { name: 'Listagem' },
				requiresAuthorities: ROUTE_SELLER_LIST.routerAuth
			}
		},

		{
			path: '/cadastro-seller',
			component: sellerRegistration,
			name: ROUTE_SELLER_REGISTRATION.name,
			meta: {
				breadcrumb: { name: 'Cadastro' },
				requiresAuthorities: ROUTE_SELLER_REGISTRATION.routerAuth
			}
		},

		{
			path: '/edicao-seller/:sellerId',
			component: sellerEdit,
			props: true,
			name: ROUTE_SELLER_DETAIL.name,
			meta: {
				breadcrumb: { name: 'Edição' },
				requiresAuthorities: ROUTE_SELLER_DETAIL.routerAuth
			}
		}
	]
}

export default sellerConfiguration
