import {
	ROUTE_CONFIGURATION_USER_COLOMBO
	, ROUTE_USER_COLOMBO_LIST
	, ROUTE_USER_REGISTRATION_COLOMBO
	, ROUTE_USER_COLOMBO_UPDATE
} from 'constants/RouterNames'

const configurationUser = () => import('components/pages/secured/configuration/configurationUser/configurationUser.vue')
const userListColombo = () => import('components/pages/secured/configuration/configurationUser/userColombo/userColomboList/userList')
const userRegistrationColombo = () => import('components/pages/secured/configuration/configurationUser/userColombo/userColomboRegistration')
const userUpdateColombo = () => import('components/pages/secured/configuration/configurationUser/userColombo/userColomboUpdate/userColomboUpdate')

const userConfiguration = {
	path: '/usuarios-colombo',
	redirect: '/listagem-usuarios',
	meta: { breadcrumb: { name: 'Usuários' }, requiresAuthorities: ROUTE_CONFIGURATION_USER_COLOMBO.routerAuth },
	component: configurationUser,
	name: ROUTE_CONFIGURATION_USER_COLOMBO.name,
	children: [
		{ path: '/cadastro-usuarios', component: userRegistrationColombo, name: ROUTE_USER_REGISTRATION_COLOMBO.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_USER_REGISTRATION_COLOMBO.routerAuth } },
		{ path: '/listagem-usuarios', component: userListColombo, name: ROUTE_USER_COLOMBO_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_USER_COLOMBO_LIST.routerAuth } },
		{ path: '/edicao-usuarios/:userId', props: true, component: userUpdateColombo, name: ROUTE_USER_COLOMBO_UPDATE.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_USER_COLOMBO_UPDATE.routerAuth } }
	]
}

export default userConfiguration
