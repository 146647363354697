import {
	ROUTE_ORDER_SELLER_BULK_ACTIONS,
	ROUTE_ORDER_SELLER_BULK_ACTIONS_IMPORT,
	ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS,
	ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS_DETAILS
} from 'constants/RouterNames'

const bulkActions = () => import('components/pages/secured/order/bulkActions/bulkActions')
const orderBulkActions = () => import('components/pages/secured/order/bulkActions/orderBulkActions/orderBulkActions.vue')
const bulkActionsLogs = () => import('components/pages/secured/order/bulkActions/bulkActionsLogs/bulkActionsLogs.vue')
const bulkActionsLogsDetails = () => import('components/pages/secured/order/bulkActions/bulkActionsLogs/bulkActionsLogsDetails/bulkActionsLogsDetails.vue')

const orderBulkActionRoute = {
	path: '/acoes-em-massa-pedidos',
	redirect: '/acoes-em-massa-pedidos-importar',
	component: bulkActions,
	name: ROUTE_ORDER_SELLER_BULK_ACTIONS.name,
	meta: { breadcrumb: { name: 'Alteração em Massa' }, requiresAuthorities: ROUTE_ORDER_SELLER_BULK_ACTIONS.routerAuth },
	children: [
		{ path: '/acoes-em-massa-pedidos-importar', component: orderBulkActions, name: ROUTE_ORDER_SELLER_BULK_ACTIONS_IMPORT.name, requiresAuthorities: ROUTE_ORDER_SELLER_BULK_ACTIONS_IMPORT.routerAuth },
		{ path: '/acoes-em-massa-pedidos-logs', component: bulkActionsLogs, name: ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS.name, requiresAuthorities: ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS.routerAuth, meta: { breadcrumb: { name: 'Logs' } } },
		{ path: '/acoes-em-massa-pedidos-logs/:id', props: true, component: bulkActionsLogsDetails, name: ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS_DETAILS.name, requiresAuthorities: ROUTE_ORDER_SELLER_BULK_ACTIONS_LOGS_DETAILS.routerAuth, meta: { breadcrumb: { name: 'Logs' } } }
	]
}

export default orderBulkActionRoute
