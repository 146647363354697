import {
	ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_COLOMBO,
	ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_COLOMBO
} from 'constants/RouterNames'

const financialAnticipationScheduled = () => import('components/pages/secured/financial/financialAnticipationScheduled/financialAnticipationScheduledSeller/financialAnticipationScheduledSeller')
const financialAnticipationScheduledList = () => import('components/pages/secured/financial/financialAnticipationScheduled/financialAnticipationScheduledColombo/financialAnticipationScheduledColomboList/financialAnticipationScheduledColomboList')

const financialAnticipationScheduledColombo = {
	path: '/antecipacao-acompanhamento-colombo',
	redirect: '/listagem-antecipacao-acompanhamento-colombo',
	meta: { requiresAuthorities: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_COLOMBO.routerAuth },
	component: financialAnticipationScheduled,
	name: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_COLOMBO.name,
	children: [
		{ path: '/listagem-antecipacao-acompanhamento-colombo', component: financialAnticipationScheduledList, name: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_COLOMBO.name, meta: { breadcrumb: { name: 'Acompanhamento de antecipação' }, requiresAuthorities: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_COLOMBO.routerAuth } }
	]
}

export default financialAnticipationScheduledColombo
