// Adicionar qualquer mutation type aqui
export const SET_SESSION_TIMER = 'SET_SESSION_TIMER'
export const SET_END_SESSION = 'SET_END_SESSION'
export const SET_CURRENT_TIMEOUT = 'SET_CURRENT_TIMEOUT'
export const SET_ON_LOGOUT = 'SET_ON_LOGOUT'
export const SET_LOGGED_USER_MUTATION = 'SET_LOGGED_USER_MUTATION'
export const SET_STEPS_MUTATION = 'SET_STEPS_MUTATION'
export const SET_STEP_MUTATION = 'SET_STEP_MUTATION'
export const SET_ROUTE_TITLE_MUTATION = 'SET_ROUTE_TITLE_MUTATION'
export const SET_ROUTE_MUTATION = 'SET_ROUTE_MUTATION'
export const SET_LOADER_STATE_MUTATION = 'SET_LOADER_STATE_MUTATION'
export const SET_PRODUCT_MODERATION = 'SET_PRODUCT_MODERATION'
export const SET_INITIAL_STATE_PRODUCT_MODERATION = 'SET_INITIAL_STATE_PRODUCT_MODERATION'
export const SET_MATCHED_PRODUCT = 'SET_MATCHED_PRODUCT'
export const SET_PRODUCT_CHARACTERISTICS = 'SET_PRODUCT_CHARACTERISTICS'
export const SET_MATCHED_PRODUCT_CHARACTERISTICS = 'SET_MATCHED_PRODUCT_CHARACTERISTICS'
export const SET_SWAP_PRODUCT_AND_MATCHED_MODERATION = 'SET_SWAP_PRODUCT_AND_MATCHED_MODERATION'
export const SET_RESET_MODERATION = 'SET_RESET_MODERATION'
export const SET_ONE_MATCHED_PRODUCT_CHARACTERISTICS = 'SET_ONE_MATCHED_PRODUCT_CHARACTERISTICS'
export const SET_PRODUCT_MODERATION_INITIAL_STATE = 'SET_PRODUCT_MODERATION_INITIAL_STATE'
export const REMOVE_MATCHED_PRODUCT = 'REMOVE_MATCHED_PRODUCT'
export const SET_PRODUCT_COLOMBO = 'SET_PRODUCT_COLOMBO'
export const SET_INITIAL_STATE_PRODUCT_COLOMBO = 'SET_INITIAL_STATE_PRODUCT_COLOMBO'
export const SET_PRODUCT_COLOMBO_CHARACTERISTIC = 'SET_PRODUCT_COLOMBO_CHARACTERISTIC'
export const SET_PRODUCT_COLOMBO_VARIATION = 'SET_PRODUCT_COLOMBO_VARIATION'
export const SET_ROUTE_ICON_MUTATION = 'SET_ROUTE_ICON_MUTATION'
export const SET_ACCOUNT_PASSWORD_RECOVERY = 'SET_ACCOUNT_PASSWORD_RECOVERY'
export const SET_REDIRECT_TO_LOGIN_MUTATION = 'SET_REDIRECT_TO_LOGIN_MUTATION'

// Seller
export const SET_SELLER_DETAILS = 'SET_SELLER_DETAILS'
export const SET_SELLER_ADDRESS = 'SET_SELLER_ADDRESS'
export const SET_SELLER_ABOUT_COMPANY = 'SET_SELLER_ABOUT_COMPANY'
export const SET_SELLER_BANK_DETAILS = 'SET_SELLER_BANK_DETAILS'
export const SET_SELLER_CONFIGURATION = 'SET_SELLER_CONFIGURATION'
export const SET_SELLER_INTEGRATION = 'SET_SELLER_INTEGRATION'
export const SET_SELLER_COMMISSION = 'SET_SELLER_COMMISSION'
export const SET_CLEAN_SELLER_REGISTRATION = 'SET_CLEAN_SELLER_REGISTRATION'
export const SET_SELLER_REGISTRATION = 'SET_SELLER_REGISTRATION'
export const SET_SELLER_SEO = 'SET_SELLER_SEO'
export const SET_SELLER_SETTINGS = 'SET_SELLER_SETTINGS'
export const SET_SELLER_ORDER_REASON = 'SET_SELLER_ORDER_REASON'
export const SET_SELLER_REGISTRATION_SELLER_ADMIN = 'SET_SELLER_REGISTRATION_SELLER_ADMIN'
export const SET_SELLER_PAYMENT_TICKET_SETTINGS = 'SET_SELLER_PAYMENT_TICKET_SETTINGS'
export const SET_SELLER_PAYMENT_SETTINGS = 'SET_SELLER_PAYMENT_SETTINGS'

export const SET_PROMOTION_ID = 'SET_PROMOTION_ID'
export const SET_PROMOTION_INITIAL_STATE = 'SET_PROMOTION_INITIAL_STATE'
export const SET_PROMOTION_BASE_FIELDS = 'SET_PROMOTION_BASE_FIELDS'
export const SET_PROMOTION_TYPE = 'SET_PROMOTION_TYPE'
export const ADD_PROMOTION_PRODUCT_ITEM_SELLER = 'ADD_PROMOTION_PRODUCT_ITEM_SELLER'
export const SET_PROMOTION_PRODUCT_SET_DISCOUNT_FIELDS = 'SET_PROMOTION_PRODUCT_SET_DISCOUNT_FIELDS'
export const SET_PROMOTION_PRODUCT_SET_SALE_LIMIT = 'SET_PROMOTION_PRODUCT_SET_SALE_LIMIT'
export const REMOVE_PROMOTION_PRODUCT = 'REMOVE_PROMOTION_PRODUCT'

// Promotion
export const ADD_PROMOTION_CATEGORY = 'ADD_PROMOTION_CATEGORY'
export const ADD_PROMOTION_CATEGORY_EXCLUDED_PRODUCT = 'ADD_PROMOTION_CATEGORY_EXCLUDED_PRODUCT'
export const SET_PROMOTION_CATEGORY_FIELD = 'SET_PROMOTION_CATEGORY_FIELD'
export const REMOVE_PROMOTION_CATEGORY = 'REMOVE_PROMOTION_CATEGORY'
export const REMOVE_PROMOTION_CATEGORY_EXCLUDED_PRODUCT = 'REMOVE_PROMOTION_CATEGORY_EXCLUDED_PRODUCT'
export const CLEAR_SPECIFIC_PROMOTION_DATA = 'CLEAR_SPECIFIC_PROMOTION_DATA'
export const SET_PROMOTION_MARKED_FIELDS = 'SET_PROMOTION_MARKED_FIELDS'
export const SET_EDITION_TYPE_STATE = 'SET_EDITION_TYPE_STATE'
export const SET_PROMOTION_STATUS = 'SET_PROMOTION_STATUS'
export const COPY_PROMOTION_INITIAL_STATE = 'COPY_PROMOTION_INITIAL_STATE'
export const ADD_REBATE_CATEGORY = 'ADD_REBATE_CATEGORY'
export const ADD_REBATE_CATEGORY_EXCLUDED_PRODUCT = 'ADD_REBATE_CATEGORY_EXCLUDED_PRODUCT'
export const REMOVE_REBATE_CATEGORY = 'REMOVE_REBATE_CATEGORY'
export const REMOVE_REBATE_CATEGORY_EXCLUDED_PRODUCT = 'REMOVE_REBATE_CATEGORY_EXCLUDED_PRODUCT'
export const SET_REBATE_BASE_FIELDS = 'SET_REBATE_BASE_FIELDS'
export const CLEAR_SPECIFIC_REBATE_DATA = 'CLEAR_SPECIFIC_REBATE_DATA'
export const SET_REBATE_CATEGORY_FIELD = 'SET_REBATE_CATEGORY_FIELD'
export const SET_REBATE_PRODUCT_FIELD = 'SET_REBATE_PRODUCT_FIELD'
export const SET_REBATE_INITIAL_STATE = 'SET_REBATE_INITIAL_STATE'
export const ADD_REBATE_PRODUCT_ITEM_SELLER = 'ADD_REBATE_PRODUCT_ITEM_SELLER'
export const SET_REBATE_PRODUCT_SET_COMISSION_FIELD = 'SET_REBATE_PRODUCT_SET_COMISSION_FIELD'
export const SET_REBATE_PRODUCT_SET_SALE_LIMIT = 'SET_REBATE_PRODUCT_SET_SALE_LIMIT'
export const SET_REBATE_STATUS = 'SET_REBATE_STATUS'
export const SET_REBATE_TYPE = 'SET_REBATE_TYPE'
export const SET_REBATE_EDITION_TYPE_STATE = 'SET_REBATE_EDITION_TYPE_STATE'
export const REMOVE_REBATE_PRODUCT = 'REMOVE_REBATE_PRODUCT'

// Notifications
export const SET_TOTAL_NOTIFICATIONS_HEADER = 'SET_TOTAL_NOTIFICATIONS_HEADER'
export const SET_NOTIFICATIONS_HEADER = 'SET_NOTIFICATIONS_HEADER'
