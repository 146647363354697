import {
	ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE,
	ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE_LIST
} from 'constants/RouterNames'

const financialAccountReceivable = () => import('components/pages/secured/financial/financialAccountReceivable/financialAccountReceivable')
const financialAccountList = () => import('components/pages/secured/financial/financialAccountReceivable/financialAccountReceivableList/financialAccountReceivableList')

const financialAccountReceivableRoute = {
	path: '/relatorio-contas-receber',
	redirect: '/listagem-relatorio-contas-receber',
	meta: { requiresAuthorities: ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE.routerAuth },
	component: financialAccountReceivable,
	name: ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE.name,
	children: [
		{ path: '/listagem-relatorio-contas-receber', component: financialAccountList, name: ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE_LIST.name, meta: { breadcrumb: { name: 'Contas a Receber' }, requiresAuthorities: ROUTE_FINANCIAL_ACCOUNT_RECEIVABLE_LIST.routerAuth } }
	]
}

export default financialAccountReceivableRoute
