import Enum from './Domain'
import OccurrenceType from './OccurrenceType'
class OccurrenceTypeColombo extends Enum {
}

const enumValues = OccurrenceType.enumValues.reduce((acc, cur) => {
	acc[cur.name] = cur
	return acc
}, {})

OccurrenceTypeColombo.initEnum({
	...enumValues,
	INTEGRATION_PROBLEM: {
		message: 'Problema de integração',
		icon: 'icon-broken_chain',
		color: '#ABA9A9'
	},
	INTEGRATION_PENDING: {
		message: 'Pendente de integração',
		icon: 'icon-pause_circle_filled',
		color: '#6E6E6E'
	},
	DESCRIPTION_ADJUSTMENT: {
		message: 'Ajustar descrição',
		icon: 'icon-cut_content',
		color: '#ABA9A9'
	}
})

export default OccurrenceTypeColombo
