import {
	ROUTE_COLOMBO_SAC,
	ROUTE_COLOMBO_SAC_LIST,
	ROUTE_COLOMBO_SAC_DETAIL
} from 'constants/RouterNames'

const sacColombo = () => import('components/pages/secured/sac/sacColombo/sacColombo')
const sacColomboList = () => import('components/pages/secured/sac/sacColombo/sacColomboList/sacColomboList')
const sacColomboDetail = () => import('components/pages/secured/sac/sacColombo/sacColomboDetail/sacColomboDetail')

const sacColomboRoute = {
	path: '/sac-colombo',
	redirect: '/listagem-atendimento-colombo',
	component: sacColombo,
	name: ROUTE_COLOMBO_SAC.name,
	meta: { breadcrumb: { name: 'Atendimento' }, requiresAuthorities: ROUTE_COLOMBO_SAC.routerAuth },
	children: [
		{ path: '/listagem-atendimento-colombo', component: sacColomboList, name: ROUTE_COLOMBO_SAC_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_COLOMBO_SAC_LIST.routerAuth } },
		{ path: '/detalhe-atendimento-colombo/:sacId', props: true, component: sacColomboDetail, name: ROUTE_COLOMBO_SAC_DETAIL.name, meta: { breadcrumb: { name: 'Detalhe' }, requiresAuthorities: ROUTE_COLOMBO_SAC_DETAIL.routerAuth } }
	]
}

export default sacColomboRoute
