import { SET_SELLER_ADDRESS } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerAddress = new SellerRegistrationBaseModule('sellerAddress', SET_SELLER_ADDRESS)

const state = sellerAddress.getState()
const initialState = sellerAddress.getInitialState()
const getters = sellerAddress.getGetters()
const actions = sellerAddress.getSetters()
const mutations = sellerAddress.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
