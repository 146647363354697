<template>
	<div class="mkt-table__header">
		<div class="mkt-table__header__results" v-if="!blHideDropdown">
			<div class="mkt-table__header__results__text">
				Resultados por página
			</div>
			<mkt-select
				class="mkt-table__header__results__select-items-page"
				v-model="modelSelectItemsPerPage"
				:ltOptions="[20, 40, 60, 100]"
				@mkt-select:selected="events.selectItemsPerPage"
			></mkt-select>
		</div>
		<div class="mkt-table__header__selected" v-else>
			<div class="mkt-table__header__selected-results">
				<b>{{ nrSelectedItems }}</b> {{txSelectedItemTypeName}} selecionados
			</div>
			<div class="mkt-table__header__selected__button-pair">
				<mkt-button class="mkt-table-button" :button="buttons.buttonSelectAll"></mkt-button>
				<mkt-button class="mkt-table-button" :button="buttons.buttonCancelSelect"></mkt-button>
			</div>
		</div>
		<div class="mkt-table__header__slot">
			<slot name="mkt-table-header-slot">
			</slot>
		</div>
	</div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import Button from 'pojos/Button'

export default {
	name: 'mkt-table-header',
	props: {
		blHideDropdown: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		itemsPerPage: new PropConfig({ required: true }),
		nrSelectedItems: new PropConfig({ type: Number }),
		txSelectedItemTypeName: new PropConfig({ type: String, required: false, valueDefault: 'produtos' })
	},
	data() {
		return {
			modelSelectItemsPerPage: this.itemsPerPage,
			buttons: {
				buttonSelectAll: new Button({
					label: 'Selecionar todas páginas',
					className: 'md-white',
					size: 'xl',
					onClick: () => this.events.selectAll()
				}),
				buttonCancelSelect: new Button({
					label: 'Cancelar Seleção',
					size: 'lg',
					className: 'md-dense md-hoverless md-primary',
					onClick: () => this.events.clearSelectedItems()
				})
			},
			events: {
				selectItemsPerPage: (itemsPerPage) => this.$emit('mkt-table-header:select-items-per-page', itemsPerPage),
				selectAll: () => this.$emit('mkt-table-header:select-all'),
				clearSelectedItems: () => this.$emit('mkt-table-header:clear-selected-items')
			}
		}
	}
}
</script>

<style src="./mktTableHeader.scss" lang="scss">
</style>
