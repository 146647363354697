import VueRouter from 'vue-router'
import Vue from 'vue'
import routes from 'src/router/routes/'

import securedGuard from 'src/router/guards/securedGuard'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes,
	/**
	 * This method controls the scroll behavior when going into a new route, it uses a saved position if
	 * there is any from the history browser, otherwise if the route that it is going to isnt a child of the previous route
	 * it resets the scroll to the top of the page as if the page were reloaded, otherwise it keeps the scroll in position
	 * @param {Destiny route} to
	 * @param {Origin route} from
	 * @param {Position saved by the browser when using navigation history} savedPosition
	 */
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else if (!to.matched.map(route => route.path).includes(from.path)) {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach(securedGuard)

export default router
