import {
	ROUTE_SELLER_SAC,
	ROUTE_SELLER_SAC_LIST,
	ROUTE_SELLER_SAC_DETAIL
} from 'constants/RouterNames'

const sacSeller = () => import('components/pages/secured/sac/sacSeller/sacSeller')
const sacSellerList = () => import('components/pages/secured/sac/sacSeller/sacSellerList/sacSellerList')
const sacSellerDetail = () => import('components/pages/secured/sac/sacSeller/sacSellerDetail/sacSellerDetail')

const sacSellerRoute = {
	path: '/sac-seller',
	redirect: '/listagem-atendimento',
	component: sacSeller,
	name: ROUTE_SELLER_SAC.name,
	meta: { breadcrumb: { name: 'Atendimento' }, requiresAuthorities: ROUTE_SELLER_SAC.routerAuth },
	children: [
		{ path: '/listagem-atendimento', component: sacSellerList, name: ROUTE_SELLER_SAC_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_SELLER_SAC_LIST.routerAuth } },
		{ path: '/detalhe-atendimento/:sacId', props: true, component: sacSellerDetail, name: ROUTE_SELLER_SAC_DETAIL.name, meta: { breadcrumb: { name: 'Detalhe' }, requiresAuthorities: ROUTE_SELLER_SAC_DETAIL.routerAuth } }
	]
}

export default sacSellerRoute
