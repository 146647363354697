export default {
	props: {
		txPlaceholder: { type: String },
		txLabel: { type: String, required: false },
		txId: { type: String, required: false },
		txHighligthedLabel: { type: String, required: false },
		blRequired: { type: Boolean, default: false },
		blPreventDefault: { type: Boolean, default: false },
		blDisabled: { type: Boolean, default: false },
		blReadOnly: { type: Boolean, required: false, default: false },
		blValidateOnMount: { type: Boolean, required: false, default: false },
		blValidateOnSet: { type: Boolean, required: false, default: false },
		nrMinlength: { type: Number, required: false },
		nrMaxlength: { type: Number, default: 100 },
		fnValidate: { type: Function },
		txFieldClass: { type: String, required: false, default: '' },
		isIconError: { type: Boolean, required: false, default: false },
		value: { required: false }
	},
	data() {
		return {
			hasErrors: false,
			formValidationError: ''
		}
	},
	methods: {
		onBlur() {
			this.$emit('blur')
			this.validateField()
		},
		validateField() {
			if (this.blRequired || this.nrMinlength || this.fnValidate) {
				this.$nextTick(() => {
					const hasValidateError = this.fnValidate && this.fnValidate(this.value)
					this.hasErrors = (this.blRequired && !this.value) || (!!this.nrMinlength && this.value.length < this.nrMinlength) || !!hasValidateError
					if (hasValidateError) {
						this.formValidationError = hasValidateError
					}
				})
			} else {
				this.hasErrors = false
			}
		}
	},
	mounted() {
		this.blValidateOnMount && this.validateField()
	},
	computed: {
		fieldModel: {
			get() {
				return this.value
			},
			set(newVal) {
				this.blValidateOnSet && this.hasErrors && this.validateField()
				this.$emit('input', newVal)
			}
		},
		txErrorMessage() {
			if (this.value && this.value.length < this.nrMinlength) {
				return `O campo deve possuir no mínimo ${this.nrMinlength} caracteres`
			} else if (this.hasErrors) {
				return this.formValidationError
			}
		}
	},
	watch: {
		blRequired(val) {
			!val && this.hasErrors && this.validateField()
		}
	}
}
