
import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Profile = new AuthorityBuilder({
	accessGroup: 'PROFILE',
	accessType: ['ACCESS', 'WRITE']
})

export default [
	Profile
]
