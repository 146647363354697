import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const ConfigurationAttributes = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_ATTRIBUTES',
	accessType: ['EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'ACCESS']
})

const ConfigurationCategories = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_CATEGORIES',
	accessType: ['READ', 'WRITE', 'ACCESS']
})

const ConfigurationGeneral = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_GENERAL',
	accessType: ['READ', 'WRITE', 'ACCESS']
})

const ConfigurationSellers = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_SELLERS',
	accessType: [
		'ACCESS',
		'LIST_READ',
		'LIST_EXPORT_READ',
		'ADD_WRITE',
		'EDIT_ABOUT_STORE_READ',
		'EDIT_ABOUT_STORE_WRITE',
		'EDIT_CANCELLATION_REASONS_READ',
		'EDIT_CANCELLATION_REASONS_WRITE',
		'EDIT_COMMISSION_READ',
		'EDIT_COMMISSION_WRITE',
		'EDIT_CONFIGURATION_READ',
		'EDIT_CONFIGURATION_WRITE',
		'EDIT_DATA_READ',
		'EDIT_DATA_WRITE',
		'EDIT_FINANCIAL_READ',
		'EDIT_FINANCIAL_WRITE',
		'EDIT_INTEGRATION_READ',
		'EDIT_INTEGRATION_WRITE',
		'EDIT_SEO_READ',
		'EDIT_SEO_WRITE'
	]
})

const ConfigurationUsers = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_USERS',
	accessType: ['ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'ACCESS']
})

const ConfigurationCancellationReason = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_CANCELLATION_REASON',
	accessType: ['ACCESS', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'ADD_WRITE']
})

const ConfigurationFinancial = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FINANCIAL',
	accessType: [
		'ACCESS',
		'CAST_EDIT_WRITE',
		'CAST_EDIT_READ',
		'CAST_ADD_WRITE',
		'CAST_LIST_READ',
		'CAST_LIST_WRITE',
		'EXTRACT_ACCESS',
		'COMMISSION_ACCESS',
		'EXTRACT_LIST_EXPORT_READ',
		'CAST_ACCESS'
	]
})

const ConfigurationMdr = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_MDR',
	accessType: ['ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'ACCESS']
})

const ConfigurationFee = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FEE',
	accessType: ['ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'ACCESS']
})

const ConfigurationAccessProfile = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_ACCESS_PROFILE',
	accessType: ['ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'ACCESS']
})

const ConfigurationReprovedReason = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_REPROVED_REASON',
	accessType: ['ACCESS', 'ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ']
})

const ConfigurationFreight = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FREIGHT',
	accessType: ['ACCESS', 'LIST_READ']
})

const ConfigurationAnticipationScheduled = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_ANTICIPATION_SCHEDULED',
	accessType: ['ACCESS', 'LIST_READ']
})

const ConfigurationPaymentDaily = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FINANCIAL_SPLIT_DIARY_SETTINGS',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EDIT']
})

const ConfigurationFinancialReport = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

const ConfigurationFinancialManualEntry = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_ENTRY',
	accessType: ['ACCESS', 'LIST_READ', 'EDIT_READ', 'ADD_WRITE']
})

const Configuration = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION',
	accessType: ['ACCESS']
})

const ConfigurationDeleteData = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_DELETE_CUSTOMER',
	accessType: ['ACCESS', 'EDIT_READ', 'EDIT_WRITE']
})

export default [
	Configuration,
	ConfigurationAttributes,
	ConfigurationCategories,
	ConfigurationGeneral,
	ConfigurationSellers,
	ConfigurationUsers,
	ConfigurationCancellationReason,
	ConfigurationFinancial,
	ConfigurationMdr,
	ConfigurationFee,
	ConfigurationAccessProfile,
	ConfigurationReprovedReason,
	ConfigurationFreight,
	ConfigurationAnticipationScheduled,
	ConfigurationPaymentDaily,
	ConfigurationFinancialReport,
	ConfigurationFinancialManualEntry,
	ConfigurationDeleteData
]
