/**
 * Not using interceptors
 *
 */

import axios from 'axios'
import configHttp from 'constants/HttpConstants'

const axiosInstance = axios.create(configHttp)

const Get = (url, params, config) => {
	return axiosInstance.get(url, addParams(params, config))
}

const Delete = (url, params) => {
	return axiosInstance.delete(url, { params })
}

const Post = (url, data, headers) => {
	return axiosInstance.post(url, data, headers)
}

const Put = (url, data) => {
	return axiosInstance.put(url, data)
}

const addParams = (params, config) => {
	return Object.assign({}, { params }, config)
}

export { Post, Get, Put, Delete }
