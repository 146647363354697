import {
	ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_SELLER,
	ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_SELLER
} from 'constants/RouterNames'

const financialAnticipationScheduled = () => import('components/pages/secured/financial/financialAnticipationScheduled/financialAnticipationScheduledSeller/financialAnticipationScheduledSeller')
const financialAnticipationScheduledList = () => import('components/pages/secured/financial/financialAnticipationScheduled/financialAnticipationScheduledSeller/financialAnticipationScheduledSellerList/financialAnticipationScheduledSellerList')

const financialAnticipationScheduledSeller = {
	path: '/antecipacao-acompanhamento',
	redirect: '/listagem-antecipacao-acompanhamento',
	meta: { requiresAuthorities: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_SELLER.routerAuth },
	component: financialAnticipationScheduled,
	name: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_SELLER.name,
	children: [
		{ path: '/listagem-antecipacao-acompanhamento', component: financialAnticipationScheduledList, name: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_SELLER.name, meta: { breadcrumb: { name: 'Acompanhamento de antecipação' }, requiresAuthorities: ROUTE_FINANCIAL_ANTICIPATION_SCHEDULED_LIST_SELLER.routerAuth } }
	]
}

export default financialAnticipationScheduledSeller
