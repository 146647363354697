<template>
	<div class="mkt-app-main">
		<mkt-notification tx-group="global"></mkt-notification>
		<div>
			<transition
				name="fade"
				mode="out-in"
				@beforeLeave="beforeLeave"
				@enter="enter"
				@afterEnter="afterEnter"
			>
				<router-view/>
			</transition>
		</div>
	</div>
</template>

<script>
import MktNotification from 'components/generics/mktNotification/mktNotification.vue'
import routerTransitionMixin from 'src/router/routerTransitionMixin.js'

export default {
	name: 'app',
	mixins: [routerTransitionMixin],
	components: {
		MktNotification
	}
}
</script>
