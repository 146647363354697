<template>
	<div class="mkt-file-input">
		<div class="mkt-file-input__wrapper">
			<label class="mkt-file-input__upload-zone" :class="{ 'mkt-file-input__upload-zone--disabled': blDisabled }" :for="txId">
				<slot name="upload-icon">
					<i aria-hidden="true" class="mkt-file-input__upload-zone__icon icon-upload-button"></i>
				</slot>
			</label>
			<md-field :class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }">
				<mkt-label v-if="txLabel" class="mkt-field__label" :txLabel="txLabel" :bl-required="blRequired"></mkt-label>
				<md-input
					:placeholder="txPlaceholder"
					:disabled="blDisabled || blReadOnly"
					:required="blRequired"
					:value="componentModel && componentModel.name"
				></md-input>
				<input
					:id="txId"
					class="mkt-file-input__input"
					type="file"
					ref="fileInput"
					:accept="txAcceptType"
					@change="onChangeValue($event)"
					:disabled="blDisabled"
				>
				<span class="md-error" v-if="hasErrors">{{formValidationError || 'Campo obrigatório'}}</span>
			</md-field>
		</div>
		<div class="mkt-file-input__subtitle" v-if="txSubtitle">{{txSubtitle}}</div>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import { sendErrorNotification } from 'services/notificationService'
import { getPresignedUrl, postValidateFile } from 'httpServices/fileHttpService.js'
import { Put } from 'httpServices/crossOriginHttpService.js'

export default {
	name: 'mkt-file-input',
	props: {
		txLabel: new PropConfig({ type: String }),
		txImageSource: new PropConfig({ type: String, valueDefault: 'path' }),
		txSubtitle: new PropConfig({ type: String }),
		txPlaceholder: new PropConfig({ type: String }),
		txAcceptType: new PropConfig({ type: String, required: true }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		blDisabled: new PropConfig({ type: Boolean, valueDefault: false }),
		blSignedKey: new PropConfig({ type: Boolean, valueDefault: false }),
		// max size in Megabytes
		nrMaxSizeMb: new PropConfig({ type: Number, required: false }),
		txId: new PropConfig({ type: String, required: true }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		fileType: new PropConfig({ type: String, required: false }),
		value: new PropConfig({ required: false })
	},
	data: () => ({
		hasErrors: false,
		formValidationError: ''
	}),
	methods: {
		onChangeValue(ev) {
			ev.preventDefault()
			this.componentModel = null

			const file = ev.target.files[0]
			this.$refs.fileInput.value = ''
			if (file) {
				if (this.isValidSize(file)) {
					this.handleFile(file)
				} else {
					sendErrorNotification('Tamanho do arquivo não suportado')
				}
			}
		},
		handleFile(file) {
			if (this.blSignedKey) {
				this.generateTempFile(file)
					.then(({ fileDownloadUrl, fileKey }) => {
						this.componentModel = { name: file.name, [this.txImageSource]: fileDownloadUrl, tempFileKey: fileKey }
					}).catch(() => {
						this.onError()
					})
			} else {
				this.componentModel = file
			}
		},
		generateTempFile(file) {
			return new Promise((resolve, reject) => {
				getPresignedUrl()
					.then(({ data }) => {
						return Put(data.fileUploadUrl, file,
							{
								headers: {
									'Content-Type': file.type,
									'Access-Control-Allow-Origin': '*',
									'Access-Control-Allow-Methods': 'PUT'
								}
							})
							.then(() => {
								return postValidateFile(data.fileDownloadUrl, this.fileType)
									.then(() => resolve(data))
									.catch(err => reject(err))
							})
							.catch(err => reject(err))
					})
					.catch(err => reject(err))
			})
		},
		onError() {
			this.$refs.fileInput.value = ''
		},
		isValidSize(file) {
			return !this.nrMaxSizeMb || file.size <= this.nrMaxSizeMb * 1000000
		}
	},
	computed: {
		componentModel: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
<style src="./mktFileInput.scss" lang="scss">
</style>
