import {
	ROUTE_FINANCIAL,
	ROUTE_FINANCIAL_STATEMENT_COLOMBO,
	ROUTE_FINANCIAL_STATEMENT_SELLER,
	ROUTE_FINANCIAL_COMMISSION
} from 'constants/RouterNames'

import financialEntry from './financialEntry'
import financialAccountReceivable from './financialAccountReceivable'
import financialAnticipationSeller from './financialAnticipation'
import financialAnticipationScheduledSeller from './financialAnticipationScheduledSeller'
import financialAnticipationScheduledColombo from './financialAnticipationScheduledColombo'
import financialReportColombo from './financialReport/financialReportColombo'
import financialReportSeller from './financialReport/financialReportSeller'
import financialEntrySplitRoute from './financialEntrySplit'

const financial = () => import('components/pages/secured/financial/financial')
const financialStatement = () => import('components/pages/secured/financial/financialStatement/financialStatement')
const financialCommission = () => import('components/pages/secured/financial/financialCommission/financialCommission.vue')

const financialRoute = {
	path: '/financial',
	component: financial,
	name: ROUTE_FINANCIAL.name,
	breadcrumb: { name: 'Financeiro', active: false },
	children: [
		financialEntry,
		financialAccountReceivable,
		financialAnticipationSeller,
		financialAnticipationScheduledSeller,
		financialAnticipationScheduledColombo,
		financialReportColombo,
		financialReportSeller,
		financialEntrySplitRoute,
		{ path: '/listagem-extratos', meta: { adminAccess: true, breadcrumb: { name: 'Extrato' }, requiresAuthorities: ROUTE_FINANCIAL_STATEMENT_COLOMBO.routerAuth }, component: financialStatement, name: ROUTE_FINANCIAL_STATEMENT_COLOMBO.name },
		{ path: '/listagem-extratos-seller', meta: { adminAccess: false, breadcrumb: { name: 'Extrato' }, requiresAuthorities: ROUTE_FINANCIAL_STATEMENT_SELLER.routerAuth }, component: financialStatement, name: ROUTE_FINANCIAL_STATEMENT_SELLER.name },
		{ path: '/listagem-comissao', meta: { breadcrumb: { name: 'Comissão' }, requiresAuthorities: ROUTE_FINANCIAL_COMMISSION.routerAuth }, component: financialCommission, name: ROUTE_FINANCIAL_COMMISSION.name }
	]
}

export default financialRoute
