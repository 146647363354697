import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Reports = new AuthorityBuilder({
	accessGroup: 'REPORTS',
	accessType: ['ACCESS']
})

const ReportsOrderStatusHistory = new AuthorityBuilder({
	accessGroup: 'REPORTS_ORDER_STATUS_HISTORY',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

const ReportsOrderProductHistory = new AuthorityBuilder({
	accessGroup: 'REPORTS_ORDER_PRODUCT_HISTORY',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

const ReportsBillingByProduct = new AuthorityBuilder({
	accessGroup: 'REPORTS_BILLING_BY_PRODUCT',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

const ReportsBillingByCategory = new AuthorityBuilder({
	accessGroup: 'REPORTS_BILLING_BY_CATEGORY',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

export default [
	Reports,
	ReportsOrderStatusHistory,
	ReportsOrderProductHistory,
	ReportsBillingByProduct,
	ReportsBillingByCategory
]
