<template>
	<div class="mkt-select" v-click-outside="selectClickOutside" :class="{ 'mkt-select--disabled': blDisabled }">
		<md-field
			:class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }">
			<mkt-label class="mkt-field__label" v-if="txLabel" :tx-label="txLabel" :bl-required="blRequired"></mkt-label>
			<md-input
				autocomplete="off"
				class="md-input"
				v-model="viewValue"
				:id="txId"
				:placeholder="txPlaceholder"
				@keyup="updateFilterValue"
				@click="isListOpen = true"
				@keydown.down="down"
				@keydown.up="up"
				@keydown.enter="enter"
				@keydown.esc="esc"
				v-on:keydown.enter="checkPrevent"
				:disabled="blDisabled || blReadOnly"
				@focus="$emit('focus')"
				:required="blRequired"
			></md-input>
			<span class="mkt-select__list--icon" @click="listOpen" v-if="!blReadOnly">
				<mkt-icon :tx-icon="isListOpen ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'" :bl-hover="true" tx-font-size="10px"></mkt-icon>
			</span>
			<span class="md-error">Campo obrigatório</span>
		</md-field>
		<md-content class="md-scrollbar mkt-raised" v-if="isListOpen">
			<ul class="mkt-select__list">
				<li
					class="mkt-select__list__item"
					v-for="(option, $index) in filteredList"
					:key="$index"
					:class="activeClass($index)"
					@mousemove="setActive($index)"
					@click="beforeSelectOption(option, $index)"
					:id="(getOptionText(option, $index) + '-' + $index)"
				>
				{{ getOptionText(option, $index) }}
				</li>
			</ul>
		</md-content>
	</div>
</template>

<script>
	import _ from 'lodash-core'
	import { incrementListIndex, decrementListIndex } from 'services/selectService'

	export default {
		name: 'mkt-select',
		props: {
			txId: { type: String },
			ltOptions: { type: Array, required: false, default: () => [] },
			optionText: { type: [String, Function], required: false },
			optionValue: { type: String, required: false },
			txLabel: { type: String },
			txPlaceholder: { type: String },
			blNoClear: { type: Boolean, required: false, default: false },
			blConfirmChange: { type: Boolean, required: false, default: false },
			blDisabled: { type: Boolean, required: false, default: false },
			blPreventDefault: { type: Boolean, required: false, default: false },
			blReadOnly: { type: Boolean, required: false, default: false },
			value: { required: false, default: null },
			defaultValue: { required: false },
			blRequired: { type: Boolean, required: false, default: true }
		},
		data() {
			return {
				viewValue: this.getViewValue(this.value),
				isListOpen: false,
				current: undefined,
				optionListFilter: '',
				hasErrors: false
			}
		},
		mounted() {
			if (!this.value && this.defaultValue) {
				this.updateModelValue(this.defaultValue)
			}
		},
		methods: {
			updateFilterValue(el) {
				if (!el || el.keyCode === 13 || el.keyCode === 16 || el.keyCode === 17 ||
					el.keyCode === 18 || el.keyCode === 225 || el.keyCode === 45 ||
					el.keyCode === 36 || el.keyCode === 33 || el.keyCode === 35 ||
					el.keyCode === 34 || el.keyCode === 20 || el.keyCode === 144 ||
					el.keyCode === 27 || el.keyCode === 40 || el.keyCode === 9 ||
					el.keyCode === 39 || el.keyCode === 38 || el.keyCode === 37) {
					return
				}

				this.optionListFilter = this.viewValue

				if (!this.viewValue && this.value) {
					this.beforeClearValue()
				}
			},
			checkPrevent(e) {
				if (this.blPreventDefault) {
					e.preventDefault()
				}
			},
			beforeSelectOption(option) {
				this.closeOptionList()
				if (this.blConfirmChange && this.value && this.value !== this.getOptionValue(option)) {
					this.viewValue = this.getViewValue(this.value)
					this.$emit('mkt-select:confirm-change', option)
				} else {
					this.selectOption(option)
				}
			},
			selectOption(option) {
				this.validateField()
				this.updateModelValue(option, 'mkt-select:selected')
			},
			clearValue() {
				this.updateModelValue(null, 'mkt-select:cleared')
				this.validateField()
			},
			beforeClearValue() {
				if (this.blNoClear) return

				if (this.blConfirmChange && this.value) {
					this.$emit('mkt-select:confirm-change', null)
				} else {
					this.clearValue()
				}
			},
			updateModelValue(selectedValue, emitEvent) {
				this.optionListFilter = ''
				const option = this.defaultValue ? (selectedValue || this.defaultValue) : selectedValue

				const optionValue = option && this.getOptionValue(option)
				this.$emit('input', optionValue)
				emitEvent && this.$emit(emitEvent, optionValue)
			},
			closeOptionList() {
				this.isListOpen = false
			},
			listOpen() {
				if (this.blDisabled) {
					return
				}
				this.isListOpen = !this.isListOpen
				this.$emit(this.isListOpen ? 'focus' : 'blur')
			},
			/* Utilities */
			getOptionText(option, index) {
				if (this.optionText && option) {
					return this.optionText instanceof Function ? this.optionText(option, index) : _.get(option, this.optionText)
				}
				return option
			},
			getOptionValue(option) {
				return this.optionValue ? option[this.optionValue] : option
			},
			getViewValue(value) {
				if (this.optionValue) {
					return this.getOptionText(this.ltOptions.find(option => option[this.optionValue] === value))
				} else {
					return this.getOptionText(value)
				}
			},
			validateField() {
				this.$emit('blur')
				this.$nextTick(() => {
					if (this.blRequired) {
						this.hasErrors = !this.value
					}
				})
			},
			up() {
				if (!this.isListOpen) {
					return
				}

				this.current = decrementListIndex(this.current)
				if (this.current !== undefined) {
					const ltOptions = this.filteredList
					this.viewValue = this.getOptionText(ltOptions[this.current])
				}
			},
			down() {
				if (!this.isListOpen) {
					this.isListOpen = true
					return
				}

				const ltOptions = this.filteredList
				const maxValue = ltOptions.length - 1
				this.current = incrementListIndex(this.current, maxValue)
				this.viewValue = this.getOptionText(ltOptions[this.current])
			},
			esc() {
				this.closeOptionList()
				this.beforeClearValue()
			},
			enter(e) {
				if (this.isListOpen && this.current !== undefined) {
					e.stopPropagation()
					this.beforeSelectOption(this.filteredList[this.current])
				}
			},
			activeClass(index) {
				return {
					active: this.current === index
				}
			},
			selectClickOutside() {
				if (!this.isListOpen) {
					return
				}

				this.closeOptionList()
				this.optionListFilter = ''
				this.viewValue = this.getViewValue(this.value)
				this.blur()
			},
			setActive(index) {
				if (this.current !== index) {
					this.current = index
					this.viewValue = this.getOptionText(this.filteredList[index])
				}
			},
			blur() {
				this.validateField()
				this.$emit('blur')
			}
		},
		computed: {
			filteredList() {
				if (!this.optionListFilter) {
					return this.ltOptions
				}
				const filterByModel =	(option, $index) => this.getOptionText(option, $index).toLowerCase().includes(this.optionListFilter.toLowerCase())
				return this.ltOptions.filter(filterByModel)
			}
		},
		watch: {
			value(newVal) {
				this.viewValue = this.getViewValue(newVal)
				this.validateField()
			},
			ltOptions() {
				if (this.value && !this.viewValue) {
					this.viewValue = this.getViewValue(this.value)
					if (this.viewValue) {
						this.$emit('mkt-select:async-view-value', this.value)
					}
				}
			}
		}
	}
</script>
<style lang="scss" src="./mktSelect.scss">
</style>
