import { Get, Put } from './simpleBaseHttpService'

const baseGlobalNotificationUrl = '/notification'

const getTotalGlobalNotifications = () => {
	return Get(`${baseGlobalNotificationUrl}/total`)
}

const getGlobalNotifications = () => {
	return Get(`${baseGlobalNotificationUrl}/top`)
}

const putReadNotification = (notificationId) => {
	return Put(`${baseGlobalNotificationUrl}/read/${notificationId}`)
}

const putReadAllNotifications = () => {
	return Put(`${baseGlobalNotificationUrl}/read-all`)
}

export { getGlobalNotifications, getTotalGlobalNotifications, putReadNotification, putReadAllNotifications }
