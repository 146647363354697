import { SET_STEPS_MUTATION, SET_STEP_MUTATION } from 'constants/MutationTypes'
import Vue from 'vue'

// initial state
const state = {
	steps: null
}

// getters
const getters = {
	getSteps: state => state.steps
}

// actions
const actions = {
	setSteps: ({ commit }, steps) => {
		commit(SET_STEPS_MUTATION, steps)
	},
	setIndividualStep: ({ commit }, { step, index }) => {
		commit(SET_STEP_MUTATION, { step, index })
	}
}

// mutations
const mutations = {
	[SET_STEPS_MUTATION](state, steps) {
		Vue.set(state, 'steps', steps)
	},
	[SET_STEP_MUTATION](state, { step, index }) {
		state.steps.splice(index, 1, step)
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
