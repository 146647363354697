<template>
	<span class="mkt-tag">
		{{ tagText(obTag) }}
		<span class="mkt-tag__icon-wrapper" @click.prevent="removeTag">
			<mkt-icon class="mkt-tag__icon-wrapper--icon__close" tx-font-size="10px" tx-icon-color="#c1c1c1" tx-icon="icon-close"></mkt-icon>
		</span>
	</span>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import _ from 'lodash-core'

export default {
	name: 'mkt-tag',
	props: {
		obTag: new PropConfig({ type: Object, required: true }),
		txOptionText: new PropConfig({ type: String, required: false }),
		fnOptionText: new PropConfig({ type: Function, required: false })
	},
	data() {
		return {
			events: {
				remove: (event, obTag) => this.$emit('mkt-tag:remove', event, obTag)
			}
		}
	},
	methods: {
		tagText(tag) {
			if (this.txOptionText) {
				return _.get(tag, this.txOptionText)
			}
			if (this.fnOptionText) {
				return this.fnOptionText(tag)
			}
			return tag
		},
		removeTag(event) {
			event.cancelBubble = true
			this.events.remove(event, this.obTag)
		}
	}
}
</script>

<style src="./mktTag.scss" lang="scss">
</style>
