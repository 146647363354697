
'use strict'

export default class PromotionCategoryDataDto {
	constructor({ promotionCategoryId, categoryId, discountPercentage, saleLimitQuantity, level, line, family, group }) {
		this.promotionCategoryId = promotionCategoryId
		this.categoryId = categoryId
		this.discountPercentage = discountPercentage && discountPercentage.toFixed(2)
		this.saleLimitQuantity = saleLimitQuantity
		this.level = level
		this.line = line
		this.family = family
		this.group = group
	}
}
