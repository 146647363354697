'use strict'

import RebateCategoryDto from './RebateCategoryDto'
import RebateProductItemSellerDto from './RebateProductItemSellerDto'

export default class RebateDto {
	constructor({
		rebateId,
		description,
		sellerId,
		startRebate,
		endRebate,
		rebatePaymentMethod,
		rebateType,
		rebateStatus,
		rebateProductItemSellers,
		rebateCategories
	}) {
		this.rebateId = rebateId || null
		this.description = description || null
		this.sellerId = sellerId || null
		this.startRebate = startRebate || null
		this.endRebate = endRebate || null
		this.rebateStatus = rebateStatus || null
		this.rebatePaymentMethod = rebatePaymentMethod || null
		this.rebateType = rebateType || null
		this.rebateProductItemSellers = (rebateProductItemSellers || []).map(item => new RebateProductItemSellerDto(item))
		this.rebateCategories = (rebateCategories || []).map(cat => new RebateCategoryDto(cat))
	}

	clearSpecificData() {
		this.rebateProductItemSellers = []
		this.rebateCategories = []
	}
}
