/**
 * Exemplo criação dominio TipoSexo.
 * Atributos default:
 *  name:
 *  ordinal: posição no array
 *
 * Inicialização default contendo atributos default name e ordinal
 * TipoSexo.initEnum(['MASCULINO', 'FEMININO'])
 *
 * Inicialização personalizada contendo atributos default name e ordinal mais atributos customizados
 * TipoSexo.initEnum({
 *    MASCULINO: {
 *        message: 'Masculino',
 *        code: 'MA',
 *    },
 *    FEMININO: {
 *        message: 'Feminino',
 *        code: 'FE',
 *    },
 *   })
 */

import { Enum } from 'enumify'

export default Enum
