
'use strict'

export default class RebateCategoryDto {
	constructor({ rebateCategoryId, categoryId, commissionPercentage, defaultCommission, saleLimitQuantity, freight, lockPrice, level, line, family, group }) {
		this.rebateCategoryId = rebateCategoryId
		this.categoryId = categoryId
		this.commissionPercentage = commissionPercentage && commissionPercentage.toFixed(2)
		this.defaultCommission = defaultCommission && defaultCommission.toFixed(2)
		this.saleLimitQuantity = saleLimitQuantity
		this.freight = freight === 0 ? '0.00' : freight && freight.toFixed(2)
		this.lockPrice = !!lockPrice
		this.level = level
		this.line = line
		this.family = family
		this.group = group
	}
}
