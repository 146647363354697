const RouterTransitionMixin = {
	data() {
		return {
			prevHeight: 0
		}
	},
	methods: {
		beforeLeave(element) {
			this.prevHeight = getComputedStyle(element).height
		},
		enter(element) {
			const { height } = getComputedStyle(element)

			element.style.height = this.prevHeight

			setTimeout(() => {
				element.style.height = height
			})
		},
		afterEnter(element) {
			element.style.height = 'auto'
		}
	}
}

export default RouterTransitionMixin
