import { getLoggedUser } from 'httpServices/userHttpService'
import store from 'src/store/store'
import { SET_LOGGED_USER_MUTATION, SET_REDIRECT_TO_LOGIN_MUTATION, SET_TOTAL_NOTIFICATIONS_HEADER } from 'constants/MutationTypes'
import { hasAuthority } from 'services/loggedUserService'
import { ROUTE_LOGIN, ROUTE_DASHBOARD, ROUTE_SERVER_ERROR } from 'constants/RouterNames'
import { sendErrorNotification } from 'src/services/notificationService'
import { USER_VALID } from 'constants/LocalStorageKeys.js'
import _ from 'lodash-core'

let nextRoute = null

const loadTotalNotificationCount = () => {
	store.commit(SET_TOTAL_NOTIFICATIONS_HEADER)
}

const debouncedLoadNotifications = _.debounce(loadTotalNotificationCount, 5000, { 'leading': true, 'trailing': false })

export default function requireAuth(to, from, next) {
	// Verify if route requires Authentication
	const requiredAuth = to.matched.some(record => record.meta.requiresAuth)
	if (!requiredAuth) {
		next()
		return
	}

	const isLoggedIn = store.getters.isLoggedIn && localStorage.getItem(USER_VALID) === 'true'
	if (isLoggedIn) {
		// Update the amount of unread notifications with a 5 seconds Debounce to avoid making too many calls
		debouncedLoadNotifications()
		directToRoute(to, next)
	} else {
		// Verify if the user has an ongoing session on the backend
		getLoggedUser().then(user => {
			// Update the amount of unread notifications with a 5 seconds Debounce to avoid making too many calls
			debouncedLoadNotifications()
			// Set the logged user on the Store
			store.commit(SET_LOGGED_USER_MUTATION, user)
			localStorage.setItem(USER_VALID, true)
			directToRoute(to, next)
		}).catch(() => {
			// Save last route to redirect after login
			nextRoute = to
			// Redirect to login in case the user isnt authenticated
			store.commit(SET_REDIRECT_TO_LOGIN_MUTATION, true)
			next(`/${ROUTE_LOGIN.name}`)
			sendErrorNotification('É necessário estar autenticado para acessar essa informação')
		})
	}
}

const directToRoute = (to, next) => {
	// Verify if the user has the necessary role to access the route
	const blHasAuthority = getHasAuthority(nextRoute || to)
	if (!blHasAuthority) {
		next({ path: `/${ROUTE_SERVER_ERROR.name}`, replace: true }) // Replace the route so that the previous route is excluded from the history
	} else if (nextRoute) { // In case the user has a saved route from a previous route change attempt, redirect him
		const path = nextRoute.fullPath
		nextRoute = null
		next(path || `/${ROUTE_DASHBOARD.name}`)
	} else {
		next()
	}
}

const getHasAuthority = (to) => {
	return hasAuthority(to.meta.requiresAuthorities)
}
