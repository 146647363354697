import Enum from './Domain'

class DayType extends Enum { }

DayType.initEnum({
	CONSECUTIVE_DAY: {
		description: 'Dia'
	},
	WORKDAY: {
		description: 'Dia Útil'
	}
})

export default DayType
