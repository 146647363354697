import {
	ROUTE_COLOMBO_NOTIFICATION,
	ROUTE_COLOMBO_NOTIFICATION_LIST
} from 'constants/RouterNames'

const notificationColombo = () => import('components/pages/secured/sac/notificationColombo/notificationColombo')
const notificationColomboList = () => import('components/pages/secured/sac/notificationColombo/notificationColomboList/notificationColomboList')

const notificationColomboRoute = {
	path: '/notificacoes-colombo',
	redirect: '/listagem-notificacoes-colombo',
	component: notificationColombo,
	name: ROUTE_COLOMBO_NOTIFICATION.name,
	meta: { breadcrumb: { name: 'Notificação' }, requiresAuthorities: ROUTE_COLOMBO_NOTIFICATION.routerAuth },
	children: [
		{ path: '/listagem-notificacoes-colombo', component: notificationColomboList, name: ROUTE_COLOMBO_NOTIFICATION_LIST.name, meta: { requiresAuthorities: ROUTE_COLOMBO_NOTIFICATION_LIST.routerAuth } }
	]
}

export default notificationColomboRoute
