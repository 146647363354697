'use strict'
import { sortFunctions } from 'services/listService.js'
import StatusType from 'domains/StatusType'
export default class ProductModerationDto {
	constructor({ sellerId, attributes, brandId, brandName, category, description, dimensions, guide, itemStatus, model, productCategory, name, productId, productItemId, productItemMatch,
		productItemSellerContentId, treeCategory, variation, youtubeLink, fullNameLock, reprovedMessage, productItemSellerId, reprovedReasonList }) {
		this.productItemSellerContentId = productItemSellerContentId || null
		this.productId = productId || null
		this.sellerId = sellerId || null
		this.productItemId = productItemId || null
		this.treeCategory = treeCategory || null
		this.category = category
		this.title = name || null
		this.description = description || null
		this.brand = { id: brandId, name: brandName }
		this.model = model || null
		this.productCategory = productCategory || null
		this.manual = guide || null
		this.characteristics = sortFunctions.alphabeticSortList((attributes || []), 'description')
		this.dimensions = dimensions
		this.variation = variation
		this.height = (dimensions && dimensions.height)
		this.width = (dimensions && dimensions.width)
		this.length = (dimensions && dimensions.length)
		this.weight = (dimensions && dimensions.weight)
		this.ean = (variation && variation.ean) || null
		this.sellerFantasyName = (variation && variation.sellerFantasyName) || null
		this.sellerName = (variation && variation.sellerName) || null
		this.color = (variation && variation.color) || null
		this.size = (variation && variation.size) || null
		this.voltage = (variation && variation.voltage) || null
		this.sku = (variation && variation.sku) || null
		this.price = (variation && variation.price) || null
		this.stock = (variation && variation.stock) || null
		this.expedition = (variation && variation.irDeliveryTimeDays)
		this.photos = (variation && variation.pictures) || []
		this.youtubeLink = youtubeLink
		this.productItemMatch = productItemMatch || null
		this.fullNameLock = fullNameLock
		this.itemStatus = StatusType[itemStatus]
		this.productItemSellerId = productItemSellerId
		this.reprovedReasonList = reprovedReasonList || []
	}
}
