import Vue from 'vue'

import { MdButton, MdField, MdRadio, MdContent, MdCheckbox, MdIcon, MdSwitch } from 'vue-material/dist/components'

Vue.use(MdButton)
Vue.use(MdField)
Vue.use(MdRadio)
Vue.use(MdContent)
Vue.use(MdCheckbox)
Vue.use(MdIcon)
Vue.use(MdSwitch)
