<template>
  <div class="mkt-table-status-icons" @click="eventEmitClick">
		<div class="mkt-table-status-icons__list">
			<mkt-icon
				v-if="statusIcon"
				:tx-icon="statusIcon.icon"
				:tx-icon-color="statusIcon.color"
				tx-font-size="18px"
				:title="statusIcon.message"
			></mkt-icon>
			<mkt-icon
				v-if="occurrenceIcons"
				v-for="(occurrenceIcon, index) in occurrenceIcons"
				:key="index"
				:tx-icon="occurrenceIcon && occurrenceIcon.icon"
				:tx-icon-color="occurrenceIcon && occurrenceIcon.color"
				tx-font-size="18px"
				:title="occurrenceIcon && occurrenceIcon.message"
			></mkt-icon>
		</div>
  </div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import StatusType from 'domains/StatusType'
import OccurrenceTypeColombo from 'domains/OccurrenceTypeColombo'

export default {
	name: 'mkt-table-status-icons',
	props: {
		rowData: new PropConfig({
			type: Object,
			required: true
		}),
		rowIndex: new PropConfig({
			type: Number
		}),
		ltIcons: new PropConfig({
			required: false
		}),
		txStatusPath: new PropConfig({
			required: false,
			valueDefault: () => 'itemStatus'
		})
	},
	methods: {
		mapOcurrences(listOccurrence) {
			return listOccurrence && listOccurrence.map(occurrence => OccurrenceTypeColombo[occurrence])
		},
		eventEmitClick(event) {
			this.$parent.onCellClicked(this.rowData, 'mkt-table-status-icons', event)
		}
	},
	computed: {
		statusIcon() {
			const itemStatus = this.rowData[this.txStatusPath]
			return this.ltIcons ? this.ltIcons[itemStatus] : StatusType[itemStatus]
		},
		occurrenceIcons() {
			const { listOccurrence } = this.rowData
			return this.mapOcurrences(listOccurrence)
		}
	}
}
</script>
<style src="./mktTableStatusIcons.scss" lang="scss">
</style>
