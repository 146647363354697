
'use strict'

export default class RebateProductItemSellerDto {
	constructor({ rebateProductItemSellerId, productItemSellerId, commissionPercentage, defaultCommission, saleLimitQuantity, freight, maximumProductPrice, productId, sku, productName }) {
		this.rebateProductItemSellerId = rebateProductItemSellerId
		this.productItemSellerId = productItemSellerId
		this.commissionPercentage = commissionPercentage && commissionPercentage.toFixed(2)
		this.defaultCommission = defaultCommission && defaultCommission.toFixed(2)
		this.saleLimitQuantity = saleLimitQuantity
		this.freight = freight === 0 ? '0.00' : freight && freight.toFixed(2)
		this.maximumProductPrice = maximumProductPrice === 0 ? '0.00' : maximumProductPrice && maximumProductPrice.toFixed(2)
		this.productId = productId
		this.sku = sku
		this.productName = productName
	}
}
