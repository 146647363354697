<template>
	<notifications group="global" :position="txPosition" :max="nrMaxNotifications" :width="txWidth">
	  <template slot="body" slot-scope="props">
			<div class="mkt-notification-message">
				<a @click="props.close" class="mkt-notification-message__close">
					<mkt-icon tx-font-size="11px" tx-icon-color="#9B9B9B" tx-icon="icon-close"></mkt-icon>
				</a>
				<div class="mkt-notification-message__box">
					<div class="mkt-notification-message__icon" :class="props.item.type">
						<mkt-icon tx-font-size="36px" :tx-icon="props.item.data.icon"></mkt-icon>
					</div>
					<div class="mkt-notification-message__message" v-html="sanitizeText(props.item.text)" >
					</div>
				</div>
		  </div>
		</template>
	</notifications>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import DOMPurify from 'dompurify'

export default {
	props: {
		txGroup: new PropConfig({ type: String, required: true }),
		txPosition: new PropConfig({ type: String, required: false, valueDefault: 'top right' }),
		nrMaxNotifications: new PropConfig({ type: Number, required: false, valueDefault: 3 }),
		txWidth: new PropConfig({ type: String, required: false, valueDefault: '300px' })
	},
	methods: {
		sanitizeText(text) {
			return DOMPurify.sanitize(text)
		}
	}
}
</script>

<style src="./mktNotification.scss" lang="scss">
</style>
