<template>
	<div class="mkt-table"
	:class="{ 'highlight': blHideDropdown }"
	>
		<mkt-table-header
			@mkt-table-header:select-items-per-page="onSelectItemsPerPage"
			@mkt-table-header:clear-selected-items="clearSelectedItems"
			@mkt-table-header:select-all="selectAll"
			:itemsPerPage="itemsPerPage"
			:nr-selected-items="selectedItems && selectedItems.length"
			:blHideDropdown="blHideDropdown"
			:tx-selected-item-type-name="txSelectedItemTypeName"
			>
			<slot name="mkt-table-header" :selectedItems="selectedItems" slot="mkt-table-header-slot">
			</slot>
		</mkt-table-header>

		<div class="mkt-table__content">
			<vue-table
				:sort-params="getSortParam"
				:query-params="{ sort: 'sort', perPage: 'size', page: 'page' }"
				:append-params="filterParams"
				ref="vuetable"
				:track-by="txContentId"
				:fields="sanitizedFields"
				:api-url="apiUrl"
				:per-page="itemsPerPage"
				data-path="content"
				:detail-row-component="txDetailRowComponent"
				:pagination-path="txPaginationPath"
				no-data-template="Não há dados para essa consulta."
				@vuetable:pagination-data="onPaginationData"
				@vuetable:checkbox-toggled="onSelectCheckbox"
				@vuetable:checkbox-toggled-all="onSelectCheckboxAll"
				@vuetable:loading="whileLoading"
				@vuetable:load-success="finishLoading"
				@vuetable:load-error="finishLoading"
				@vuetable:cell-clicked="onCellClick"
				:load-on-start="blLoadOnStart"
				:http-fetch="onSearch"
				:css="css"
				:row-class="rowClass">
				<template
					v-for="field in slotFields"
					:slot="extractArgs(field.name)"
					slot-scope="props">
					<slot :name="extractArgs(field.name)" :row-data="props.rowData" :row-index="props.rowIndex"></slot>
				</template>
			</vue-table>
		</div>
		<div class="mkt-table__loader progress active" :class="{ 'loading': isLoading, 'hasError': hasError }" role="progressbar">
			<div class="progress-bar progress-bar-striped progress-bar-animated" style="width:100%;"></div>
		</div>
		<mkt-table-footer
			:has-export="hasExport"
			:pagination-data="paginationData"
			:is-processing="isProcessing"
			@changePage="onChangePage"
			:exportDisabled="hasNoData"
			:export-authority="exportAuthority"
			@mkt-table:export="initExport"
		>
			<slot name="mkt-table-footer" :selectedItems="selectedItems" slot="mkt-table-footer-slot">
			</slot>
		</mkt-table-footer>
	</div>
</template>

<script>
	import './mktTableComponents'
	import VueTable from 'vuetable-2'
	import MktTableHeader from './mktTableHeader/mktTableHeader'
	import MktTableFooter from './mktTableFooter/mktTableFooter'
	import { toObjectUrl } from 'services/queryStringService.js'
	import { sendWarningNotification, sendErrorNotification } from 'services/notificationService'
	import DOMPurify from 'dompurify'
	import { Get } from 'httpServices/baseHttpService'
	import { serializeObject } from 'src/services/queryStringService'
	import axios from 'axios'
	import { verifyExportFile } from 'services/recursionService.js'

	const OVERRIDE_MESSAGE = 'Request overridden'

	export default {
		name: 'mkt-table',
		components: {
			VueTable,
			MktTableFooter,
			MktTableHeader
		},
		props: {
			apiUrl: { type: String, required: true },
			ltDataKeys: { type: Array, required: false },
			txPaginationPath: { type: String, required: false, default: '' },
			txDetailRowComponent: { type: String, required: false },
			fields: { type: Array, required: true },
			blClickableCell: { type: Boolean, required: false, default: false },
			blHideDropdown: { type: Boolean, required: false, default: false },
			blLoadOnStart: { type: Boolean, required: false, default: true },
			txContentId: { type: String, required: false, default: 'id' },
			getAllContentId: { type: Function, required: false },
			exportAuthority: { type: [String, Array], required: false },
			getExport: { type: Function, required: false },
			exportData: { type: Function, required: false },
			hasExport: { type: Boolean, required: false, default: false },
			txSelectedItemTypeName: { type: String, required: false, default: 'produtos' }
		},
		data() {
			return {
				filterParams: {},
				currentFilter: {},
				itemsPerPage: 20,
				hasNoData: true,
				paginationData: null,
				selectedItems: [],
				isLoading: false,
				hasError: false,
				call: null,
				isProcessing: false,
				css: {
					loadingClass: 'loading',
					sortableIcon: 'icon-arrow_downward',
					ascendingIcon: 'icon-arrow_upward',
					descendingIcon: 'icon-arrow_downward',
					detailRowClass: 'detail-row'
				},
				events: {
					selectedItems: () => this.$emit('mkt-table:selected-items', this.selectedItems),
					toggleSelectedItem: (isChecked, dataItem) => this.$emit('mkt-table:toggle-selected-item', { isChecked, dataItem }),
					toggleSelectedItems: (isChecked, dataItems) => this.$emit('mkt-table:toggle-selected-items', { isChecked, dataItems }),
					selectAll: (dataItems) => this.$emit('mkt-table:select-all', dataItems),
					clearAll: () => this.$emit('mkt-table:clear-all'),
					cellClicked: (cell) => this.$emit('mkt-table:cell-clicked', cell)
				}
			}
		},
		methods: {
			getSortParam(sortOrder) {
				return sortOrder.map(function(sort) {
					const fieldNameSort = sort.sortField === undefined ? sort.field : sort.sortField
					return fieldNameSort + ',' + sort.direction
				}).join(',')
			},
			extractName(string) {
				return string.split(':')[0].trim()
			},
			extractArgs(string) {
				return string.split(':')[1]
			},
			onSearch(apiUrl, httpOptions) {
				if (this.call) {
					this.call.cancel(OVERRIDE_MESSAGE)
					this.call = null
				}
				this.call = axios.CancelToken.source()
				return Get(`${apiUrl}?${serializeObject(httpOptions)}`, {}, { cancelToken: this.call.token })
			},
			transform(data) {
				if (data.ids) {
					let allContent = (this.getAllContentId && data.ids.map(this.getAllContentId)) || data.ids
					this.selectedItems = this.$refs.vuetable.selectedTo = allContent
					this.events.selectedItems()
				}
				if (this.filterParams.selectAll) {
					this.events.selectAll(data.ids)
					this.filterParams.selectAll = undefined
				}

				if (!data.content || !data.content.length) {
					this.hasNoData = true
					sendWarningNotification('Nenhum resultado encontrado!')
				} else {
					this.hasNoData = false
				}

				const currentPage = (data.number + 1)
				const itemsPerPage = data.size

				const paginateToTotal = (currentPage * itemsPerPage)

				const from = ((currentPage - 1) * itemsPerPage) + 1
				const to = paginateToTotal >= data.totalElements ? data.totalElements : paginateToTotal

				const paginationInfo = {
					total: data.totalElements,
					from,
					to
				}

				const paginationData = {
					per_page: itemsPerPage,
					current_page: currentPage,
					last_page: data.totalPages
				}

				if (this.ltDataKeys && this.ltDataKeys.length > 0) {
					this.mapMultitrackKey(data.content)
				}

				let transformed = Object.assign({}, {
					content: data.content
				}, paginationInfo, paginationData)

				return transformed
			},
			mapMultitrackKey(content) {
				content.map(data => {
					this.ltDataKeys.forEach(key => {
						this.iterateDataKeys(key, data)
					})
				})
			},
			iterateDataKeys(key, data) {
				const properties = key.split('.')
				let iterator = data
				properties.forEach((prop, index) => {
					iterator = iterator[prop]
					if (index === (properties.length - 1)) {
						this.buildMultitrackKey(iterator, data)
					}
				})
			},
			buildMultitrackKey(keyVal, data) {
				data.multitrackKey = !data.multitrackKey ? keyVal : `${data.multitrackKey}-${keyVal}`
			},
			whileLoading() {
				this.isLoading = true
			},
			finishLoading(response) {
				if (response && response.message !== OVERRIDE_MESSAGE) {
					this.isLoading = false
					this.hasError = !(response.status <= 300)
				}
			},
			clearSelectedItems() {
				this.selectedItems = this.$refs.vuetable.selectedTo = []
				this.events.selectedItems()
				this.events.clearAll()
			},
			onSelectCheckbox(isChecked, dataItem) {
				this.changeSelectedItems()
				this.events.toggleSelectedItem(isChecked, dataItem)
			},
			onSelectCheckboxAll(isChecked) {
				this.changeSelectedItems()
				this.events.toggleSelectedItems(isChecked, this.$refs.vuetable.tableData)
			},
			changeSelectedItems() {
				this.selectedItems = this.$refs.vuetable.selectedTo
				this.events.selectedItems()
			},
			onPaginationData(paginationData) {
				this.paginationData = paginationData
			},
			onChangePage(page) {
				this.$refs.vuetable.changePage(page)
			},
			onCellClick(cell) {
				if (!this.blClickableCell) {
					return
				}
				this.events.cellClicked(cell)
			},
			selectAll() {
				this.filterParams = { ...this.filterParams, selectAll: true }
				this.$nextTick(function() {
					this.$refs.vuetable.refresh()
				})
			},
			appendParam(filter) {
				this.currentFilter = filter
				this.filterParams = toObjectUrl(filter) || {}
				this.clearSelectedItems()
				this.$nextTick(() => this.$refs.vuetable.refresh())
			},
			toggleDetailRow(id) {
				this.$nextTick(() => this.$refs.vuetable.toggleDetailRow(id))
			},
			onSelectItemsPerPage(itemsPerPage) {
				this.itemsPerPage = itemsPerPage
				this.$nextTick(function() {
					this.$refs.vuetable.refresh()
				})
			},
			rowClass(dataItem, index) {
				return { 'has-clickable-row': this.blClickableCell, 'selected': this.selectedItems.indexOf(dataItem.id) > -1 }
			},
			refreshTable() {
				this.$nextTick(function() {
					this.$refs.vuetable.refresh()
				})
			},
			initExport() {
				this.isProcessing = true
				this.exportData(this.currentFilter)
					.then(({ data: fileExport }) => {
						fileExport.warningMessage && sendWarningNotification(fileExport.warningMessage)
						verifyExportFile(this.getExport, fileExport.exportId)
							.then(this.stopProcessing)
							.catch(() => {
								sendErrorNotification('Ocorreu um erro! Tente novamente mais tarde.')
								this.stopProcessing()
							})
					}).catch(this.stopProcessing)
			},
			resetData() {
				this.hasNoData = true
				this.$refs.vuetable.resetData()
			},
			stopProcessing() {
				this.isProcessing = false
			}
		},
		computed: {
			sanitizedFields() {
				const clonedFields = [...this.fields]
				clonedFields.forEach(field => {
					const fieldName = field.name
					if (this.extractName(fieldName) !== '__slot') {
						const fieldCallback = field.callback
						field.callback = (...args) => {
							return DOMPurify.sanitize(fieldCallback ? fieldCallback(...args) : args[0])
						}
					}
				})
				return clonedFields
			},
			slotFields() {
				return this.fields.filter(field => this.extractName(field.name) === '__slot')
			}
		}
	}
</script>

<style src="./mktTable.scss" lang="scss">

</style>
