import {
	ROUTE_REBATE_REGISTER_COLOMBO,
	ROUTE_REBATE_REGISTER_COLOMBO_PRODUCT,
	ROUTE_REBATE_REGISTER_COLOMBO_CATEGORY
} from 'constants/RouterNames'

const rebateRegister = () =>
	import('components/pages/secured/promotion/rebate/rebateColombo/rebateRegister/rebateRegister.vue')
const rebateRegisterProduct = () =>
	import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseProduct/rebateBaseProduct.vue')
const rebateRegisterCategory = () =>
	import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseCategory/rebateBaseCategory.vue')

const rebateRegisterRoute = {
	path: '/cadastrar-rebate',
	component: rebateRegister,
	name: ROUTE_REBATE_REGISTER_COLOMBO.name,
	meta: { breadcrumb: { name: 'Cadastro', active: false }, requiresAuthorities: ROUTE_REBATE_REGISTER_COLOMBO.routerAuth },
	children: [
		{
			path: '/cadastrar-rebate-produto',
			component: rebateRegisterProduct,
			name: ROUTE_REBATE_REGISTER_COLOMBO_PRODUCT.name,
			meta: { breadcrumb: { name: 'Rebate por produto' }, requiresAuthorities: ROUTE_REBATE_REGISTER_COLOMBO_PRODUCT.routerAuth }
		},
		{
			path: '/cadastrar-rebate-categorias',
			component: rebateRegisterCategory,
			name: ROUTE_REBATE_REGISTER_COLOMBO_CATEGORY.name,
			meta: { breadcrumb: { name: 'Rebate por categoria' }, requiresAuthorities: ROUTE_REBATE_REGISTER_COLOMBO_CATEGORY.routerAuth }
		}
	]
}

export default rebateRegisterRoute
