import {
	ROUTE_FINANCIAL_REPORT_COLOMBO,
	ROUTE_FINANCIAL_REPORT_LIST_COLOMBO
} from 'constants/RouterNames'

const financialReport = () => import('components/pages/secured/financial/financialReport/financialReportColombo/financialReportColombo')
const financialReportColomboList = () => import('components/pages/secured/financial/financialReport/financialReportColombo/financialReportColomboList/financialReportColomboList')

const financialReportColombo = {
	path: '/agenda-detalhada-colombo',
	redirect: '/listagem-agenda-detalhada-colombo',
	meta: { requiresAuthorities: ROUTE_FINANCIAL_REPORT_COLOMBO.routerAuth },
	component: financialReport,
	name: ROUTE_FINANCIAL_REPORT_COLOMBO.name,
	children: [
		{ path: '/listagem-agenda-detalhada-colombo', component: financialReportColomboList, name: ROUTE_FINANCIAL_REPORT_LIST_COLOMBO.name, meta: { breadcrumb: { name: 'Agenda detalhada' }, requiresAuthorities: ROUTE_FINANCIAL_REPORT_LIST_COLOMBO.routerAuth } }
	]
}

export default financialReportColombo
