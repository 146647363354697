import { SET_ROUTE_TITLE_MUTATION, SET_ROUTE_ICON_MUTATION, SET_ROUTE_MUTATION } from 'constants/MutationTypes'

// initial state
const state = {
	routeTitle: '',
	routeSubtitle: '',
	routeIcon: ''
}

// getters
const getters = {
	getRouteTitle: state => state.routeTitle,
	getRouteSubtitle: state => state.routeSubtitle,
	getRouteIcon: state => state.routeIcon
}

// actions
const actions = {
	setRoute: ({ commit }, { routeTitle, routeSubtitle }) => {
		commit(SET_ROUTE_MUTATION, { routeTitle, routeSubtitle })
	},
	setRouteTitle: ({ commit }, routeTitle) => {
		commit(SET_ROUTE_TITLE_MUTATION, routeTitle)
	},
	setRouteIcon: ({ commit }, routeIcon) => {
		commit(SET_ROUTE_ICON_MUTATION, routeIcon)
	}
}

// mutations
const mutations = {
	[SET_ROUTE_TITLE_MUTATION](state, routeTitle) {
		state.routeTitle = routeTitle
		state.routeSubtitle = ''
	},
	[SET_ROUTE_MUTATION](state, { routeTitle, routeSubtitle }) {
		state.routeTitle = routeTitle
		state.routeSubtitle = routeSubtitle
	},
	[SET_ROUTE_ICON_MUTATION](state, routeIcon) {
		state.routeIcon = routeIcon
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
