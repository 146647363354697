import { sendErrorNotification } from 'src/services/notificationService'

import {
	ADD_PROMOTION_CATEGORY,
	ADD_PROMOTION_CATEGORY_EXCLUDED_PRODUCT,
	SET_PROMOTION_CATEGORY_FIELD,
	REMOVE_PROMOTION_CATEGORY,
	REMOVE_PROMOTION_CATEGORY_EXCLUDED_PRODUCT
} from 'constants/MutationTypes'

import PromotionProductItemSellerDto from 'dtos/promotionDtos/PromotionProductItemSellerDto.js'
import PromotionCategoryDataDto from 'dtos/promotionDtos/PromotionCategoryDataDto.js'

import { isNullOrUndefined } from 'services/objectService.js'

// getters
const getters = {
	getPromotionCategoryExcludedProducts: state => state.promotion.promotionProductItemSellers || [],
	getPromotionCategories: state => state.promotion.promotionCategories || []
}

// actions
const actions = {
	pushPromotionCategory({ commit }, category) {
		commit(ADD_PROMOTION_CATEGORY, category)
	},
	pushPromotionCategoryExcludedProduct({ commit }, product) {
		commit(ADD_PROMOTION_CATEGORY_EXCLUDED_PRODUCT, product)
	},
	setPromotionCategoryField({ commit }, { index, fieldName, fieldValue }) {
		commit(SET_PROMOTION_CATEGORY_FIELD, { index, fieldName, fieldValue })
	},
	removePromotionCategory({ commit }, index) {
		commit(REMOVE_PROMOTION_CATEGORY, index)
	},
	removePromotionCategoryExcludedProduct({ commit }, index) {
		commit(REMOVE_PROMOTION_CATEGORY_EXCLUDED_PRODUCT, index)
	}
}

// mutations
const mutations = {
	[ADD_PROMOTION_CATEGORY](state, data) {
		const promotionCategory = state.promotion.promotionCategories.find(cat => cat.categoryId === data.categoryId)

		if (!promotionCategory) {
			state.promotion.promotionCategories.push(new PromotionCategoryDataDto(data))
		} else {
			const categoryName = promotionCategory.group || promotionCategory.family || promotionCategory.line
			sendErrorNotification(`A categoria ${categoryName} já foi adicionada!`)
		}
	},
	[ADD_PROMOTION_CATEGORY_EXCLUDED_PRODUCT](state, data) {
		const productItemSeller = state.promotion.promotionProductItemSellers.find(pis => pis.productItemSellerId === data.productItemSellerId)

		if (!productItemSeller) {
			state.promotion.promotionProductItemSellers.push(new PromotionProductItemSellerDto(data))
		} else {
			sendErrorNotification(`O produto ${productItemSeller.productName} já foi adicionado!`)
		}
	},
	[SET_PROMOTION_CATEGORY_FIELD](state, { index, fieldName, fieldValue }) {
		if (isNullOrUndefined(index)) {
			state.promotion.promotionCategories.forEach(cat => {
				cat[fieldName] = fieldValue
			})
		} else {
			state.promotion.promotionCategories[index][fieldName] = fieldValue
		}
	},
	[REMOVE_PROMOTION_CATEGORY](state, index) {
		state.promotion.promotionCategories.splice(index, 1)
	},
	[REMOVE_PROMOTION_CATEGORY_EXCLUDED_PRODUCT](state, index) {
		state.promotion.promotionProductItemSellers.splice(index, 1)
	}
}

export default {
	getters,
	actions,
	mutations
}
