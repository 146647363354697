<template>
  <div
    :class="{
      [txClass]: true,
      'mkt-warning--small': blSmall,
      'mkt-warning--no-padding': blNoPadding,
    }"
    class="mkt-warning"  
  >
    <mkt-icon tx-icon="icon-info" tx-icon-color="#F58220" tx-font-size="26px" />
    
    <p class="mkt-warning__text">
      <slot />
    </p>
  </div>
</template>

<script>

import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-warning',

	props: {
		txClass: new PropConfig({ type: String, required: false, valueDefault: '' }),
		blSmall: new PropConfig({ type: Boolean, valueDefault: false }),
		blNoPadding: new PropConfig({ type: Boolean, valueDefault: false })
	}
}
</script>

<style src="./mktWarning.scss" lang="scss"></style>
