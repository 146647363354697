import { REGEX_MATCH_FIRST_LETTER } from 'constants/Regex'

class SellerRegistration {
	constructor(stateProp, updateMutation, statePropType) {
		this.stateProp = stateProp
		this.updateMutation = updateMutation
		this.state = { [stateProp]: statePropType || {} }
		this.initialState = this.state

		this.getMutations = this.getMutations.bind(this)
		this.getState = this.getState.bind(this)
		this.getGetters = this.getGetters.bind(this)
		this.getSetters = this.getSetters.bind(this)
	}

	getMutations() {
		const updateMutationProp = this.updateMutation
		const stateProperty = this.stateProp

		return {
			[updateMutationProp](state, newState) {
				state.sellerRegistration[stateProperty] = { ...state.sellerRegistration[stateProperty], ...newState }
			}
		}
	}

	getState() {
		return this.state
	}

	getInitialState() {
		return this.initialState
	}

	getGetters() {
		const getterName = `get${this.stateProp.replace(REGEX_MATCH_FIRST_LETTER, s => s.toUpperCase())}`
		const stateProperty = this.stateProp
		return {
			[getterName](state) {
				return state.sellerRegistration[stateProperty]
			}
		}
	}

	getSetters() {
		const setterName = `set${this.stateProp.replace(REGEX_MATCH_FIRST_LETTER, s => s.toUpperCase())}`
		const updateMutationProp = this.updateMutation
		return {
			[setterName]({ commit }, newValState) {
				commit(updateMutationProp, newValState)
			}
		}
	}
}

export default SellerRegistration
