import _ from 'lodash-core'

const transformFieldsToOnlyNumbers = (object, fields) => {
	const fieldsToTransform = fields || Object.keys(object)
	fieldsToTransform.forEach(field => {
		const value = _.get(object, field)
		value && _.set(object, field, value.replace(/[^0-9]/g, ''))
	})
}

const anyPropHasValue = (obj) => {
	return Object.values(obj).filter(prop => prop).length > 0
}

const isNullOrUndefined = (field) => {
	return field === null || field === undefined
}

/** Function taken from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 * Author: broofa
 */
const generateUUID = () => {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	)
}

const isAnyDifferent = (obj1, obj2) => {
	let isDifferent
	Object.keys(obj1).forEach(key => {
		isDifferent = obj1[key] !== obj2[key] && !isNullOrUndefined(obj1[key]) && !isNullOrUndefined(obj2[key])
	})

	return isDifferent
}

const openBoxesInsideRef = (ref) => {
	Object.values(ref).filter(ref => ref.openBox).forEach(ref => {
		ref.openBox()
	})
}

const cleanEmptyProperties = (obj) => {
	for (var propName in obj) {
		if (obj[propName] === '' || obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName]
		}
	}
	return obj
}

const isObjectEmpty = (obj) => {
	return _.isEmpty(obj)
}

export {
	transformFieldsToOnlyNumbers,
	openBoxesInsideRef,
	anyPropHasValue,
	isNullOrUndefined,
	isAnyDifferent,
	generateUUID,
	cleanEmptyProperties,
	isObjectEmpty
}
