import {
	ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT,
	ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT_LIST
} from 'constants/RouterNames'

const reportBillingByProduct = () => import('components/pages/secured/reports/reportBillingByProduct/reportBillingByProductSeller')
const reportBillingByProductList = () => import('components/pages/secured/reports/reportBillingByProduct/reportBillingByProductSeller/reportBillingByProductList/reportBillingByProductListSeller')

const reportBillingByProductRoute = {
	path: '/report-billing-by-product-seller',
	redirect: '/seller-relatorio-faturamento-por-produto',
	component: reportBillingByProduct,
	name: ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT.name,
	meta: { breadcrumb: { name: 'Faturamento por produto' }, requiresAuthorities: ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT.routerAuth },
	children: [
		{ path: '/seller-relatorio-faturamento-por-produto', component: reportBillingByProductList, name: ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_SELLER_REPORTS_BILLING_BY_PRODUCT_LIST.routerAuth } }
	]
}

export default reportBillingByProductRoute
