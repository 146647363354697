import {
	ROUTE_FINANCIAL_ENTRY,
	ROUTE_FINANCIAL_ENTRY_LIST,
	ROUTE_FINANCIAL_ENTRY_REGISTER,
	ROUTE_FINANCIAL_ENTRY_DETAIL
} from 'constants/RouterNames'

const financialEntry = () => import('components/pages/secured/financial/financialEntry/financialEntry')
const financialEntryList = () => import('components/pages/secured/financial/financialEntry/financialEntryList/financialEntryList')
const financialEntryDetail = () => import('components/pages/secured/financial/financialEntry/financialEntryDetail/financialEntryDetail')

const financialEntryRoute = {
	path: '/lancamentos-financeiros-braspag',
	redirect: '/listagem-lancamentos-financeiros-braspag',
	component: financialEntry,
	name: ROUTE_FINANCIAL_ENTRY.name,
	meta: { breadcrumb: { name: 'Lançamentos Braspag' }, requiresAuthorities: ROUTE_FINANCIAL_ENTRY.routerAuth },
	children: [
		{ path: '/listagem-lancamentos-financeiros-braspag', component: financialEntryList, name: ROUTE_FINANCIAL_ENTRY_LIST.name, meta: { requiresAuthorities: ROUTE_FINANCIAL_ENTRY_LIST.routerAuth } },
		{ path: '/cadastro-lancamentos-manuais-braspag', component: financialEntryDetail, name: ROUTE_FINANCIAL_ENTRY_REGISTER.name, meta: { requiresAuthorities: ROUTE_FINANCIAL_ENTRY_REGISTER.routerAuth } },
		{ path: '/edicao-lancamentos-manuais-braspag/:financialEntryId', props: true, component: financialEntryDetail, name: ROUTE_FINANCIAL_ENTRY_DETAIL.name, meta: { requiresAuthorities: ROUTE_FINANCIAL_ENTRY_DETAIL.routerAuth } }
	]
}

export default financialEntryRoute
