import { REGEX_NOT_PURE_NUMBER, REGEX_NOT_NUMBER_EXCLUDING_NEGATIVE } from 'constants/Regex'

const transformValue = (value, nrDecimal = 2, suffix = '') => {
	if (!value) return ''

	value = value.toString()

	const decimalRegex = new RegExp(`(.*)(\\d{${nrDecimal}})`, 'g')
	const isNegative = Math.sign(value.replace(REGEX_NOT_NUMBER_EXCLUDING_NEGATIVE, '')) === -1

	const transformedValue = ((parseInt(value.replace(REGEX_NOT_PURE_NUMBER, ''))
		.toString().padStart(nrDecimal + 1, '0')
		.replace(decimalRegex, '$1,$2')) + suffix)

	return isNegative ? ('-' + transformedValue) : transformedValue
}

export { transformValue }
