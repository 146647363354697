import {
	ROUTE_CONFIGURATION_ORDER_REASON
	, ROUTE_CONFIGURATION_ORDER_REASON_REGISTRATION
	, ROUTE_CONFIGURATION_ORDER_REASON_UPDATE
	, ROUTE_CONFIGURATION_ORDER_REASON_LIST
} from 'constants/RouterNames'

const orderReason = () => import('src/components/pages/secured/configuration/orderReason/orderReason')
const orderReasonDetail = () => import('src/components/pages/secured/configuration/orderReason/orderReasonDetail/orderReasonDetail')
const orderReasonList = () => import('src/components/pages/secured/configuration/orderReason/orderReasonList/orderReasonList')

const orderReasonRoute = {
	path: '/motivo-cancelamento',
	redirect: '/listagem-motivo-cancelamento',
	component: orderReason,
	name: ROUTE_CONFIGURATION_ORDER_REASON.name,
	meta: { breadcrumb: { name: 'Motivo Cancelamento' }, requiresAuthorities: ROUTE_CONFIGURATION_ORDER_REASON.routerAuth },
	children: [
		{ path: '/cadastro-motivo-cancelamento', component: orderReasonDetail, name: ROUTE_CONFIGURATION_ORDER_REASON_REGISTRATION.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_CONFIGURATION_ORDER_REASON_REGISTRATION.routerAuth } },
		{ path: '/edicao-motivo-cancelamento/:orderReasonId', props: true, component: orderReasonDetail, name: ROUTE_CONFIGURATION_ORDER_REASON_UPDATE.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_CONFIGURATION_ORDER_REASON_UPDATE.routerAuth } },
		{ path: '/listagem-motivo-cancelamento', component: orderReasonList, name: ROUTE_CONFIGURATION_ORDER_REASON_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_ORDER_REASON_LIST.routerAuth } }
	]
}

export default orderReasonRoute
