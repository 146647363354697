import {
	ROUTE_CATEGORY
	, ROUTE_CONFIGURATION
} from 'constants/RouterNames'

// configuration
import configurationCharacteristicRoute from './configurationCharacteristic'
import configurationSellerRoute from './configurationSeller/configurationSeller'
import configurationSellerColombo from './configurationSeller/configurationSellerColombo'
import configurationGeneral from './configurationGeneral'
import configurationFreightRoute from './configurationFreight'
import configurationUserColombo from './configurationUser/configurationUserColombo'
import configurationUserSeller from './configurationUser/configurationUserSeller'
import configurationOrderReason from './configurationOrderReason'
import configurationMdr from './configurationMdr'
import configurationFee from './configurationFee'
import configurationAccessProfile from './configurationAccessProfile'
import configurationReprovedReason from './configurationReprovedReason'
import configurationDiarySettings from './configurationDiarySettings'
import configurationDeleteData from './configurationDeleteData'
const configuration = () => import('components/pages/secured/configuration/configuration')

// configuration-category
const category = () => import('components/pages/secured/configuration/category/category')

const configurationRoute = {
	path: '/configuration',
	meta: { breadcrumb: { name: 'Configurações' }, requiresAuthorities: ROUTE_CONFIGURATION.routerAuth },
	component: configuration,
	name: ROUTE_CONFIGURATION.name,
	children: [
		{
			path: '/categorias',
			component: category,
			name: ROUTE_CATEGORY.name,
			meta: { breadcrumb: { name: 'Categorias/Comissões' }, requiresAuthorities: ROUTE_CATEGORY.routerAuth }
		},
		configurationCharacteristicRoute,
		configurationSellerRoute,
		configurationGeneral,
		configurationFreightRoute,
		configurationUserColombo,
		configurationUserSeller,
		configurationOrderReason,
		configurationSellerColombo,
		configurationMdr,
		configurationFee,
		configurationAccessProfile,
		configurationReprovedReason,
		configurationDiarySettings,
		configurationDeleteData
	]
}

export default configurationRoute
