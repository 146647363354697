import {
	SET_PROMOTION,
	SET_PROMOTION_INITIAL_STATE,
	SET_PROMOTION_BASE_FIELDS,
	SET_PROMOTION_TYPE,
	SET_PROMOTION_ID,
	CLEAR_SPECIFIC_PROMOTION_DATA,
	SET_PROMOTION_MARKED_FIELDS,
	SET_PROMOTION_STATUS,
	SET_EDITION_TYPE_STATE,
	COPY_PROMOTION_INITIAL_STATE
} from 'constants/MutationTypes'

import EditionType from 'domains/EditionType.js'

import promotionProduct from './promotionProduct'
import promotionCategory from './promotionCategory'

import PromotionDto from 'dtos/promotionDtos/PromotionDto.js'
import PromotionProductDto from 'dtos/promotionDtos/PromotionProductDto.js'
import PromotionCategoryDto from 'dtos/promotionDtos/PromotionCategoryDto.js'

// initial state
const state = {
	promotion: new PromotionDto({}),
	markedFields: [],
	editionType: EditionType.ALL,
	disableInRouteRedirects: false
}

// getters
const getters = {
	getPromotion: state => state.promotion,
	getPromotionStatus: state => state.promotion && state.promotion.promotionStatus,
	getPromotionModerationLock: state => state.promotion.lockModeration || false,
	getPromotionType: state => state.promotion.promotionType,
	getMarkedFields: state => state.markedFields,
	isInPromotionRouteRedirectsDisabled: state => state.disableInRouteRedirects,
	isEditionTypeAll: state => state.editionType === EditionType.ALL,
	isEditionTypeEndDate: state => state.editionType === EditionType.END_DATE,
	isEditionTypeNone: state => state.editionType === EditionType.NONE,
	...promotionProduct.getters,
	...promotionCategory.getters
}

// actions
const actions = {
	setProductColombo: ({ commit }, promotion) => {
		commit(SET_PROMOTION, promotion)
	},
	setPromotionBaseFields: ({ commit }, { fieldName, fieldValue }) => {
		commit(SET_PROMOTION_BASE_FIELDS, { fieldName, fieldValue })
	},
	setPromotionType: ({ commit }, promotionType) => {
		commit(SET_PROMOTION_TYPE, promotionType)
	},
	setPromotionStatus: ({ commit }, promotionStatus) => {
		commit(SET_PROMOTION_STATUS, promotionStatus)
	},
	setPromotionInitialState: ({ commit }, promotion) => {
		commit(SET_PROMOTION_INITIAL_STATE, promotion)
	},
	clearSpecificData({ commit }) {
		commit(CLEAR_SPECIFIC_PROMOTION_DATA)
	},
	setPromotionId: ({ commit }, promotionId) => {
		commit(SET_PROMOTION_ID, promotionId)
	},
	setMarkedFields: ({ commit }, markedFields) => {
		commit(SET_PROMOTION_MARKED_FIELDS, markedFields)
	},
	setPromotionEditionType: ({ commit }, editionType) => {
		commit(SET_EDITION_TYPE_STATE, editionType)
	},
	copyPromotion: ({ commit }) => {
		commit(COPY_PROMOTION_INITIAL_STATE)
	},
	...promotionProduct.actions,
	...promotionCategory.actions
}

// mutations
const mutations = {
	[SET_PROMOTION_BASE_FIELDS](state, { fieldName, fieldValue }) {
		state.promotion[fieldName] = fieldValue
	},
	[SET_PROMOTION_ID](state, promotionId) {
		state.promotion.promotionId = promotionId
	},
	[CLEAR_SPECIFIC_PROMOTION_DATA](state) {
		state.promotion.clearData && state.promotion.clearData()
	},
	[SET_PROMOTION_TYPE](state, promotionType) {
		state.promotion.promotionType = promotionType
		mutations[SET_PROMOTION_INITIAL_STATE](state, state.promotion)
	},
	[SET_PROMOTION_STATUS](state, promotionStatus) {
		state.promotion.promotionStatus = promotionStatus
	},
	[SET_PROMOTION_MARKED_FIELDS](state, markedFields) {
		state.markedFields = markedFields
	},
	[SET_PROMOTION_INITIAL_STATE](state, promotion) {
		if (promotion) {
			state.disableInRouteRedirects = false
			switch (promotion.promotionType) {
			case 'PRODUCT':
				state.promotion = new PromotionProductDto(promotion)
				return
			case 'CATEGORY':
				state.promotion = new PromotionCategoryDto(promotion)
				return
			default:
				state.promotion = new PromotionDto(promotion)
			}
		} else {
			state.disableInRouteRedirects = true
			state.markedFields = []
			state.editionType = EditionType.ALL
			state.promotion = new PromotionDto({})
		}
	},
	[SET_EDITION_TYPE_STATE](state, editionType) {
		state.editionType = editionType
	},
	[COPY_PROMOTION_INITIAL_STATE](state) {
		state.promotion.removeId()
		state.promotion.startPromotion = null
		state.promotion.endPromotion = null
		state.promotion.promotionStatus = null
		state.disableInRouteRedirects = false
		state.editionType = EditionType.ALL
		state.markedFields = []
	},
	...promotionProduct.mutations,
	...promotionCategory.mutations
}

export default {
	state,
	getters,
	actions,
	mutations
}
