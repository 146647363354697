<template>
	<div class="mkt-button" v-if="renderButton(button)" :class="button.size" v-tooltip="{ text: txTitle, show: !!txTitle }">
		<md-button
			class="mkt-button__button"
			:class="button.className"
			:disabled="isDisabled"
			:type="button.type"
			:md-ripple="false"
			@click="onClick"
		>
			<div class="mkt-button__button__intern-content" v-if="!isLoading">
				<span class="mkt-button__button__icon" :class="{ 'disabled': isDisabled, 'loading': isLoading }" v-if="button.icon">
					<mkt-icon :tx-icon="button.icon" :tx-font-size="button.iconSize"></mkt-icon>
				</span>
				<div class="mkt-button__button__label">
					{{ button.label }}
				</div>
			</div>
			<div v-else>
				<mkt-icon :tx-icon-color="button.loaderColor" class="mkt-button__button__loader" tx-icon="icon-cached" tx-font-size="32px"></mkt-icon>
			</div>
		</md-button>
	</div>
</template>

<script>
	import Button from 'pojos/Button'
	import _ from 'lodash-core'
	import { hasAuthority } from 'services/loggedUserService'

	export default {
		props: {
			button: {
				type: Button,
				required: true
			},
			blDisabled: {
				type: Boolean,
				required: false,
				default: false
			},
			txTitle: {
				required: false,
				default: null
			},
			value: { required: false }
		},
		data() {
			return {
				hasLoader: false
			}
		},
		methods: {
			renderButton(button) {
				const { if: conditional, authorization, model } = button

				const hasAuthorization = (!authorization || hasAuthority(authorization))
				const canRenderButton = (!conditional || conditional(model))

				return hasAuthorization && canRenderButton
			},
			onClick() {
				if (this.button.onClick) this.throttleClick(this.button.model || this.value)
			},
			startLoader(promise) {
				if (!this.button.hasLoading) return

				this.hasLoader = true
				promise.finally(() => {
					this.hasLoader = false
				})
			}
		},
		computed: {
			isLoading() {
				return this.hasLoader || (this.button.loading && this.button.loading())
			},
			isDisabled() {
				return (this.button.disabled && this.button.disabled()) || this.isLoading || this.blDisabled
			},
			throttleClick() {
				return _.throttle(this.button.onClick, this.button.throttle)
			}
		}
	}
</script>
<style src="./mktButton.scss" lang="scss">
</style>
