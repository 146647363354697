import {
	SET_PRODUCT_COLOMBO,
	SET_INITIAL_STATE_PRODUCT_COLOMBO,
	SET_PRODUCT_COLOMBO_CHARACTERISTIC,
	SET_PRODUCT_COLOMBO_VARIATION
} from 'constants/MutationTypes'

import ProductColomboDto from 'src/dtos/ProductColomboDto'

// initial state
const state = {
	initialState: new ProductColomboDto({}),
	productColombo: new ProductColomboDto({})
}

// getters
const getters = {
	getProductColombo: state => state.productColombo,
	getProductCharacteristics: state => state.productColombo && state.productColombo.productCharacteristics,
	getProductColomboInitialState: state => state.initialState
}

// actions
const actions = {
	setProductColombo: ({ commit }, productColombo) => {
		commit(SET_INITIAL_STATE_PRODUCT_COLOMBO, productColombo)
	},
	setProductColomboCharacteristics: ({ commit }, characteristic) => {
		commit(SET_PRODUCT_COLOMBO_CHARACTERISTIC, characteristic)
	},
	modifyProductColombo: ({ commit }, productColombo) => {
		commit(SET_PRODUCT_COLOMBO, productColombo)
	},
	modifyProductColomboVariation: ({ commit }, productVariation) => {
		commit(SET_PRODUCT_COLOMBO_VARIATION, productVariation)
	}
}

// mutations
const mutations = {
	[SET_INITIAL_STATE_PRODUCT_COLOMBO](state, productColombo) {
		state.initialState = state.productColombo = productColombo
	},
	[SET_PRODUCT_COLOMBO](state, productColombo) {
		state.productColombo = { ...state.productColombo, ...productColombo }
	},
	[SET_PRODUCT_COLOMBO_VARIATION](state, newProductVariation) {
		if (state.productColombo) {
			const newProductVariationIndex = state.productColombo.variations.map(variation => variation.productItemId).indexOf(newProductVariation.productItemId)
			const productVariation = state.productColombo.variations[newProductVariationIndex]
			newProductVariation.isModified = true

			state.productColombo.variations.splice(newProductVariationIndex, 1, { ...productVariation, ...newProductVariation })
		}
	},
	[SET_PRODUCT_COLOMBO_CHARACTERISTIC](state, characteristic) {
		const index = state.productColombo.characteristics.map(characteristic => characteristic.attributeId).indexOf(characteristic.attributeId)
		state.productColombo.characteristics.splice(index, 1, { ...state.productColombo.characteristics[index], ...characteristic })
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
