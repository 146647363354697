import store from 'src/store/store'
import { ROUTE_CHANGE_PASSWORD } from 'constants/RouterNames'
import { sendWarningNotification } from 'src/services/notificationService'

export default function requireAuth(to, from, next) {
	const user = store.getters.getLoggedUser

	if (user.mustRenewPassword) {
		next(`/${ROUTE_CHANGE_PASSWORD.name}`)
		user.firstAccess && sendWarningNotification('Bem vindo, esse é seu primeiro acesso, favor criar uma nova senha de acesso.')
	} else {
		next()
	}
}
