import {
	ROUTE_REBATE_LISTING_COLOMBO_DETAIL,
	ROUTE_REBATE_COLOMBO_DETAIL_PRODUCT,
	ROUTE_REBATE_COLOMBO_DETAIL_CATEGORY,
	ROUTE_REBATE_COLOMBO_EDIT_PRODUCT,
	ROUTE_REBATE_COLOMBO_EDIT_CATEGORY
} from 'constants/RouterNames'

const rebateDetailColombo = () => import('components/pages/secured/promotion/rebate/rebateColombo/rebateDetail/rebateDetail.vue')
const rebateDetailColomboProduct = () => import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseProduct/rebateBaseProduct.vue')
const rebateDetailColomboCategory = () => import('components/pages/secured/promotion/rebate/rebateBase/rebateBaseCategory/rebateBaseCategory.vue')

const rebateDetailColomboRoute = {
	path: '/detalhar-rebate-colombo/:rebateId',
	component: rebateDetailColombo,
	name: ROUTE_REBATE_LISTING_COLOMBO_DETAIL.name,
	meta: { requiresAuthorities: ROUTE_REBATE_LISTING_COLOMBO_DETAIL.routerAuth, breadcrumb: { name: 'Detalhamento', active: false } },
	children: [
		{ path: '/editar-rebate-colombo/:rebateId/produto', component: rebateDetailColomboProduct, name: ROUTE_REBATE_COLOMBO_EDIT_PRODUCT.name, meta: { breadcrumb: { name: 'Rebate por produto' }, requiresAuthorities: ROUTE_REBATE_COLOMBO_EDIT_PRODUCT.routerAuth } },
		{ path: '/editar-rebate-colombo/:rebateId/categoria', component: rebateDetailColomboCategory, name: ROUTE_REBATE_COLOMBO_EDIT_CATEGORY.name, meta: { breadcrumb: { name: 'Rebate por categoria' }, requiresAuthorities: ROUTE_REBATE_COLOMBO_EDIT_CATEGORY.routerAuth } },
		{ path: '/detalhar-rebate-colombo/:rebateId/produto', component: rebateDetailColomboProduct, name: ROUTE_REBATE_COLOMBO_DETAIL_PRODUCT.name, meta: { breadcrumb: { name: 'Rebate por produto' }, requiresAuthorities: ROUTE_REBATE_COLOMBO_DETAIL_PRODUCT.routerAuth } },
		{ path: '/detalhar-rebate-colombo/:rebateId/categoria', component: rebateDetailColomboCategory, name: ROUTE_REBATE_COLOMBO_DETAIL_CATEGORY.name, meta: { breadcrumb: { name: 'Rebate por categoria' }, requiresAuthorities: ROUTE_REBATE_COLOMBO_DETAIL_CATEGORY.routerAuth } }
	]
}

export default rebateDetailColomboRoute
