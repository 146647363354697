import {
	ROUTE_CONFIGURATION_ACCESS_PROFILE,
	ROUTE_CONFIGURATION_ACCESS_PROFILE_LIST,
	ROUTE_CONFIGURATION_ACCESS_PROFILE_REGISTER,
	ROUTE_CONFIGURATION_ACCESS_PROFILE_DETAIL
} from 'constants/RouterNames'

const accessProfile = () => import('src/components/pages/secured/configuration/accessProfile/accessProfile')
const accessProfileList = () => import('src/components/pages/secured/configuration/accessProfile/accessProfileList/accessProfileList')
const accessProfileRegister = () => import('src/components/pages/secured/configuration/accessProfile/accessProfileRegister/accessProfileRegister')
const accessProfileDetail = () => import('src/components/pages/secured/configuration/accessProfile/accessProfileDetail/accessProfileDetail')

const accessProfileRoute = {
	path: '/perfis-de-acesso',
	redirect: '/listagem-perfis-de-acesso',
	component: accessProfile,
	name: ROUTE_CONFIGURATION_ACCESS_PROFILE.name,
	meta: { breadcrumb: { name: 'Perfis de acesso' }, requiresAuthorities: ROUTE_CONFIGURATION_ACCESS_PROFILE.routerAuth },
	children: [
		{ path: '/listagem-perfis-de-acesso', component: accessProfileList, name: ROUTE_CONFIGURATION_ACCESS_PROFILE_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_ACCESS_PROFILE_LIST.routerAuth } },
		{ path: '/cadastro-perfis-de-acesso', component: accessProfileRegister, name: ROUTE_CONFIGURATION_ACCESS_PROFILE_REGISTER.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_CONFIGURATION_ACCESS_PROFILE_REGISTER.routerAuth } },
		{ path: '/detalhe-perfis-de-acesso/:accessProfileId', props: true, component: accessProfileDetail, name: ROUTE_CONFIGURATION_ACCESS_PROFILE_DETAIL.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_CONFIGURATION_ACCESS_PROFILE_DETAIL.routerAuth } }
	]
}

export default accessProfileRoute
