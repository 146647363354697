import { SET_SELLER_ABOUT_COMPANY } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerAboutCompany = new SellerRegistrationBaseModule('sellerAboutCompany', SET_SELLER_ABOUT_COMPANY)

const state = sellerAboutCompany.getState()
const initialState = sellerAboutCompany.getInitialState()
const getters = sellerAboutCompany.getGetters()
const actions = sellerAboutCompany.getSetters()
const mutations = sellerAboutCompany.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
