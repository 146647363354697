import Vue from 'vue'

import mktTableHeaderInput from './mktTableHeaderInput/mktTableHeaderInput'
import mktTableJoinVariationData from './mktTableJoinData/mktTableJoinVariationData'
import mktTableStatusIcons from './mktTableIcons/mktTableStatusIcons'
import mktProductModerationStatus from './mktTableIcons/mktProductModerationStatus'
import mktProductPreview from './mktTableIcons/mktProductPreview'

Vue.component('mkt-table-header-input', mktTableHeaderInput)
Vue.component('mkt-table-join-variation-data', mktTableJoinVariationData)
Vue.component('mkt-table-status-icons', mktTableStatusIcons)
Vue.component('mkt-product-moderationStatus', mktProductModerationStatus)
Vue.component('mkt-product-preview', mktProductPreview)
