import Enum from './Domain'

class MonthType extends Enum { }

MonthType.initEnum({
	JANUARY: {
		description: 'Janeiro'
	},
	FEBRUARY: {
		description: 'Fevereiro'
	},
	MARCH: {
		description: 'Março'
	},
	APRIL: {
		description: 'Abril'
	},
	MAY: {
		description: 'Maio'
	},
	JUNE: {
		description: 'Junho'
	},
	JULY: {
		description: 'Julho'
	},
	AUGUST: {
		description: 'Agosto'
	},
	SEPTEMBER: {
		description: 'Setembro'
	},
	OCTOBER: {
		description: 'Outubro'
	},
	NOVEMBER: {
		description: 'Novembro'
	},
	DECEMBER: {
		description: 'Dezembro'
	}
})

export default MonthType
