import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Sac = new AuthorityBuilder({
	accessGroup: 'SAC',
	accessType: ['ACCESS']
})

const CustomerService = new AuthorityBuilder({
	accessGroup: 'SAC_CUSTOMER_SERVICE',
	accessType: ['ACCESS', 'LIST_READ', 'EDIT_READ', 'EDIT_WRITE', 'LIST_EXPORT_READ']
})

const Notification = new AuthorityBuilder({
	accessGroup: 'SAC_NOTIFICATION',
	accessType: ['ACCESS', 'ORDER_READ', 'PRODUCT_READ', 'SAC_READ', 'MARKETPLACE_SERVICE_READ']
})

const MarketplaceService = new AuthorityBuilder({
	accessGroup: 'SAC_MARKETPLACE_SERVICE',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ', 'ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE']
})

export default [
	Sac,
	CustomerService,
	Notification,
	MarketplaceService
]
