import Vue from 'vue'
import app from 'src/app'

import router from 'src/router/routerConfig'
import store from 'src/store/store'

import 'src/components/'
import 'src/directives/'
import 'src/filters/'
import 'src/assets/'
import 'src/override/'

import 'src/services/localStorageListenerService.js'

export default new Vue({
	el: '#app',
	router,
	store,
	render: h => h(app)
})
