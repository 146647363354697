import { SET_SELLER_BANK_DETAILS } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerBankDetails = new SellerRegistrationBaseModule('sellerBankDetails', SET_SELLER_BANK_DETAILS, { splitFlow: null })

const state = sellerBankDetails.getState()
const initialState = sellerBankDetails.getInitialState()
const getters = sellerBankDetails.getGetters()
const actions = sellerBankDetails.getSetters()
const mutations = sellerBankDetails.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
