<template>
	<div class="mkt-dropdown" v-click-outside="closeList" :class="{ disabled: blDisabled }">
		<md-field :class="{ 'md-focused': isFocused }">
			<div @click="toggleList" class="mkt-dropdown__text">
				{{ txLabel }}
			</div>
			<span class="mkt-dropdown__list--icon" @click="toggleList">
				<mkt-icon :tx-icon="isListOpen ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'" :bl-hover="true" tx-font-size="10px"></mkt-icon>
			</span>
		</md-field>
		<div class="mkt-dropdown__content" v-if="isListOpen">
			<slot name="dropdown-content">
			</slot>
		</div>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'

	export default {
		name: 'mkt-dropdown',
		props: {
			txLabel: new PropConfig({ type: String, required: false }),
			blDisabled: new PropConfig({ type: Boolean, required: false, valueDefault: false })
		},
		data() {
			return {
				isListOpen: false,
				isFocused: false
			}
		},
		methods: {
			closeList() {
				this.isListOpen = this.isFocused = false
			},
			toggleList() {
				if (this.blDisabled) return
				this.isFocused = !this.isFocused
				this.isListOpen = !this.isListOpen
			}
		}
	}
</script>
<style lang="scss" src="./mktDropdown.scss">
</style>
