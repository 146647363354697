'use strict'

export default class AuthorityBuilder {
	constructor({ baseAuthority, accessGroup, accessType }) {
		this.baseAuthority = baseAuthority
		this.accessGroup = accessGroup
		this.accessType = accessType
	}

	setBaseAuthority(baseAuthority) {
		this.baseAuthority = baseAuthority
	}

	setGroupAccess(accessGroup) {
		this.accessGroup = accessGroup
	}

	build() {
		return this.accessType.map(type => [this.baseAuthority, this.accessGroup, type].join('_'))
	}
}
