const mktPaginationMixin = {
	props: {
		blSearchOnLoad: { type: Boolean, required: false, default: true },
		nrCurrentPage: { type: Number, required: false, default: 0 },
		nrRecordsPerPage: { type: Number, required: false, default: 8 }
	},
	data() {
		return {
			currentPage: this.nrCurrentPage,
			totalPages: null,
			pageSize: this.nrRecordsPerPage
		}
	},
	mounted() {
		this.blSearchOnLoad && this.loadPaginateData()
		this.$emit('mkt-pagination:ready')
	},
	methods: {
		resetPaginator() {
			this.totalPages = 0
			this.lastCall = ''
			this.currentPage = 0
		},
		setCurrentPage(page) {
			this.currentPage = page
			this.loadPaginateData()
		},
		previousPage() {
			if (this.currentPage > 0) {
				this.currentPage--
				this.loadPaginateData()
			}
		},
		nextPage() {
			if (this.totalPages > this.currentPage + 1) {
				this.currentPage++
				this.loadPaginateData()
			}
		}
	},
	computed: {
		paginate() {
			const currentPage = this.currentPage
			const pager = [currentPage]
			let iterator = 0

			while (iterator < 4 && (iterator < this.totalPages - 1)) {
				iterator++
				let previousPage = pager[0] - 1
				const lastPage = pager[pager.length - 1]
				if ((previousPage < 0 || iterator > 2) && (lastPage + 1) < this.totalPages) {
					pager.push(lastPage + 1)
				} else {
					const firstPage = pager[0]
					pager.unshift(firstPage - 1)
				}
			}

			return pager
		}
	}
}

export default mktPaginationMixin
