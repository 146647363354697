import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const ConfigurationFreight = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FREIGHT',
	accessType: ['ACCESS', 'LIST_READ', 'EDIT_WRITE', 'EDIT_READ']
})

const ConfigurationGeneral = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_GENERAL',
	accessType: ['READ', 'WRITE', 'ACCESS']
})

const ConfigurationSellers = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_SELLERS',
	accessType: [
		'ACCESS',
		'EDIT_ABOUT_STORE_READ',
		'EDIT_ABOUT_STORE_WRITE',
		'EDIT_COMMISSION_READ',
		'EDIT_COMMISSION_WRITE',
		'EDIT_DATA_READ',
		'EDIT_DATA_WRITE',
		'EDIT_FINANCIAL_READ',
		'EDIT_FINANCIAL_WRITE',
		'EDIT_INTEGRATION_READ',
		'EDIT_INTEGRATION_WRITE'
	]
})

const ConfigurationUsers = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_USERS',
	accessType: ['ADD_WRITE', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'PERMISSION_ACCESS']
})

const ConfigurationFinancial = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FINANCIAL',
	accessType: ['ACCESS', 'EXTRACT_ACCESS', 'EXTRACT_LIST_READ', 'EXTRACT_LIST_EXPORT_READ']
})

const ConfigurationAnticipation = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_ANTICIPATION',
	accessType: ['ACCESS', 'LIST_READ']
})

const ConfigurationAnticipationScheduled = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_ANTICIPATION_SCHEDULED',
	accessType: ['ACCESS', 'LIST_READ']
})

const ConfigurationFinancialReport = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION_FINANCIAL_SPLIT_FINANCIAL_REPORT',
	accessType: ['ACCESS', 'LIST_READ', 'LIST_EXPORT_READ']
})

const Configuration = new AuthorityBuilder({
	accessGroup: 'CONFIGURATION',
	accessType: ['ACCESS']
})

export default [
	Configuration,
	ConfigurationFreight,
	ConfigurationGeneral,
	ConfigurationSellers,
	ConfigurationUsers,
	ConfigurationFinancial,
	ConfigurationAnticipation,
	ConfigurationAnticipationScheduled,
	ConfigurationFinancialReport
]
