import Enum from './Domain'

class InputType extends Enum { }

InputType.initEnum({
	TEXT: {
		code: 'text',
		type: 'text'
	},
	NUMBER: {
		code: 'number',
		type: 'text'
	},
	EMAIL: {
		code: 'email',
		type: 'email'
	},
	PASSWORD: {
		code: 'password',
		type: 'password'
	},
	STRING: {
		code: 'string',
		type: 'string'
	},
	DOMAIN: {
		code: 'domain',
		type: 'domain'
	}
})

export default InputType
