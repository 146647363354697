
'use strict'
/**
 * Dto contendo configurações possíves para props de componentes.
 *
 * @constructor
 * @param {Any} type - função de construtor ou options [String, Number, Boolean, Function, Object, Array, Symbol].
 * @param {Boolean} required  - true or false, define se campo é obrigatório
 * @param valueDefault  - define valor default para prop.
 * @param {Function} validator  - define valor default para prop.
 */

export default class PropConfig {
	constructor({ type, required, valueDefault, validator }) {
		this.type = type
		this.required = required
		this.default = valueDefault
		this.validator = validator
	}
}
