import {
	ROUTE_PROMOTION_DETAIL,
	ROUTE_PROMOTION_DETAIL_PRODUCT,
	ROUTE_PROMOTION_DETAIL_CATEGORY
} from 'constants/RouterNames'

const promotionDetail = () => import('components/pages/secured/promotion/promotionSeller/promotionDetail/promotionDetail.vue')
const promotionDetailProduct = () => import('components/pages/secured/promotion/promotionBase/promotionBaseProduct/promotionBaseProduct.vue')
const promotionDetailCategory = () => import('components/pages/secured/promotion/promotionBase/promotionBaseCategory/promotionBaseCategory.vue')

const promotionDetailRoute = {
	path: '/detalhar-promocao/:promotionId',
	component: promotionDetail,
	name: ROUTE_PROMOTION_DETAIL.name,
	meta: { breadcrumb: { name: 'Detalhamento', active: false } },
	children: [
		{ path: '/detalhar-promocao/:promotionId/produto', component: promotionDetailProduct, name: ROUTE_PROMOTION_DETAIL_PRODUCT.name, meta: { breadcrumb: { name: 'Promoção por produto' }, requiresAuthorities: ROUTE_PROMOTION_DETAIL_PRODUCT.routerAuth } },
		{ path: '/detalhar-promocao/:promotionId/categoria', component: promotionDetailCategory, name: ROUTE_PROMOTION_DETAIL_CATEGORY.name, meta: { breadcrumb: { name: 'Promoção por categoria' }, requiresAuthorities: ROUTE_PROMOTION_DETAIL_CATEGORY.routerAuth } }
	]
}

export default promotionDetailRoute
