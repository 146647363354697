import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Promotion = new AuthorityBuilder({
	accessGroup: 'PROMOTION',
	accessType: ['ACCESS', 'PROMOTION_ACCESS', 'EDIT_READ', 'EDIT_WRITE', 'LIST_READ', 'LIST_EXPORT_READ']
})

const PromotionModeration = new AuthorityBuilder({
	accessGroup: 'PROMOTION_MODERATION',
	accessType: ['ACCESS', 'EDIT_READ', 'LIST_READ']
})

const Rebate = new AuthorityBuilder({
	accessGroup: 'REBATE',
	accessType: ['ACCESS', 'LIST_READ', 'EDIT_READ', 'ADD_WRITE', 'EDIT_WRITE']
})

const RebateBulkAction = new AuthorityBuilder({
	accessGroup: 'REBATE_BULK_ACTIONS_UPLOAD',
	accessType: ['ACCESS', 'READ', 'WRITE']
})

const RebateBulkActionLogs = new AuthorityBuilder({
	accessGroup: 'REBATE_BULK_ACTIONS_UPLOAD_LOGS',
	accessType: ['ACCESS', 'READ']
})

export default [
	Promotion,
	PromotionModeration,
	Rebate,
	RebateBulkAction,
	RebateBulkActionLogs
]
