import {
	ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY,
	ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY_LIST
} from 'constants/RouterNames'

const reportBillingByCategory = () => import('components/pages/secured/reports/reportBillingByCategory/reportBillingByCategory')
const reportBillingByCategoryList = () => import('components/pages/secured/reports/reportBillingByCategory/reportBillingByCategorySeller/reportBillingByCategoryList/reportBillingByCategoryList')

const reportBillingByCategoryRoute = {
	path: '/report-billing-by-category-seller',
	redirect: '/relatorio-faturamento-por-categoria-seller',
	component: reportBillingByCategory,
	name: ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY.name,
	meta: { breadcrumb: { name: 'Faturamento por Categoria' }, requiresAuthorities: ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY.routerAuth },
	children: [
		{ path: '/relatorio-faturamento-por-categoria-seller', component: reportBillingByCategoryList, name: ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_SELLER_REPORTS_BILLING_BY_CATEGORY_LIST.routerAuth } }
	]
}

export default reportBillingByCategoryRoute
