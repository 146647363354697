<template>
	<div class="mkt-pagination" v-if="totalPages && totalPages > 1">
		<div :class="{ 'disabled': currentPage <= 0 }">
			<a @click.prevent="previousPage" href="#">
				<mkt-icon :bl-hover="true" tx-font-size="10px" :tx-icon="'icon-chevron-left'"></mkt-icon>
			</a>
		</div>

		<div v-for="(page, index) in paginate" :key="index" :class="{ 'active': page === currentPage }">
			<a @click.prevent="() => setCurrentPage(page)" href="#">{{page + 1}}</a>
		</div>

		<div :class="{ 'disabled': currentPage === totalPages -1 }">
			<a @click.prevent="nextPage" href="#">
				<mkt-icon :bl-hover="true" tx-font-size="10px" :tx-icon="'icon-chevron-right'"></mkt-icon>
			</a>
		</div>
	</div>
</template>
<script>
import mktPaginationMixin from '../mktPaginationMixin'

export default {
	name: 'mkt-paginate-list',
	mixins: [mktPaginationMixin],
	props: {
		paginatedDataList: { type: Array, required: false, default: () => [] }
	},
	mounted() {
		this.setTotalPages(this.paginatedDataList)
		this.blSearchOnLoad && this.loadPaginateData()
		this.$emit('mkt-pagination:ready')
	},
	data() {
		return {
			events: {
				onListPaginate: (page) => this.$emit('mkt-pagination:on-list-paginate', page)
			}
		}
	},
	methods: {
		loadPaginateData() {
			this.events.onListPaginate(this.paginatedDataList.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize))
		},
		setTotalPages(paginationDataList) {
			this.totalPages = Math.ceil(paginationDataList.length / this.pageSize)
		}
	},
	watch: {
		paginatedDataList(val) {
			this.setTotalPages(val)
			this.loadPaginateData()
		}
	}
}
</script>
<style src="../mktPagination.scss" lang="scss">
</style>
