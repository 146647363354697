<template>
  <div class="mkt-table-header-input">
		<span v-if="blReadOnly">
			{{ title }}
		</span>
		<span class="mkt-table-header-input__input" v-else>
			<mkt-input-decimal
				v-if="rowData.type === 'DECIMAL'"
				:tx-label="title"
				v-model="rowData.value"
				tx-id="rowData.value"
				:tx-suffix="rowData.suffix"
				:nr-maxlength="rowData.maxLength"
				tx-placeholder=" "
			></mkt-input-decimal>
			<mkt-field
				v-else-if="rowData.type === 'INPUT'"
				v-model="rowData.value"
				:tx-label="title"
				tx-id="rowData.value"
				:tp-input-type="rowData.inputType"
				:nr-maxlength="rowData.maxLength"
				tx-placeholder=" "
			></mkt-field>
			<mkt-input-money
				v-else-if="rowData.type === 'MONEY'"
				v-model="rowData.value"
				:tx-label="title"
				tx-id="rowData.value"
				:nr-maxlength="rowData.maxLength"
				tx-placeholder=" "
			></mkt-input-money>
			<mkt-icon
				tx-font-size="22px"
				v-tooltip="{ text: 'Atualizar os valores individuais abaixo', show: true, style: { maxWidth: '400px' }, options: { appendTo: 'document' } }"
				tx-icon="icon-arrow_downward"
				@mkt-icon:icon-clicked="onClick"
			></mkt-icon>
		</span>
  </div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-table-header-input',
	props: {
		rowData: new PropConfig({
			type: Object,
			required: true
		}),
		blReadOnly: {
			type: Boolean,
			required: false,
			default: false
		},
		rowIndex: new PropConfig({
			type: Number
		})
	},
	methods: {
		extractTitle: function(string) {
			return string.split(':')[2]
		},
		extractName: function(string) {
			const nameSplit = string.split(':')
			return nameSplit[nameSplit.length - 1]
		},
		onClick() {
			if (this.rowData.onClick) {
				const headerName = this.extractName(this.rowData.name)
				const headerValue = this.rowData.suffix ? this.rowData.value.replace(this.rowData.suffix, '') : this.rowData.value
				this.rowData.onClick(headerName, headerValue)
			}
		}
	},
	computed: {
		title() {
			return this.extractTitle(this.rowData.title)
		}
	}
}
</script>

<style src="./mktTableHeaderInput.scss" lang="scss">
</style>
