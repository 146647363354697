<script>
// Importing Bar class from the vue-chartjs wrapper
import { Bar } from 'vue-chartjs'
import PropConfig from 'pojos/PropConfig'

// Exporting this so it can be used in other components
export default {
	extends: Bar,
	props: {
		labels: new PropConfig({ required: true }),
		datasets: new PropConfig({ required: true }),
		yAxesCallback: new PropConfig({ type: Function, required: false }),
		tooltipValueCallback: new PropConfig({ type: Function, required: false })
	},
	data() {
		return {
			datacollection: {
				// Data to be represented on x-axis
				labels: this.labels,
				datasets: this.datasets
			},
			// Chart.js options that controls the appearance of the chart
			options: {
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							let label = data.datasets[tooltipItem.datasetIndex].label || ''
							label += `: ${this.tooltipValueCallback ? this.tooltipValueCallback(tooltipItem.yLabel) : tooltipItem.yLabel}`
							return label
						}
					}
				},
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true,
							callback: (value, index, values) => {
								return this.yAxesCallback ? this.yAxesCallback(value, index, values) : value
							}
						},
						gridLines: {
							display: true
						}
					}],
					xAxes: [{
						gridLines: {
							display: false
						}
					}]
				},
				legend: {
					display: true,
					position: 'bottom',
					fontFamily: 'Roboto'
				},
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	mounted() {
		// renderChart function renders the chart with the datacollection and options object.
		this.renderChart(this.datacollection, this.options)
	}
}
</script>
