import {
	ROUTE_CONFIGURATION_FREIGHT
} from 'constants/RouterNames'

import configurationFreightColombo from './configurationFreightColombo'
import configurationFreightSeller from './configurationFreightSeller'

const freight = () => import('components/pages/secured/configuration/freight/freight')

const configurationFreightRoute = {
	path: '/frete',
	meta: { breadcrumb: { name: 'Frete', active: false } },
	component: freight,
	name: ROUTE_CONFIGURATION_FREIGHT.name,
	children: [
		configurationFreightColombo,
		configurationFreightSeller
	]
}

export default configurationFreightRoute
