<template>
  <div class="mkt-table-join-data" @click="eventEmitClick">
		{{ joinVariationData(rowData) }}
  </div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-table-join-variation-data',
	props: {
		rowData: new PropConfig({
			type: Object,
			required: true
		}),
		rowIndex: new PropConfig({
			type: Number
		})
	},
	methods: {
		joinVariationData(rowData) {
			const variationData = [rowData.color, rowData.size, rowData.voltage]
			return variationData.filter(variation => !!variation).join(', ')
		},
		eventEmitClick(event) {
			this.$parent.onCellClicked(this.rowData, 'mkt-table-join-data', event)
		}
	}
}
</script>

<style src="./mktTableJoinVariationData.scss" lang="scss">
</style>
