export const REGEX_NOT_NUMBER = /[^0-9.]/g
export const REGEX_NOT_PURE_NUMBER = /\.|,|\D/g
export const REGEX_CEP_FORMAT = /^(\d{5})(\d)/
export const REGEX_DECIMAL_FORMAT = /(.*)(\d{2})/g
export const REGEX_EMAIL_FORMAT = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_LETTERS_AND_NUMBERS = /^(?=.*[a-zA-Z])(?=.*[0-9])/
export const REGEX_NUMBERS = /^\d+$/
export const REGEX_MATCH_FIRST_LETTER = /\w{1}/
export const REGEX_BR_OR_LINE_BREAK = /\n|<br\s*\/?>/
export const REGEX_NOT_NUMBER_EXCLUDING_NEGATIVE = /[^-?\d*\.?\d+]/g
