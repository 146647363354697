// imports de libs
import './bootstrap'
import './slider'

/*
    Estilo de classes globais da aplicação.
    Overrides de classes de bibliotecas dependentes devem ser realizadas em seu respectivo pacote
*/
import './style.scss'
import './animations.scss'
