import accounting from 'accounting-js'

accounting.settings.format = '%s %v'
accounting.settings.symbol = 'R$'
accounting.settings.decimal = ','
accounting.settings.thousand = '.'

export function customFormatMoney(val, { symbol, decimal, thousand }) {
	return accounting.formatMoney(val, { symbol, decimal, thousand })
}

export function toFixed(val) {
	return accounting.toFixed(val)
}

export function formatMoney(val = 0) {
	return accounting.formatMoney(val)
}

export function unformatMoney(val, limitator) {
	return accounting.unformat(val, limitator)
}
