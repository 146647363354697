import {
	ROUTE_FINANCIAL_REPORT_SELLER,
	ROUTE_FINANCIAL_REPORT_LIST_SELLER
} from 'constants/RouterNames'

const financialReport = () => import('components/pages/secured/financial/financialReport/financialReportSeller/financialReportSeller')
const financialReportSellerList = () => import('components/pages/secured/financial/financialReport/financialReportSeller/financialReportSellerList/financialReportSellerList')

const financialReportSeller = {
	path: '/agenda-detalhada',
	redirect: '/listagem-agenda-detalhada',
	meta: { requiresAuthorities: ROUTE_FINANCIAL_REPORT_SELLER.routerAuth },
	component: financialReport,
	name: ROUTE_FINANCIAL_REPORT_SELLER.name,
	children: [
		{ path: '/listagem-agenda-detalhada', component: financialReportSellerList, name: ROUTE_FINANCIAL_REPORT_LIST_SELLER.name, meta: { breadcrumb: { name: 'Agenda detalhada' }, requiresAuthorities: ROUTE_FINANCIAL_REPORT_LIST_SELLER.routerAuth } }
	]
}

export default financialReportSeller
