<template>
  <form class="mkt-form" ref="form" @submit.prevent="onSubmit" novalidate>
    <slot></slot>
		<div class="mkt-form__actions" v-if="hasActions">
			<mkt-button :button="buttonCancel"></mkt-button>
			<mkt-button :button="buttonRegister"></mkt-button>
		</div>
  </form>
</template>
<script>
import { isNullOrUndefined } from 'services/objectService.js'
import { verticalScrollToElement } from 'services/scrollService.js'
import { sendErrorNotification } from 'services/notificationService'
import { getFirstElementVertically } from 'services/formErrorsService.js'
import Button from 'pojos/Button'

export default {
	name: 'mkt-form',
	props: {
		hasActions: { type: Boolean, required: false, default: false },
		submitButtonLabel: { type: String, required: false, default: 'Cadastrar' },
		cancelButtonLabel: { type: String, required: false, default: 'Cancelar' },
		submitButtonAuthority: { type: String, required: false }
	},
	data() {
		return {
			buttonCancel: new Button({
				label: this.cancelButtonLabel,
				className: 'md-dense mkt-filter-button',
				onClick: this.cancel
			}),
			buttonRegister: new Button({
				label: this.submitButtonLabel,
				className: 'md-primary md-raised mkt-filter-button',
				authorization: this.submitButtonAuthority,
				type: 'submit'
			})
		}
	},
	methods: {
		onSubmit() {
			const form = this.$refs.form
			const elements = [...form.elements]
			const elementsWithErrors = elements
				.map(this.mapElementsWithErrors)
				.filter(el => !isNullOrUndefined(el))

			if (!elementsWithErrors.length) this.$emit('submit')
			else {
				const topMostElement = elementsWithErrors
					.map(this.mapVueInstanceToElement)
					.reduce(getFirstElementVertically)
				sendErrorNotification('Verifique o preenchimento dos campos sinalizados')

				this.$nextTick(() => {
					verticalScrollToElement(topMostElement)
				})
			}
		},
		cancel() {
			this.$emit('mkt-form:cancel')
		},
		openContainer(el) {
			if (!el) return
			else if (el.isCollapsed) {
				el.openBox()
			}
			return this.openContainer(el.$parent)
		},
		mapVueInstanceToElement(el) {
			el.hasErrors = true
			this.openContainer(el)
			return el.$el
		},
		mapElementsWithErrors(el) {
			const elVueInstance = this.findFormControl(el.__vue__)
			if (elVueInstance) {
				const isReadOnly = elVueInstance.blReadonly || false
				const hasErrors = elVueInstance.hasErrors
				const hasValue = !!elVueInstance.value

				if ((!hasValue && !isReadOnly) || hasErrors) {
					return elVueInstance
				}
			}
		},
		findFormControl(el) {
			if (!el || this === el) return null
			else if (isNullOrUndefined(el.blRequired) || isNullOrUndefined(el.hasErrors)) return this.findFormControl(el.$parent)
			else if (el.blRequired || el.hasErrors) return el
			else return null
		}
	}
}
</script>
<style src="./mktForm.scss" lang="scss">
</style>
