import {
	ROUTE_CONFIGURATION_GENERAL
} from 'constants/RouterNames'

const configurationGeneral = () => import('src/components/pages/secured/configuration/general/configurationGeneral')

const configurationGeneralRoute = {
	path: '/configuracao-geral',
	component: configurationGeneral,
	name: ROUTE_CONFIGURATION_GENERAL.name,
	meta: { breadcrumb: { name: 'Configurações Padrão' }, requiresAuthorities: ROUTE_CONFIGURATION_GENERAL.routerAuth }
}

export default configurationGeneralRoute
