<template>
  <div class="mkt-input-email">
		<md-field :class="fieldClasses">
			<mkt-label :txLabel="txLabel" class="mkt-label__input"></mkt-label>
			<md-input
			:placeholder="txPlaceholder"
			type="email"
			:required="blRequired"
			@input="updateParent"
			v-model="fieldModel"
			@blur="validateEmailFormat">
			</md-input>
			<span class="md-error">{{txErrorMessage || 'Campo obrigatório'}}</span>
		</md-field>
  </div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'
import { REGEX_EMAIL_FORMAT } from 'constants/Regex'

export default {
	name: 'mkt-input-email',
	data() {
		return {
			fieldModel: this.value,
			emailFormat: true
		}
	},
	props: {
		txFieldClass: new PropConfig({ type: String, required: false }),
		txPlaceholder: new PropConfig({ type: String, required: false }),
		txLabel: new PropConfig({ type: String, required: true }),
		txErrorMessage: new PropConfig({ type: String, valueDefault: 'Email com formato inválido', required: false }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		value: new PropConfig({ required: false })
	},
	methods: {
		updateParent(value) {
			this.$emit('input', value)
		},
		validateEmailFormat() {
			this.emailFormat = !this.fieldModel || REGEX_EMAIL_FORMAT.test(this.fieldModel)
		}
	},
	mounted() {
		this.validateEmailFormat()
	},
	computed: {
		fieldClasses() {
			return {
				'md-invalid': !this.emailFormat && this.fieldModel,
				[this.txFieldClass]: this.txFieldClass
			}
		}
	}
}
</script>
