
import {
	ROUTE_CONFIGURATION_MDR
	, ROUTE_CONFIGURATION_MDR_LIST
	, ROUTE_CONFIGURATION_MDR_REGISTER
	, ROUTE_CONFIGURATION_MDR_DETAIL
	, ROUTE_CONFIGURATION_MDR_DUPLICATE
} from 'constants/RouterNames'

const mdr = () => import('src/components/pages/secured/configuration/mdr/mdr')
const mdrList = () => import('src/components/pages/secured/configuration/mdr/mdrList/mdrList')
const mdrRegister = () => import('src/components/pages/secured/configuration/mdr/mdrRegister/mdrRegister')
const mdrDetail = () => import('src/components/pages/secured/configuration/mdr/mdrDetail/mdrDetail')
const mdrDuplicate = () => import('src/components/pages/secured/configuration/mdr/mdrDuplicate/mdrDuplicate')

const mdrRoute = {
	path: '/mdr',
	redirect: '/listagem-mdr',
	component: mdr,
	name: ROUTE_CONFIGURATION_MDR.name,
	meta: { breadcrumb: { name: 'MDR' }, requiresAuthorities: ROUTE_CONFIGURATION_MDR.routerAuth },
	children: [
		{ path: '/listagem-mdr', component: mdrList, name: ROUTE_CONFIGURATION_MDR_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_MDR_LIST.routerAuth } },
		{ path: '/cadastro-mdr', component: mdrRegister, name: ROUTE_CONFIGURATION_MDR_REGISTER.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_CONFIGURATION_MDR_REGISTER.routerAuth } },
		{ path: '/detalhe-mdr/:mdrId', props: true, component: mdrDetail, name: ROUTE_CONFIGURATION_MDR_DETAIL.name, meta: { breadcrumb: { name: 'Edição' }, requiresAuthorities: ROUTE_CONFIGURATION_MDR_DETAIL.routerAuth } },
		{ path: '/duplicar-mdr/:mdrId', props: true, component: mdrDuplicate, name: ROUTE_CONFIGURATION_MDR_DUPLICATE.name, meta: { breadcrumb: { name: 'Duplicar' }, requiresAuthorities: ROUTE_CONFIGURATION_MDR_DUPLICATE.routerAuth } }
	]
}

export default mdrRoute
