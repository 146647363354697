import { Get } from 'httpServices/baseHttpService.js'

const transformDataUrlIntoBlobType = (dataUrl) => {
	const base64data = dataUrl.split(',')[1]
	const mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0]

	const byteCharacters = atob(base64data)
	const byteNumbers = new Array(byteCharacters.length)

	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}

	const byteArray = new Uint8Array(byteNumbers)
	return new Blob([byteArray], { type: mimeType })
}

const loadFileFromUrl = (url) => {
	return Get(url, {}, { responseType: 'arraybuffer',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET'
		} })
}

const openFile = (file, type) => {
	if (file instanceof File || file instanceof Blob) {
		openBlobFile(file)
	} else if (file instanceof ArrayBuffer) {
		const blob = new Blob([file], { type })
		openBlobFile(blob)
	} else {
		throw new Error('Not a valid format')
	}
}

const openBlobFile = (blobFile) => {
	if (blobFile instanceof File || blobFile instanceof Blob) {
		const _URL = URL || window.webkitURL
		window.open(_URL.createObjectURL(blobFile), '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes')
	} else {
		throw new Error('Not a blob file')
	}
}

const validateFileMimeType = (file, mimeType) => {
	const binaryArray = (new Uint8Array(file)).subarray(0, 4)
	let fileSignature = ''

	binaryArray.forEach(binary => {
		fileSignature += binary.toString(16)
	})

	return mimeType.fileSignature.includes(fileSignature)
}

const validateFileBlobType = (file, mimeType) => {
	return mimeType.code.includes(file.type)
}

export {
	validateFileBlobType,
	validateFileMimeType,
	openFile,
	loadFileFromUrl,
	transformDataUrlIntoBlobType
}
