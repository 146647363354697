import {
	ROUTE_ORDER
} from 'constants/RouterNames'

// order
import orderColomboRoute from './orderColombo'
import orderSellerRoute from './orderSeller'
import orderBulkActionRoute from './orderBulkActions'
const order = () => import('components/pages/secured/order/order')

const orderRoute = {
	path: '/order',
	component: order,
	name: ROUTE_ORDER.name,
	meta: { breadcrumb: { name: 'Vendas', active: false } },
	children: [
		orderColomboRoute,
		orderSellerRoute,
		orderBulkActionRoute
	]
}

export default orderRoute
