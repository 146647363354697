import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const Products = new AuthorityBuilder({
	accessGroup: 'PRODUCTS',
	accessType: ['ACCESS']
})

const ProductsBulkActionsUpload = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_BULK_ACTIONS_UPLOAD',
	accessType: ['ACCESS', 'READ', 'WRITE', 'LOGS_ACCESS', 'LOGS_READ']
})

const ProductsNewProduct = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_NEW_PRODUCT',
	accessType: ['ACCESS', 'WRITE']
})

const ProductsProducts = new AuthorityBuilder({
	accessGroup: 'PRODUCTS_PRODUCTS',
	accessType: ['EDIT_READ', 'EDIT_WRITE', 'EDIT_APPROVAL_SELLER_READ', 'EDIT_APPROVAL_SELLER_WRITE', 'EDIT_CHANGE_STATUS_READ', 'EDIT_CHANGE_STATUS_WRITE', 'LIST_READ', 'LIST_EXPORT_READ', 'LIST_CHANGE_SITUATION_WRITE', 'LIST_APPROVAL_SELLER_WRITE', 'ACCESS']
})

export default [
	Products,
	ProductsBulkActionsUpload,
	ProductsNewProduct,
	ProductsProducts
]
