const calculateCenter = (side1, side2) => {
	const sideBig = side1 > side2 ? side1 : side2
	const sideSmall = side1 < side2 ? side1 : side2
	return (((sideBig - sideSmall) / 2))
}

const calculateOffset = (position, elementRect, defaultSpacing) => {
	let offsetX = 0
	let offsetY = 0

	if (position === 'top' || position === 'bottom') {
		offsetX += calculateCenter(elementRect.left, elementRect.right)
		offsetY += defaultSpacing
	}

	return { offsetX, offsetY }
}

const calculatePosition = (position, { offsetX, offsetY }, { tooltipRect, eleRect } = {}) => {
	if (position === 'top') {
		if (eleRect) {
			// TODO Rafael Henrique da Silva: Implementar a mesma lógica para position bottom!
			return {
				left: `${eleRect.left + window.scrollX - offsetX + (eleRect.width / 2)}px`,
				top: `${eleRect.top + window.scrollY - offsetY - (eleRect.height / 2) - tooltipRect.height}px`
			}
		} else {
			return {
				left: `calc(50% - ${offsetX}px)`,
				bottom: `calc(100% + ${offsetY}px)`
			}
		}
	} else if (position === 'bottom') {
		return {
			left: `calc(50% - ${offsetX}px)`,
			top: `calc(100%	+ ${offsetY}px)`
		}
	}
}

export {
	calculateCenter,
	calculateOffset,
	calculatePosition
}
