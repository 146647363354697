import {
	ROUTE_CONFIGURATION_FREIGHT_SELLER
	, ROUTE_CONFIGURATION_FREIGHT_SELLER_REGISTRATION
	, ROUTE_CONFIGURATION_FREIGHT_SELLER_LIST
} from 'constants/RouterNames'

const freightSeller = () => import('components/pages/secured/configuration/freight/freightSeller/freightSeller')
const freightList = () => import('components/pages/secured/configuration/freight/freightSeller/freightList/freightList')
const freightRegistration = () => import('components/pages/secured/configuration/freight/freightSeller/freightRegistration/freightRegistration')

const configurationFreightSeller = {
	path: '/frete',
	redirect: '/listagem-fretes',
	component: freightSeller,
	name: ROUTE_CONFIGURATION_FREIGHT_SELLER.name,
	meta: { requiresAuthorities: ROUTE_CONFIGURATION_FREIGHT_SELLER.routerAuth },
	children: [
		{ path: '/listagem-fretes', component: freightList, name: ROUTE_CONFIGURATION_FREIGHT_SELLER_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_CONFIGURATION_FREIGHT_SELLER_LIST.routerAuth } },
		{ path: '/cadastrar-frete', component: freightRegistration, name: ROUTE_CONFIGURATION_FREIGHT_SELLER_REGISTRATION.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_CONFIGURATION_FREIGHT_SELLER_REGISTRATION.routerAuth } }
	]
}

export default configurationFreightSeller
