<template>
  <div class="mkt-push-button" :class="{ 'no-click': onClickVal === undefined }" @click="onClick">
		<mkt-icon v-if="txIcon" :tx-icon="txIcon" :tx-font-size="txFontSize" :tx-icon-color="txIconColor"></mkt-icon>
		<div class="mkt-icon__label" v-else>{{ txLabel }}</div>
  </div>
</template>

<script>
import PropConfig from 'pojos/PropConfig'

export default {
	name: 'mkt-push-button',
	props: {
		onClickVal: new PropConfig({ required: false }),
		txLabel: new PropConfig({ type: [String, Number], required: false }),
		txIcon: new PropConfig({ type: String, required: false }),
		txIconColor: new PropConfig({ type: String, required: false }),
		txFontSize: new PropConfig({ type: String, required: false, valueDefault: '16px' })
	},
	methods: {
		onClick() {
			this.$emit('mkt-push-button:on-click', this.onClickVal)
		}
	}
}
</script>

<style src="./mktPushButton.scss" lang="scss">
</style>
