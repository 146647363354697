import { render, staticRenderFns } from "./mktProductModerationStatus.vue?vue&type=template&id=17afd648&"
import script from "./mktProductModerationStatus.vue?vue&type=script&lang=js&"
export * from "./mktProductModerationStatus.vue?vue&type=script&lang=js&"
import style0 from "./mktProductModerationStatus.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports