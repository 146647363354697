import {
	ROUTE_PRODUCT_SELLER_LIST
	, ROUTE_PRODUCT_SELLER
	, ROUTE_SELLER_NEW_VARIATION_REGISTRATION
	, ROUTE_PRODUCT_SELLER_SEARCH
	, ROUTE_SELLER_NEW_PRODUCT_REGISTRATION
	, ROUTE_PRODUCT_SELLER_EDIT
	, ROUTE_CONTENT_SELLER_EDIT
} from 'constants/RouterNames'

const productSeller = () => import('components/pages/secured/product/productSeller/productSeller')
const productSellerList = () => import('components/pages/secured/product/productSeller/productSellerList/productSellerList')
const productSellerSearch = () => import('components/pages/secured/product/productSeller/productSellerSearch/productSellerSearch')
const sellerNewVariationRegistration = () => import('components/pages/secured/product/productSeller/productSellerRegistration/sellerNewVariationRegistration/sellerNewVariationRegistration')
const sellerNewProductRegistration = () => import('components/pages/secured/product/productSeller/productSellerRegistration/sellerNewProductRegistration/sellerNewProductRegistration')
const productSellerEdit = () => import('components/pages/secured/product/productSeller/productSellerList/productSellerEdit/productSellerEdit.vue')
const contentSellerEdit = () => import('components/pages/secured/product/productSeller/productSellerList/contentSellerEdit/contentSellerEdit.vue')

const productSellerRoute = {
	path: '/produtos-seller',
	redirect: '/listagem-produtos-seller',
	component: productSeller,
	name: ROUTE_PRODUCT_SELLER.name,
	meta: { breadcrumb: { name: 'Meus Produtos' }, requiresAuthorities: ROUTE_PRODUCT_SELLER.routerAuth },
	children: [
		{ path: '/listagem-produtos-seller', component: productSellerList, name: ROUTE_PRODUCT_SELLER_LIST.name, meta: { breadcrumb: { name: 'Listagem' }, requiresAuthorities: ROUTE_PRODUCT_SELLER_LIST.routerAuth } },
		{ path: '/buscar-produtos', component: productSellerSearch, name: ROUTE_PRODUCT_SELLER_SEARCH.name, meta: { breadcrumb: { name: 'Cadastro' }, requiresAuthorities: ROUTE_PRODUCT_SELLER_SEARCH.routerAuth } },
		{ path: '/cadastro-produto', component: sellerNewProductRegistration, name: ROUTE_SELLER_NEW_PRODUCT_REGISTRATION.name, meta: { breadcrumb: { name: 'Novo Produto' }, requiresAuthorities: ROUTE_SELLER_NEW_PRODUCT_REGISTRATION.routerAuth } },
		{ path: '/cadastro-variacao/:id', component: sellerNewVariationRegistration, name: ROUTE_SELLER_NEW_VARIATION_REGISTRATION.name, meta: { breadcrumb: { name: 'Novo Produto' }, requiresAuthorities: ROUTE_SELLER_NEW_VARIATION_REGISTRATION.routerAuth } },
		{ path: '/edicao-produto-seller/:productId', append: true, props: true, component: productSellerEdit, name: ROUTE_PRODUCT_SELLER_EDIT.name, meta: { breadcrumb: { name: 'Editar Produto' }, requiresAuthorities: ROUTE_PRODUCT_SELLER_EDIT.routerAuth } },
		{ path: '/edicao-conteudo-seller/:contentId', append: true, props: true, component: contentSellerEdit, name: ROUTE_CONTENT_SELLER_EDIT.name, meta: { breadcrumb: { name: 'Editar Produto' }, requiresAuthorities: ROUTE_CONTENT_SELLER_EDIT.routerAuth } }
	]
}

export default productSellerRoute
