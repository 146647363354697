import { SET_SELLER_CONFIGURATION } from 'constants/MutationTypes'
import SellerRegistrationBaseModule from './sellerRegistrationBaseModule'

const sellerConfiguration = new SellerRegistrationBaseModule('sellerConfiguration', SET_SELLER_CONFIGURATION)

const state = sellerConfiguration.getState()
const initialState = sellerConfiguration.getInitialState()
const getters = sellerConfiguration.getGetters()
const actions = sellerConfiguration.getSetters()
const mutations = sellerConfiguration.getMutations()

export default {
	state,
	getters,
	actions,
	mutations,
	initialState
}
