import { ROUTE_REBATE_LIST_MODERATION_SELLER } from 'constants/RouterNames'

const rebateModerationListingColombo = () => import('components/pages/secured/promotion/rebate/rebateSeller/rebateModerationList/rebateList.vue')

const rebateRoute = {
	path: '/listar-rebates-moderacao',
	component: rebateModerationListingColombo,
	meta: { breadcrumb: { name: 'Listar Rebates em Moderação' }, requiresAuthorities: ROUTE_REBATE_LIST_MODERATION_SELLER.routerAuth },
	children: [
		{ path: '/listar-rebates-moderacao', component: rebateModerationListingColombo, name: ROUTE_REBATE_LIST_MODERATION_SELLER.name, meta: { requiresAuthorities: ROUTE_REBATE_LIST_MODERATION_SELLER.routerAuth } }

	]
}

export default rebateRoute
