<template>
	<div
		class="mkt-file-dropzone-container"
		@drag.prevent=""
		@dragstart.prevent=""
		@dragover.prevent="onDragEnter"
		@dragenter.prevent="onDragEnter"
		@dragend.prevent="onDragLeave"
		@dragleave.prevent="onDragLeave"
		@drop.prevent="addFiles"
		:class="{ 'is-dragover': isDragging }"
	>
		<div class="mkt-file-dropzone-container__input">
			<input class="mkt-file-dropzone-container__file" ref="fileInput" :accept="acceptedFileTypes.code.join(', ')" @change="onChangeValue($event)" type="file" :id="txIdFileUpload" multiple />
			<div class="mkt-file-dropzone-container__dropzone__internText">
				<div class="mkt-file-dropzone-container__dropzone__icon">
					<i aria-hidden="true" :class="txIcon"></i>
				</div>
				<div class="mkt-file-dropzone-container__dropzone__text">{{txDescription}}</div>
				<div class="mkt-file-dropzone-container__dropzone__subtext">
					Arraste aqui arquivos em formato {{acceptedFileTypes.format}}{{txAdditionalDescription}} de até {{nrMaxFilesize}} MB ou <label :for="txIdFileUpload"><a class="mkt-file-dropzone-container__dropzone__clickable">envie manualmente</a></label>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import PropConfig from 'pojos/PropConfig'

import fileUploadMixin from 'components/generics/mktFileComponents/fileUploadMixin.js'

export default {
	name: 'mkt-file-dropzone-container',
	mixins: [fileUploadMixin],
	data() {
		return {
			isDragging: false
		}
	},
	props: {
		value: new PropConfig({ type: [Array, Object], required: false }),
		txIcon: new PropConfig({ type: String, required: false, valueDefault: 'icon-image-file' }),
		txDescription: new PropConfig({ type: String, required: false, valueDefault: 'Arraste e solte aqui o seu arquivo' }),
		txAdditionalDescription: new PropConfig({ type: String, required: false, valueDefault: ' ' })
	},
	methods: {
		addFiles(e) {
			this.onDragLeave()
			this.loadFiles(Object.values(e.dataTransfer.files))
		},
		clearFileInput() {
			this.$refs.fileInput.value = ''
		},
		onDragLeave() {
			this.isDragging = false
		},
		onDragEnter() {
			this.isDragging = true
		}
	}
}
</script>
<style src="./mktFileDropzoneContainer.scss" lang="scss">
</style>







