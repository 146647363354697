import Password from './password/'
import Profile from './profile/'

const baseAuthority = 'MARKETPLACE'

const Authorities = [
	...Password,
	...Profile
]

Authorities.forEach(auth => auth.setBaseAuthority(baseAuthority))

export default Authorities
