import {
	ROUTE_REBATE_LISTING_COLOMBO
} from 'constants/RouterNames'

const rebateListTable = () =>
	import('components/pages/secured/promotion/rebate/rebateColombo/rebateList/rebateList.vue')

const rebateListRoute = {
	path: '/listar-rebates',
	component: rebateListTable,
	meta: { breadcrumb: { name: 'Listar Rebates', active: false }, requiresAuthorities: ROUTE_REBATE_LISTING_COLOMBO.routerAuth },
	children: [
		{
			path: '/listar-rebates',
			component: rebateListTable,
			name: ROUTE_REBATE_LISTING_COLOMBO.name
		}
	]
}

export default rebateListRoute
