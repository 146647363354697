import { download } from 'services/downloadService.js'
import { sendErrorNotification } from 'services/notificationService'
import { eventEmit, EVENT_EXPORT_FINISH, EVENT_EXPORT_ERROR } from 'services/eventBusService.js'

const recursiveListObjectToList = (listRecursiveObject = [], fieldChildrens) => {
	return listRecursiveObject.flatMap(object => recursiveObjectToList([], object, fieldChildrens))
}

const recursiveObjectToList = (list, recursiveObject, fieldChildrens) => {
	list.push(recursiveObject)
	if (recursiveObject[fieldChildrens]) {
		recursiveObject[fieldChildrens].forEach(c => recursiveObjectToList(list, c, fieldChildrens))
	}
	return list
}

const ERROR = 'ERROR'
const FINISHED = 'FINISHED'
const PROCESSING = 'PROCESSING'

const verifyExportFile = (method, id) => {
	return method(id).then(({ data }) => {
		return new Promise((resolve, reject) => {
			if (data.fileExportStatus === FINISHED) {
				eventEmit(EVENT_EXPORT_FINISH)
				download(data.urlDownload)
				resolve()
			} else if (data.fileExportStatus === ERROR) {
				eventEmit(EVENT_EXPORT_ERROR)
				sendErrorNotification('Ocorreu algum erro num processo! Tente novamente mais tarde.')
				resolve()
			} else if (data.fileExportStatus === PROCESSING) {
				setTimeout(function() {
					verifyExportFile(method, id).then(resolve)
				}, 3000)
			}
		})
	})
}

export { recursiveObjectToList, recursiveListObjectToList, verifyExportFile }
