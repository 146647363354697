import {
	ROUTE_PROMOTION_SELLER,
	ROUTE_PROMOTION_LISTING_SELLER
} from 'constants/RouterNames'

import promotionRegister from './promotionRegister/'
import promotionDetail from './promotionDetail/'
const promotionSeller = () => import('components/pages/secured/promotion/promotionSeller/promotionSeller.vue')
const promotionListingSeller = () => import('components/pages/secured/promotion/promotionSeller/promotionList/promotionList.vue')

const promotionRoute = {
	path: '/promotion-seller',
	redirect: '/listar-promocoes',
	component: promotionSeller,
	name: ROUTE_PROMOTION_SELLER.name,
	meta: { breadcrumb: { name: 'Preço por produto' } },
	children: [
		{ path: '/listar-promocoes', component: promotionListingSeller, name: ROUTE_PROMOTION_LISTING_SELLER.name, meta: { requiresAuthorities: ROUTE_PROMOTION_LISTING_SELLER.routerAuth } },
		promotionRegister,
		promotionDetail
	]
}

export default promotionRoute
