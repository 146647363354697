import { sendErrorNotification } from 'src/services/notificationService'

import {
	ADD_REBATE_PRODUCT_ITEM_SELLER,
	SET_REBATE_PRODUCT_SET_COMISSION_FIELD,
	SET_REBATE_PRODUCT_SET_SALE_LIMIT,
	SET_REBATE_PRODUCT_FIELD,
	REMOVE_REBATE_PRODUCT
} from 'constants/MutationTypes'

import RebateProductItemSellerDto from 'dtos/promotionDtos/RebateProductItemSellerDto.js'
import { isNullOrUndefined } from 'services/objectService.js'

// getters
const getters = {
	getRebateProductItemSellerList: state => state.rebate.rebateProductItemSellers || []
}

// actions
const actions = {
	addRebateProductItemSeller: ({ commit }, rebateProductItemSeller) => {
		commit(ADD_REBATE_PRODUCT_ITEM_SELLER, rebateProductItemSeller)
	},
	setRebateProductComissionField: ({ commit }, { index, fieldName, fieldValue }) => {
		commit(SET_REBATE_PRODUCT_SET_COMISSION_FIELD, { index, fieldName, fieldValue })
	},
	setRebateProductSaleLimit: ({ commit }, { index, saleLimitQuantity }) => {
		commit(SET_REBATE_PRODUCT_SET_SALE_LIMIT, { index, saleLimitQuantity })
	},
	setRebateProductField({ commit }, { index, fieldName, fieldValue }) {
		commit(SET_REBATE_PRODUCT_FIELD, { index, fieldName, fieldValue })
	},
	removeRebateProduct: ({ commit }, index) => {
		commit(REMOVE_REBATE_PRODUCT, index)
	}
}

// mutations
const mutations = {
	[ADD_REBATE_PRODUCT_ITEM_SELLER](state, rebateProductItemSeller) {
		const productItemSeller = state.rebate.rebateProductItemSellers.find(currentItem => currentItem.productItemSellerId === rebateProductItemSeller.productItemSellerId)

		if (!productItemSeller) {
			state.rebate.rebateProductItemSellers.push(new RebateProductItemSellerDto(rebateProductItemSeller))
		} else {
			sendErrorNotification(`O produto ${productItemSeller.productName} já foi adicionado!`)
		}
	},
	[SET_REBATE_PRODUCT_SET_COMISSION_FIELD](state, { index, fieldName, fieldValue }) {
		if (isNullOrUndefined(index)) {
			state.rebate.rebateProductItemSellers.forEach(productItemSeller => {
				productItemSeller[fieldName] = fieldValue
			})
		} else {
			const productItemSeller = state.rebate.rebateProductItemSellers[index]
			if (productItemSeller) {
				productItemSeller[fieldName] = fieldValue
			}
		}
	},
	[SET_REBATE_PRODUCT_SET_SALE_LIMIT](state, { index, saleLimitQuantity }) {
		if (isNullOrUndefined(index)) {
			state.rebate.rebateProductItemSellers.forEach(productItemSeller => {
				productItemSeller.saleLimitQuantity = saleLimitQuantity
			})
		} else {
			const productItemSeller = state.rebate.rebateProductItemSellers[index]
			if (productItemSeller) {
				productItemSeller.saleLimitQuantity = saleLimitQuantity
			}
		}
	},
	[SET_REBATE_PRODUCT_FIELD](state, { index, fieldName, fieldValue }) {
		if (isNullOrUndefined(index)) {
			state.rebate.rebateProductItemSellers.forEach(item => {
				item[fieldName] = fieldValue
			})
		} else {
			state.rebate.rebateProductItemSellers[index][fieldName] = fieldValue
		}
	},
	[REMOVE_REBATE_PRODUCT](state, index) {
		if (!isNullOrUndefined(index)) {
			state.rebate.rebateProductItemSellers.splice(index, 1)
		}
	}
}

export default {
	getters,
	actions,
	mutations
}
