import { verticalScrollToElement } from 'services/scrollService.js'

const setErrorsToElements = (validationErrList) => {
	const elementList = validationErrList.map(elementErr => {
		const { target, message } = elementErr
		const element = findElementByTarget(target)
		element && findElementErrorControl(element.__vue__, message)
		return element
	}).filter(element => !!element)

	if (!elementList.length) return

	const topMostElement = elementList.reduce(getFirstElementVertically)

	setTimeout(() => {
		verticalScrollToElement(topMostElement)
	})
}

const findElementByTarget = (target) => {
	const element = document.getElementById(target)
	if (element && element.__vue__) {
		return element
	}
}

const findElementErrorControl = (component, message) => {
	const { $data: data = {}, $parent: parent } = component
	const hasErrorData = data.hasErrors

	if (hasErrorData !== undefined) {
		data.hasErrors = true
		data.formValidationError = message
	} else {
		parent && findElementErrorControl(parent, message)
	}
}

const validateFormElements = (refForm) => {
	const listFormElements = Array.from(refForm.elements)
	let hasErrors = false
	for (const element of listFormElements) {
		hasErrors = !!(element && element.__vue__ && recursiveFindElementFormControl(element.__vue__))
		if (hasErrors) {
			break
		}
	}
	return hasErrors
}

const recursiveFindElementFormControl = (element) => {
	const { $data: data = {}, $parent: parent } = element
	const hasErrorData = data.hasErrors
	if (hasErrorData !== undefined) {
		return hasErrorData
	} else if (parent) {
		return parent && recursiveFindElementFormControl(parent)
	} else {
		return false
	}
}

const getFirstElementVertically = (acc, curr) => {
	const { top: topAcc } = acc.getBoundingClientRect()
	const { top: topCurr } = curr.getBoundingClientRect()
	return topCurr < topAcc ? curr : acc
}

export {
	setErrorsToElements,
	validateFormElements,
	getFirstElementVertically
}
