import {
	ROUTE_REBATE_ACCESS_LISTING_SELLER
} from 'constants/RouterNames'

const rebateListTable = () =>
	import('components/pages/secured/promotion/rebate/rebateSeller/rebateList/rebateList.vue')

const rebateListRoute = {
	path: '/listar-rebates-seller',
	component: rebateListTable,
	children: [
		{
			path: '/listar-rebates-seller',
			component: rebateListTable,
			name: ROUTE_REBATE_ACCESS_LISTING_SELLER.name
		}
	]
}

export default rebateListRoute
